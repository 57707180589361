/* eslint-disable max-len */
import React           from 'react';
import { ColorLegend } from '../../../../../lib/annual-report/survey/colorLegend';
import { SurveyV2 }    from '../../../../../lib/annual-report/survey/survey-v2';
import { Title }       from '../../../../../lib/components/title/title';
import { Wrapper }     from '../../../../../lib/components/wrapper/wrapper.component';

type DataChartItemType = {
    agree: number;
    disagree: number;
    // color?: 'green1' | 'green2' | 'red1' | 'red2' | 'red3';
    agreeColor?: 'green1' | 'green2' | 'red1' | 'red2' | 'red3';
    disagreeColor?: 'green1' | 'green2' | 'red1' | 'red2' | 'red3';
    message?: string;
}
type DataCommunityType = {
    title: string;
    question: string;
    data: {
        subject: 'parent'|'primary'|'secondary'|'faculty'|'staff';
        question?: string;
        current?: DataChartItemType;
        previous?: DataChartItemType;
    }[];
    after: Record<string, string>;

}[]

const colorLegend = {
    green1: 'More than 90% \nAgree/Strongly Agree',
    green2: '2020 Agrees increased \nby least 10% vs. 2019',
    red1:   'More than 15% Disagree',
    red2:   '2020 agrees dropped \nby least 10% vs. 2019',
    red3:   '2020 disagrees increased \nby least 10% vs. 19'
};

const ClimateSurveyChart = () => {
    const community: DataCommunityType = [
        {
            title:    'Strong sense of community',
            question: 'PSI has a strong sense of community.',
            data:     [
                {
                    subject:  'parent',
                    question: 'PSI fosters a strong sense of community.',
                    current:  { agree: 93, disagree: 7, agreeColor: 'green2' },
                    previous: { agree: 77, disagree: 16 }
                },
                { subject: 'primary' },
                {
                    subject:  'secondary',
                    question: 'PSI has a strong sense of community.',
                    current:  { agree: 83, disagree: 12, agreeColor: 'green2' },
                    previous: { agree: 64, disagree: 31 }
                },
                {
                    subject:  'faculty',
                    question: 'PSI has a strong sense of community.',
                    current:  { agree: 86, disagree: 16, agreeColor: 'green2' },
                    previous: { agree: 71, disagree: 26 }
                },
                { subject: 'staff' }
            ],
            after: {
                actions: 'We will celebrate the increase in the sense of community among faculty, parents and students over last year.',
                example: '"There has been a renewed sense of community that I felt was missing over the past couple of years" \n— _Secondary Faculty_'
            }
        },
        {
            title:    'Students are respectful of differences',
            question: 'Students in my school respect people from different backgrounds, e.g., race, ethnicities and gender.',
            data:     [
                { subject: 'parent' },
                {
                    subject:  'primary',
                    current:  { agree: 75, disagree: 12 },
                    previous: { agree: 77, disagree: 14 }
                },
                {
                    subject:  'secondary',
                    question: 'PSI has a strong sense of community.',
                    current:  { agree: 87, disagree: 8 },
                    previous: { agree: 91, disagree: 6 }
                },
                { subject: 'faculty' },
                { subject: 'staff' }
            ],
            after: {
                actions: 'Whilst we want to celebrate the the values are high for an indication of adult respect of differences in all stakeholders, we are concerned that there is a lower percentages representing student respect of people\'s differences than there is for adults.  As such, we will engage the student council or another student committee to create an action plan on how to improve levels of respect among students\n',
                example: '"Other students don\'t really respect the gender but but those students are working on becoming nicer but some people are still mean Sometimes" \n— _G3-5 Student_  \n"Some kids don\'t respect kids with different backgrounds, which is really bad." \n— _G3-5 Student_'
            }
        },
        {
            title:    'PSI is respectful of differences',
            question: 'PSI is respectful of people of different races, genders, ethnicities, and backgrounds.',
            data:     [
                {
                    subject:  'parent',
                    // current:  { agree: 96, disagree: 3, color: 'green1' },
                    current:  { agree: 96, disagree: 3, agreeColor: 'green2' },
                    previous: { agree: 83, disagree: 10 }
                },
                {
                    subject:  'primary',
                    current:  { agree: 88, disagree: 3 },
                    previous: { agree: 90, disagree: 7 }
                },
                {
                    subject:  'secondary',
                    question: 'PSI has a strong sense of community.',
                    current:  { agree: 96, disagree: 3, agreeColor: 'green1' },
                    previous: { agree: 99, disagree: 2 }
                },
                {
                    subject:  'faculty',
                    question: 'My school is respectful of different races, genders, ethnicities, and backgrounds.',
                    current:  { agree: 92, disagree: 6, agreeColor: 'green1' },
                    previous: { agree: 92, disagree: 8 }
                },
                { subject: 'staff' }
            ],
            after: {

                actions: 'Whilst we want to celebrate the the values are high for an indication of adult respect of differences in all stakeholders, we are concerned that there is a lower percentages representing student respect of people\'s differences than there is for adults.  As such, we will engage the student council or another student committee to create an action plan on how to improve levels of respect among students\n',
                example: ''
            }
        }
    ];
    const wellBeing: DataCommunityType = [
        {
            title:    'Workload Balance',
            question: 'I am satisfied with my child/ren\'s workload and schedule balance among study, homework, play and sleep.',
            data:     [
                {
                    subject:  'parent',
                    question: 'I am satisfied with my child/ren\'s workload and schedule balance among study, homework, play and sleep.',
                    current:  { agree: 73, disagree: 23, disagreeColor: 'red1', message: 'More than 15% Disagree' }
                },
                {
                    subject:  'primary',
                    question: 'I am satisfied with the workload and schedule balance among study, homework, play and sleep.',
                    current:  { agree: 70, disagree: 20, disagreeColor: 'red1', message: 'More than 15% Disagree' }
                },
                {
                    subject:  'secondary',
                    question: 'I am satisfied with the workload and schedule balance among study, homework, play and sleep.',
                    current:  { agree: 47, disagree: 49, disagreeColor: 'red1', message: 'More than 15% Disagree' }
                },
                {
                    subject:  'faculty',
                    question: 'I am satisified with the workload and schedule balance among instruction, preparation, grading, relaxation and sleep.',
                    current:  { agree: 71, disagree: 24, disagreeColor: 'red1', message: 'More than 15% Disagree' }
                },
                { subject: 'staff' }
            ],
            after: {
                actions: 'We plan to convene focus groups from each stakeholder to better understand the issue and generate possible solutions for implementation\n',
                example: '"As has been the case over many years, PSI still needs to work on the balance and well-being of its students.  Obviously, this year had its own nuances.  But, the topic needs to continue to be a priority for PSI." \n— _Primary Parent_'
            }
        },
        {
            title:    'Bullying is a problem',
            question: 'Bullying is a problem in our school community.',
            data:     [
                { subject: 'parent' },
                {
                    subject:  'primary',
                    question: 'I am satisfied with the workload and schedule balance among study, homework, play and sleep.',
                    current:  { agree: 51, disagree: 33, disagreeColor: 'red1' },
                    previous: { agree: 54, disagree: 31 }
                },
                {
                    subject:  'secondary',
                    question: 'I am satisfied with the workload and schedule balance among study, homework, play and sleep.',
                    current:  { agree: 22, disagree: 67, agreeColor: 'red1', disagreeColor: 'green2' },
                    previous: { agree: 23, disagree: 57 }
                },
                { subject: 'faculty' },
                { subject: 'staff' }
            ],
            after: {
                actions: 'Whilst we recognise the bullying situation has improved slightly the primary students still see it as a problem.  As such, we pln to Increase lessons on kindness, conflict resolution and bullying. In addition, we plan to add additional focus on the defintion of bullying as compared to issues of conflict.',
                example: ''
            }
        },
        {
            title:    'Trust in Adults',
            question: 'If I have a personal problem / question / concern, I feel comfortable talking with a trusted adult at PSI.',
            data:     [
                { subject: 'parent' },
                {
                    subject: 'primary',
                    current: { agree: 66, disagree: 20, disagreeColor: 'red1' }
                },
                {
                    subject: 'secondary',
                    current: { agree: 54, disagree: 37, disagreeColor: 'red1' }
                },
                {
                    subject:  'faculty',
                    current:  { agree: 78, disagree: 15 },
                    previous: { agree: 68, disagree: 28 }
                },
                {
                    subject:  'staff',
                    current:  { agree: 86, disagree: 3, agreeColor: 'green2' },
                    previous: { agree: 68, disagree: 26 }
                }
            ],
            after: {
                actions: 'With the addition of the Advisory Programme in Secondary School, and increase social emotional lessons in primary classes, we hope that more students will trust adults with whom they work.',
                example: ''
            }
        }
    ];
    const learningTeaching: DataCommunityType = [
        {
            title:    'High Quality Education',
            question: 'The overall educational experience offered to students at PSI is of a high quality.',
            data:     [
                {
                    subject:  'parent',
                    current:  { agree: 91, disagree: 9, agreeColor: 'green1' },
                    previous: { agree: 93, disagree: 5 }
                },
                {
                    subject:  'primary',
                    current:  { agree: 92, disagree: 3, agreeColor: 'green1' },
                    previous: { agree: 84, disagree: 4 }
                },
                {
                    subject:  'secondary',
                    current:  { agree: 88, disagree: 8 },
                    previous: { agree: 90, disagree: 5 }
                },
                {
                    subject:  'faculty',
                    current:  { agree: 97, disagree: 2, agreeColor: 'green1' },
                    previous: { agree: 97, disagree: 3 }
                },
                { subject: 'staff' }
            ],
            after: {
                actions: '',
                example: '"They have high equality education.and fun activities" \n— _G3-5 Student_\n "It’s the best school that I’ve ever been to, the education has a very high quality and teachers are kind and cool." \n— _Secondary Student_\n "No doubt it is the best School in Ukraine" \r— _Parent of Children in Both Divisions_'
            }
        },
        {
            title:    'Well-rounded programme',
            question: 'PSI offers a balanced, well-rounded programme.',
            data:     [
                {
                    subject:  'parent',
                    current:  { agree: 88, disagree: 10 },
                    previous: { agree: 88, disagree: 9 }
                },
                { subject: 'primary' },
                {
                    subject:  'secondary',
                    current:  { agree: 82, disagree: 10, disagreeColor: 'red2' },
                    previous: { agree: 93, disagree: 3 }
                },
                {
                    subject:  'faculty',
                    current:  { agree: 94, disagree: 5, agreeColor: 'green1' },
                    previous: { agree: 90, disagree: 11 }
                },
                { subject: 'staff' }
            ],
            after: {
                actions: 'In order to learn more about why secondary students have a decresed belief in the PSI programme being well rounded, we plan to hold focus groups.  Through this, we hope to gather strategies to implement in order to improve balance for our student body.',
                example: ''
            }
        },
        {
            title:    'Student Feedback',
            question: 'I receive meaningful feedback about my child/ren\'s progress.',
            data:     [
                {
                    subject:  'parent',
                    current:  { agree: 84, disagree: 15 },
                    previous: { agree: 80, disagree: 18 }
                },
                {
                    subject:  'primary',
                    current:  { agree: 92, disagree: 4, agreeColor: 'green1' },
                    previous: { agree: 90, disagree: 4 }
                },
                {
                    subject:  'secondary',
                    current:  { agree: 72, disagree: 21, disagreeColor: 'red1' },
                    previous: { agree: 70, disagree: 27 }
                },
                {
                    subject:  'faculty',
                    current:  { agree: 94, disagree: 1, agreeColor: 'green1' },
                    previous: { agree: 96, disagree: 1 }
                },
                { subject: 'staff' }
            ],
            after: {
                actions: 'Since secondary students have less confidence in the timeliness and usefulness of feedback, we plan to adjust mechanisms and pacing of feedback.  Additionally, we plan to add more regular 1:1 and small group meetings between students and teachers to provide feedback sessions.',
                example: '"Feedback from teachers is sometimes slow." \n— _Secondary Student_ \n "More clear feedback on grading would be nice" \n— _Secondary Student_'
            }
        },
        {
            title:    'Choices in Learning',
            question: 'My child has the opportunity to make choices about his /her learning.',
            data:     [
                {
                    subject:  'parent',
                    current:  { agree: 75, disagree: 14 },
                    previous: { agree: 71, disagree: 14 }
                },
                {
                    subject: 'primary',
                    current: { agree: 88, disagree: 3 }
                },
                {
                    subject: 'secondary',
                    current: { agree: 72, disagree: 23, disagreeColor: 'red1' }
                },
                {
                    subject:  'faculty',
                    current:  { agree: 90, disagree: 9 },
                    previous: { agree: 92, disagree: 3 }
                },
                { subject: 'staff' }
            ],
            after: {
                actions: 'There is a discrepency between the perception of teachers and students in the amount of choice in learning.  In order to address this, we plan to introduce more levels of personalisation in learning experiences.',
                example: '"I think what PSI has to improve is to give students a little bit more flexibility, where they can choose what to do from a few options. That only happened in a couple of classes, and maybe it would be better if most classes had that option." \n— _Secondary Student_\n'
            }
        },
        {
            title:    'Academic Progress',
            question: 'I am satisfied with my child\'s academic progress.',
            data:     [
                {
                    subject:  'parent',
                    current:  { agree: 83, disagree: 16, disagreeColor: 'red1' },
                    previous: { agree: 87, disagree: 11 }
                },
                { subject: 'primary' },
                { subject: 'secondary' },
                { subject: 'faculty' },
                { subject: 'staff' }
            ],
            after: {
                actions: 'We plan to convene a focus group with parents, leadership team and teachers to better understand parents\' lack of satisfaction with academic progress.',
                example: ''
            }
        }
    ];
    const serviceActivities: DataCommunityType = [ {
        title:    'Extensive Co-curriculars',
        question: 'PSI offers an extensive after school / Saturday programme.',
        data:     [
            {
                subject:  'parent',
                current:  { agree: 77, disagree: 15, agreeColor: 'red2', disagreeColor: 'red1' },
                previous: { agree: 88, disagree: 6 }
            },
            {
                subject:  'primary',
                current:  { agree: 58, disagree: 14, agreeColor: 'red2' },
                previous: { agree: 80, disagree: 17 }
            },
            {
                subject:  'secondary',
                current:  { agree: 66, disagree: 10, agreeColor: 'red2' },
                previous: { agree: 79, disagree: 15 }
            },
            {
                subject:  'faculty',
                current:  { agree: 83, disagree: 5, agreeColor: 'red2' },
                previous: { agree: 93, disagree: 4 }
            },
            { subject: 'staff' }
        ],
        after: {
            actions: 'We plan to convene focus group to understand dissatifaction with co-curricular activities opportunities and how much is a change in the programme and how much is related to the distance learning programme of the spring when the survey was conducted.  Additionally, we plan to reword the question and discussion to clarify what activities are included as we have begun incorporating more co-curricular activities among options for students.',
            example: '"The choices this year were not as exciting for my child. He was very pleased with the options provided last year." \n— _Primary Parent_'
        }
    } ];
    const servicesFacilities: DataCommunityType = [ {
        title:    'Satisfaction with Communications',
        question: 'I am satisfied with the clarity, purpose, and quantity of communications I receive from PSI.',
        data:     [
            {
                subject: 'parent',
                current: { agree: 82, disagree: 17, disagreeColor: 'red1' }
            },
            { subject: 'primary' },
            {
                subject: 'secondary',
                current: { agree: 85, disagree: 10 }
            },
            {
                subject: 'faculty',
                current: { agree: 93, disagree: 6, agreeColor: 'green1' }
            },
            {
                subject: 'staff',
                current: { agree: 86, disagree: 10 }
            }
        ],
        after: {
            actions: 'We plan to begin with a primary  focus group to better understand their dissatisfaction with communication.  Once we have suggestions, we plan to implement suggestions.',
            example: ''
              + '"A lot less communication is. needed.  Too many emails that contain the same information." \r— _Primary Parent_\n'
              + '"We receive too many different kinds of communication and information is placed in too many places." \r— _Secondary Parent_'
        }
    } ];
    const professionalDevelopment: DataCommunityType = [ {
        title:    'PD opportunities',
        question: 'I have opportunities to grow professionally at work.',
        data:     [
            { subject: 'parent' },
            { subject: 'primary' },
            { subject: 'secondary' },
            {
                subject:  'faculty',
                current:  { agree: 85, disagree: 12 },
                previous: { agree: 82, disagree: 18 }
            },
            {
                subject:  'staff',
                current:  { agree: 86, disagree: 24, agreeColor: 'green2', disagreeColor: 'red1' },
                previous: { agree: 53, disagree: 47 }
            }
        ],
        after: {
            actions: 'We plan to continue our efforts to offer more opportunities for growth among staff in addition to faculty.',
            example: ''
        }
    } ];
    return (
        <Wrapper className="test">
            <Title margin="lg" size="h2">Community / Culture</Title>
            <SurveyV2 data={ community } />

            <Title margin="lg" size="h2">Well-Being</Title>
            <SurveyV2 data={ wellBeing } />

            <Title margin="lg" size="h2">Learning & Teaching Climate Survey Questions</Title>
            <SurveyV2 data={ learningTeaching } />

            <Title margin="lg" size="h2">Service / Co-curricular Activities Climate Survey Questions</Title>
            <SurveyV2 data={ serviceActivities } />

            <Title margin="lg" size="h2">PSI Services & Facilities Climate Survey Questions</Title>
            <SurveyV2 data={ servicesFacilities } />

            <Title margin="lg" size="h2">Professional Development Climate Survey Questions</Title>
            <SurveyV2 data={ professionalDevelopment } />

            <ColorLegend />
        </Wrapper>
    );
};

export default ClimateSurveyChart;
