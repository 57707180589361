import React                                                 from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { Text }                                              from '../text/text';
import { Wrapper }                                           from '../wrapper/wrapper.component';
import { ChartPropsType }                                    from './types';

export type PropsType = {
  size?: number;
  fill?: string;
  isAnimationActive?: boolean;
  innerRadius?: number;
  paddingAngle?: number;
  startAngle?: number;
  endAngle?: number;
  tooltip?: boolean;
    disableLabel?: boolean;
  unit?: string;
} & ChartPropsType;

export const ChartPie = (props: PropsType) => {
    const renderCellFunction = () => props.data.map((entry, index) => (
        <Cell key={ index } fill={ props.data[ index ].color || props.colorFunction(index) } />
    ));
    const renderCellArray = () => props.colorArray.map((color, index) => (
        <Cell key={ index } fill={ props.data[ index ].color || color } />
    ));
    const RADIAN = Math.PI / 180;

    const renderCustomizedLabel = ({
        cx, cy, midAngle, innerRadius, outerRadius, percent, index, color
    }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.45;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={ x } y={ y } fill="white" fontSize="14px" textAnchor={ x > cx ? 'start' : 'end' } dominantBaseline="central">
                { `${ (percent * 100).toFixed(0) }${ props.unit ? props.unit : '' }` }
            </text>
        );
    };

    const renderCustomTooltip = ({ active, payload, label }) => {
        // console.log(v);
        if (active && payload && payload.length) {
            console.log(payload[ 0 ]);
            return (
                <Wrapper
                    padding={ { right: 'sm', left: 'xs', top: 'xs', bottom: 'xs' } }
                    radius="sm"
                    frame="solid-dark"
                    style={ { fontSize: 14 } }
                    row
                    valign="center"
                >
                    <Wrapper
                        margin="xs"
                        flex="0"
                        style={ {
                            borderRadius: 16, width: 16, minWidth: 16, height: 16, background: payload[ 0 ].payload.fill
                        } }
                    />
                    <Text size="sm" bold margin={ props.unit ? undefined : 'xxs' }>{ payload[ 0 ].value }</Text>
                    <Text size="sm" italic noWrap flex="0">{ `${ props.unit ? `${ props.unit }, ` : '' }${ payload[ 0 ].name }` }</Text>
                </Wrapper>
            );
        }
        return null;
    };

    return (
        <PieChart width={ props.size } height={ props.size } unit={ props.unit }>
            <Pie
                dataKey="value"
                startAngle={ props.startAngle || 0 }
                endAngle={ props.endAngle || 360 }
                data={ props.data }
                isAnimationActive={ props.isAnimationActive }
                outerRadius={ (props.size / 2) - 10 }
                labelLine={ false }
                innerRadius={ props.innerRadius || props.size / 7 }
                // paddingAngle={ props.paddingAngle || 2 }
                fill={ props.fill }
                label={ !props.disableLabel ? renderCustomizedLabel : undefined }
            >
                { props.colorFunction && renderCellFunction() }
                { props.colorArray && renderCellArray() }
            </Pie>
            { props.tooltip && <Tooltip content={ renderCustomTooltip } /> }
        </PieChart>
    );
};
ChartPie.defaultProps = {
    isAnimationActive: false,
    size:              280
};
