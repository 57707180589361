import React                         from 'react';
import { cn }                        from '../../util/bem';
import { Wrapper, WrapperPropsType } from '../wrapper/wrapper.component';
import './table.scss';

type PropsType = {
  children: React.ReactNode;
  innerWidth?: number;
} & WrapperPropsType;

const DefaultProps = {};

const className = cn('table');

export const Table = (props: PropsType) => (
    <Wrapper className={ className() } { ...props }>
        <Wrapper row={ props.row } style={ { minWidth: props.innerWidth ? props.innerWidth : '' } }>
            { props.children }
        </Wrapper>
    </Wrapper>
);

Table.defaultProps = DefaultProps;
