import React, { FC, forwardRef, memo }                                 from 'react';
import { cn }                                                          from '../../util/bem';
import { dataFunction }                                                from '../../util/data-function';
import { RadiusSizesType, SizeType, SizeTypeDirection, TextColorType } from '../../util/global-props';
import './wrapper.component.scss';

export type WrapperPropsType = {
    children?: React.ReactNode | string;
    className?: string;

    row?: boolean;
    columns?: number;
    align?: 'left'|'center' | 'right';
    valign?: 'top'|'center'|'bottom';
    hoverEffect?: boolean;
    frame?:
      'solid-grey'
      |'solid-key'
      |'solid-white'
      |'solid-key-translucent'
      |'solid-red'
      |'solid-red-translucent'
      |'solid-green'
      |'solid-green-translucent'
      |'solid-dark'
      |'solid-shadow-xl'
      |'border-gray'
      |'border-primary'
      |'border-secondary';
    color?: TextColorType;
    direction?: 'row' | 'column';
    padding?: SizeType | SizeTypeDirection;
    margin?: SizeType | SizeTypeDirection;
    fullWidth?: boolean;
    maxWidth?: string;
    flex?: number | string ;
    style?: React.CSSProperties;
    styles?: React.CSSProperties;
    radius?: RadiusSizesType;
    overflow?: 'hidden' | 'scroll';
    display?: 'none' | 'flex' | 'block';

    scrollHorizontal?: boolean;
    scrollVertical?: boolean;

    // media?: MediaPropsType;
    lastChildMargin?: boolean;
    block?: boolean;

    ref?: React.Ref<HTMLDivElement>;
    onClick?: React.EventHandler<React.MouseEvent<HTMLDivElement, PointerEvent>>;
    onMouseLeave?: React.EventHandler<React.MouseEvent<HTMLDivElement>>;
    onMouseEnter?: React.EventHandler<React.MouseEvent<HTMLDivElement>>;
    onScroll?: React.EventHandler<React.MouseEvent<HTMLDivElement>>;

    xsm?: WrapperPropsType;
    sm?: WrapperPropsType;
    md?: WrapperPropsType;
    lg?: WrapperPropsType;
    hover?: WrapperPropsType;

}

const className = cn('wrapper');

export const Wrapper: FC<WrapperPropsType> = memo(forwardRef((props, ref: React.Ref<HTMLDivElement>) => (
    <div
        className={ className(null, null, props.className) }
        ref={ ref }
        onClick={ props.onClick }
        onMouseEnter={ props.onMouseEnter }
        onMouseLeave={ props.onMouseLeave }
        onScroll={ props.onScroll }
        { ...dataFunction({
            direction:        props.row ? 'row' : 'column',
            columns:          props.columns,
            align:            props.align,
            valign:           props.valign,
            color:            props.color,
            frame:            props.frame,
            hoverEffect:      props.hoverEffect,
            padding:          props.padding,
            margin:           props.margin,
            flex:             props.flex,
            fullWidth:        props.fullWidth,
            radius:           props.radius,
            overflow:         props.overflow,
            lastChildMargin:  props.lastChildMargin,
            display:          props.display,
            scrollHorizontal: props.scrollHorizontal,
            scrollVertical:   props.scrollVertical,
            xsm:              props.xsm,
            sm:               props.sm,
            md:               props.md,
            lg:               props.lg,
            hover:            props.hover
        }) }
        style={ {
            maxWidth: props.maxWidth,
            cursor:   props.onClick && 'pointer',
            display:  props.block && 'block',
            ...props.styles,
            ...props.style
        } }
    >
        { props.children }
    </div>
)));
