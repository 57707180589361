import React       from 'react';
import { md }      from '../../util/md';
import { Divider } from '../divider/divider';
import { Text }    from '../text/text';
import { Wrapper } from '../wrapper/wrapper.component';

type GalleryGridItemData ={
  photo: string;
  title?: string;
  subtitle?: string;
}
export type GalleryGridType = {
  data: GalleryGridItemData[];
}
const DefaultProps = {};

export const GalleryGrid = (props: GalleryGridType) => {
    const temp = '';
    return (
        <Wrapper
            row
            columns={ 5 }
            fullWidth
            md={ { columns: 4 } }
            sm={ { columns: 3 } }
            xsm={ { columns: 2 } }
        >
            { props.data.map((item, index) => (
                <Wrapper
                    key={ index }
                    // padding={ { right: 'xxl', bottom: 'xxl' } }
                    padding={ { right: 'xxl', bottom: 'xxl' } }
                >
                    <img width="100%" src={ item.photo } alt="" />
                    <Divider transparent margin="sm" />
                    { item.title && <Text bold size="md">{ md(item.title) }</Text> }
                    { item.subtitle && <Text size="sm" preWrap><i>{ item.subtitle }</i></Text> }
                </Wrapper>
            )) }
        </Wrapper>
    );
};

GalleryGrid.defaultProps = DefaultProps;
