/* eslint-disable max-len */
import React                                from 'react';
import { ColorLegend, ColorLegendItemType } from '../../../../../../lib/annual-report/color-legend/color-legend';
import { Divider }                          from '../../../../../../lib/components/divider/divider';
import { Paragraph }                        from '../../../../../../lib/components/paragraph/paragraph';
import { Title }                            from '../../../../../../lib/components/title/title';
import { Wrapper }                          from '../../../../../../lib/components/wrapper/wrapper.component';
import { colors }                           from '../../../../../../lib/styles/color';
import { md }                               from '../../../../../../lib/util/md';

type Tags =
    |'met'
    |'partially_met'
    |'not_met'
    |'no_data'
    |'pandemic'
const colorLegend: Record<Tags, ColorLegendItemType> =    {
    met:           { color: colors.green.hex(), text: 'Target met' },
    partially_met: { color: colors.yellow.hex(), text: 'Target partially met ' },
    not_met:       { color: colors.red.hex(), text: 'Target not met ' },
    no_data:       { color: colors.gray.darken(0.2).hex(), text: 'No data available ' },
    pandemic:      { text: 'Pandemic Impacted', wrapper: { style: { border: `4px solid ${ colors.red.darken(0.5).hex() }` } } }
};
type ItemType = {
    tag: Tags;
    text: string;
    pandemic?: boolean;
}
type DataType = {
    title: string;
    items: ItemType[];
};
const dataCommunity: DataType[] = [
    {
        title: '**Objective 1:** PSI commits to maintain, accommodate and support a diverse community.',
        items: [
            { tag: 'met', text: 'PSI maintains diversity among the student body, faculty members, Board of Governors, and the PTA executive committee.\n' },
            { tag: 'met', text: 'PSI maintains a scholarship programme.' },
            { tag: 'met', text: 'PSI maintains a public statement of diversity.' },
            { tag: 'partially_met', pandemic: true, text: 'PSI has sufficient faculty to support students with English language or designated learning needs.' },
            { tag: 'met', pandemic: true, text: 'PSI supports language learning among staff and parents.' },
            { tag: 'partially_met', pandemic: true, text: 'Parents, faculty and students are respectful of characteristics of diversity.' }
        ]
    },
    {
        title: '**Objective 2:** PSI cultivates a positive school ethos to develop a strong sense of community.',
        items: [
            { tag: 'met', pandemic: true, text: 'PSI offers engagement opportunities to the community through events sponsored by the PTA or staff social committee, and school-sponsored parent engagement opportunities.\n' },
            { tag: 'met', pandemic: true, text: 'PSI seeks students’ opinions in major decision making.' },
            { tag: 'met', pandemic: true, text: 'PSI maintains community partnerships for mutual benefit.' },
            { tag: 'not_met', pandemic: true, text: 'PSI has an active alumni programme.' },
            { tag: 'partially_met', pandemic: true, text: 'Community members believe we have a strong community, they’re part of the community and that our Core Values are foundational to our culture.\n' },
            { tag: 'partially_met', pandemic: true, text: 'Staff and students trust the adults with whom they interact.' },
            { tag: 'partially_met', pandemic: true, text: 'Community members are satisfied with communications from the school.' },
            { tag: 'partially_met', text: 'Students feel like they can be themselves at PSI.' }
        ]
    }
];
const dataFaculty: DataType[] = [
    {
        title: '**Objective 1:** PSI attracts and recruits the best qualified and experienced faculty',
        items: [
            { tag: 'met', text: 'PSI hires faculty who are its top choice, have desired experience and postgraduate degrees.' },
            { tag: 'met', text: 'Faculty have leadership experience with the IB or PSI’s accreditors.' }
        ]
    },
    {
        title: 'Objective 2: PSI offers employment conditions that retain the best faculty.',
        items: [
            { tag: 'met', text: 'The overseas hire package is strong when compared to other CEESA schools.' },
            { tag: 'no_data', pandemic: true, text: 'Exit interviews show satisfaction among departing teachers.' },
            { tag: 'met', text: 'Faculty evaluations and improvement plans are appropriately managed.' },
            { tag: 'not_met', text: 'PSI regularly analyses, reflects and acts upon data from faculty members.' }
        ]
    },
    {
        title: 'Objective 3: PSI commits to a culture of continuous professional growth and motivation.',
        items: [
            { tag: 'not_met', pandemic: true, text: 'The professional learning budget is significant.' },
            { tag: 'met', pandemic: true, text: 'Faculty regularly reflect on their own growth.' },
            { tag: 'met', text: 'Students recognise the subject expertise of teachers.' },
            { tag: 'partially_met', pandemic: true, text: 'Faculty and staff recognise their opportunities and ownership of professional learning.' },
            { tag: 'met', text: 'PSI motivates faculty to grow as educators.' }
        ]
    }
];

const dataStudentSuccess: DataType[] = [
    {
        title: 'Objective 1: PSI students’ academic work is at or above expectations.',
        items: [
            { tag: 'met', text: 'Graduates who choose to continue with their education, are accepted into one of their top choice educational institutions.' },
            { tag: 'met', text: 'Recent graduates have a high mean DP score.' },
            { tag: 'met', text: 'Primary school students are reading at or above their grade levels' },
            { tag: 'met', pandemic: true, text: 'Measure of Academic Progress (MAP) results are strong in comparison to other international schools and show student growth across all achievement levels.' },
            { tag: 'not_met', pandemic: true, text: 'Students felt prepared for the current grade level.' },
            { tag: 'not_met', pandemic: true, text: 'Parents are satisfied with their child/ren’s academic progress and level of academic rigour in the school' }
        ]
    },
    {
        title: 'Objective 2: PSI students demonstrate development of the attributes of the IB Learner Profile.',
        items: [
            { tag: 'met', text: 'Teachers mention students’ development on the IB Learner Profile traits in reports.' },
            { tag: 'not_met', pandemic: true, text: 'Students participate in co-curricular activities, and find the offerings extensive and satisfying.' },
            { tag: 'partially_met', pandemic: true, text: 'Students have opportunities to develop as leaders, get involved in service activities, and take responsible actions' },
            { tag: 'met', text: 'Teachers have helped learners make connections between subjects and develop skills with global information networks.' }
        ]
    },
    {
        title: 'Objective 3: PSI provides a stimulating learning environment, engaging pedagogy, and a balanced programme.',
        items: [
            { tag: 'met', text: 'Class sizes and student to faculty ratios are maintained at appropriate levels.' },
            { tag: 'met', pandemic: true, text: 'The director of learning helps teaching teams analyse student learning data, reviews that curricular updates are appropriate and updates the community about changes.' },
            { tag: 'met', pandemic: true, text: 'Community members agree that feedback is timely and meaningful.' },
            { tag: 'partially_met', pandemic: true, text: 'Community members agree that learning experiences are inspiring and enhanced by  technology.' },
            { tag: 'partially_met', pandemic: true, text: 'Community members agree that students are challenged to do their best, have opportunities to make learning choices, and their experiences meet their needs.' }
        ]
    }
];

const dataWellBeing: DataType[] = [
    {
        title: 'Objective 1:  PSI develops skills and encourages the use of strategies that promote student and faculty resilience.\n',
        items: [
            { tag: 'partially_met', pandemic: true, text: 'Students and faculty have been explicitly taught skills on resilience.' },
            { tag: 'partially_met', pandemic: true, text: 'Community members agree that learners understand appropriate strategies to use and have access to support when they face challenges.' },
            { tag: 'partially_met', pandemic: true, text: 'Community members feel that PSI supports students socially and emotionally' },
            { tag: 'met', text: 'Staff members feel that PSI values their well-being' }
        ]
    },
    {
        title: 'Objective 2: PSI ensures that academic and extracurricular workloads allow for a balance of work, play, and sleep.',
        items: [
            { tag: 'partially_met', pandemic: true, text: 'Students and Faculty report having an appropriate workload, and are satisfied with it.' },
            { tag: 'partially_met', pandemic: true, text: 'Students and faculty report that the schedule fits learning needs' },
            { tag: 'not_met', pandemic: true, text: 'Students and parents agree that participation in co-curricular activities is beneficial' }
        ]
    }
];

const Item = (item: ItemType) => {
    const size = 24;
    return (
        <Wrapper row margin="sm" valign="center" style={ { alignItems: 'stretch' } }>
            <Wrapper
                margin="sm"
                style={ { minHeight: '100%', flex: '0 0 auto', width: 'auto' } }
            >
                <Wrapper
                    flex="0"
                    valign="center"
                    style={ {
                        minWidth:        size,
                        maxWidth:        8,
                        minHeight:       32,
                        borderRadius:    4,
                        marginTop:       6,
                        flex:            '0 0 auto',
                        backgroundColor: colorLegend[ item.tag ].color,
                        ...item.pandemic ? colorLegend.pandemic.wrapper.style : undefined
                    } }
                >
                    { /* <Paragraph size="sm" style={ { textTransform: 'uppercase' } }> */ }
                    { /*    { colorLegend[ item.tag ].text } */ }
                    { /* </Paragraph> */ }
                </Wrapper>
            </Wrapper>
            <Wrapper flex={ 1 } style={ { flex: '1 1 100%' } } valign="center">
                <Paragraph>{ item.text }</Paragraph>
            </Wrapper>
        </Wrapper>
    );
};

const Section = (section: DataType) => (
    <Wrapper frame="solid-grey" padding="md" margin="md">
        <Title size="h4" weight="regular" margin="sm">
            { md(section.title) }
        </Title>
        <Divider margin="md" />
        { section.items.map((item, index) => <Item key={ index } { ...item } />) }
    </Wrapper>
);

const Chapter = (props: {
    title: string;
    description: string;
    data: DataType[];
}) => (
    <Wrapper>
        <Title margin="sm">{ md(props.title) }</Title>
        <Paragraph margin="md">{ md(props.description) }</Paragraph>
        { props.data.map((section, index) => <Section key={ index } { ...section } />) }
        <Divider margin="xxl" transparent />
    </Wrapper>
);

export const BenchmarkChart = () => (
    <Wrapper>
        <Chapter
            title="Community, Diversity & Inclusion: PSI is a diverse community where people feel included."
            description="Our motto, “a place where we belong” underscores the value PSI holds for community, diversity and inclusion.  The pandemic affected our ability to fully come together; however, we remained committed to ensuring that people have opportunities to feel a strong  sense of belonging.  In addition, the Diversity, Equity and Inclusion initiative has highlighted this commitment and provided additional impetus to improve further in all areas."
            data={ dataCommunity }
        />
        <Chapter
            title="Faculty: PSI attracts, develops and retains the best faculty talent."
            description="Members of faculty are fundamental to delivering an exceptional school experience to our students; academic, social and emotional.  As such, we recognise the importance of attracting, developing and retaining a high quality staff.  The pandemic impacted on our faculty development, as we were able to affordably bring in an expanded variety of experts to work with faculty members virtually, thus enhancing our professional development opportunities. We experienced a very strong recruitment season, with a high number of applications to all open positions. We were delighted to be successful in hiring the vast majority of our top choice candidates."
            data={ dataFaculty }
        />
        <Chapter
            title="Student Success: PSI students develop knowledge, skills, dispositions and experiences needed for success."
            description="Providing high quality learning experiences for student development remains our core priority at PSI.  The pandemic-based health and safety precautions and local regulations meant that  adjustments to our programme were necessary to incorporate a combination of blended and remote learning models.  This had an impact on our ability to offer the variety of co-curricular and enrichment activities that we have made available  in the past."
            data={ dataStudentSuccess }
        />
        <Chapter
            title="Well-being: PSI provides an environment that promotes resilience and balance."
            description="PSI’s focus on well-being has always been paramount.  This year its importance was highlighted, as the pandemic required community members to remain resilient through periods of significant uncertainty and time pressures associated with dealing with a crisis.  Additionally, our co-curricular programme, which provides a balance to many of our community members, was impacted by pandemic restrictions."
            data={ dataWellBeing }
        />
        <ColorLegend colorLegend={ Object.keys(colorLegend).map((key) => colorLegend[ key ]) } />
    </Wrapper>
);
