import React from 'react';
import {
    Bar, BarChart, LabelProps, ResponsiveContainer, Tooltip, XAxis, YAxis
} from 'recharts';
import { Wrapper }        from '../wrapper/wrapper.component';
import { ChartPropsType } from './types';

export type PropsType = {
  width?: number | string;
  height?: number | string;
  size?: number;
  fill?: string;
  isAnimationActive?: boolean;
  innerRadius?: number;
  paddingAngle?: number;
  tooltip?: boolean;
  colors?: string[];
  labelComponent?: (v: LabelProps) => void;
} & ChartPropsType;

export const ChartBar = (props: PropsType) => {
    const customLabel = (v: LabelProps) => {
        const { x, y, value, name } = v;
        console.log(v.value, v);
        return (
            <text
                x={ x + 5 }
                y={ y + 10 }
                fill="white"
                fontSize="14px"
                // textAnchor={ x > cx ? 'start' : 'end' }
                dominantBaseline="central"
            >
                { `${ (value).toFixed(2) }${ props.unit ? props.unit : '' }` }
            </text>
        );
    };
    return (
        <Wrapper style={ { fontSize: 12 } }>
            { ' ' }
            <ResponsiveContainer width="100%" height={ props.size }>
                <BarChart width={ props.width } height={ props.height } data={ props.data }>
                    { Object
                        .keys(props.data[ 0 ])
                        .filter((key) => key.includes('value'))
                        .map((key, index) => (
                            <Bar
                                key={ key }
                                stackId="a"
                                dataKey={ key }// label={ { position: 'insideTopLeft' } } // label={ (d) => <Label { ...d } /> }
                                fill={ props.colors[ index ] }
                                isAnimationActive={ props.isAnimationActive }
                                maxBarSize={ 80 }
                                label={ props.labelComponent || customLabel }
                                aria-label="sdf"
                            />
                        )) }
                    { ' ' }
                    <XAxis dataKey="name" />
                    { /* <YAxis /> */ }
                    { props.tooltip && <Tooltip /> }
                </BarChart>
            </ResponsiveContainer>
            { ' ' }

        </Wrapper>
    );
};
ChartBar.defaultProps = {
    isAnimationActive: false,
    size:              280
};
