import React           from 'react';
import { GalleryGrid } from '../../../../../lib/components/gallery-grid/gallery-grid';
// @ts-ignore
import Photo01         from './01.jpg';
// @ts-ignore
import Photo02         from './02.jpg';
// @ts-ignore
import Photo03         from './03.jpg';
// @ts-ignore
import Photo04         from './04.jpg';
// @ts-ignore
import Photo05         from './05.jpg';
// @ts-ignore
import Photo06         from './06.jpg';
// @ts-ignore
import Photo07         from './07.jpg';
// @ts-ignore
import Photo08         from './08.jpg';
// @ts-ignore
import Photo09         from './09.jpg';
// @ts-ignore
import Photo10         from './10.jpg';
// @ts-ignore
import Photo11         from './11.jpg';
// @ts-ignore
import Photo12         from './12.jpg';
// @ts-ignore
import Photo13         from './13.jpg';
// @ts-ignore
import Photo14         from './14.jpg';
// @ts-ignore
import Photo15         from './15.jpg';
// @ts-ignore
import Photo16         from './16.jpg';
// @ts-ignore
import Photo17         from './17.jpg';
// @ts-ignore
import Photo18         from './18.jpg';
// @ts-ignore
import Photo19         from './19.jpg';
// @ts-ignore
import Photo20         from './20.jpg';
// @ts-ignore
import Photo21         from './21.jpg';
// @ts-ignore
import Photo22         from './22.jpg';
// @ts-ignore
import Photo23         from './23.jpg';
// @ts-ignore
import Photo24         from './24.jpg';

const Photos = () => {
    const photolist = [
        { title: 'Jassem, A', photo: Photo01 },
        { title: 'Arencibia Lara, P', photo: Photo02 },
        { title: 'Yde, B', photo: Photo03 },
        { title: 'Emily, C', photo: Photo04 },
        { title: 'Ecem Sena, C', photo: Photo05 },
        { title: 'Sophia, C', photo: Photo06 },
        { title: 'Zoe, C', photo: Photo07 },
        { title: 'Sofie, D', photo: Photo08 },
        { title: 'Cody, F', photo: Photo09 },
        { title: 'Kai, F', photo: Photo10 },
        { title: 'Nadim, G', photo: Photo11 },
        { title: 'Elyas, G', photo: Photo12 },
        { title: 'Eva, H', photo: Photo13 },
        { title: 'Mykyta, I', photo: Photo14 },
        { title: 'Bogdan, I', photo: Photo15 },
        { title: 'Grant, J', photo: Photo16 },
        { title: 'Pranaav, K', photo: Photo17 },
        { title: 'Jokubas, L', photo: Photo18 },
        { title: 'Mariia, M', photo: Photo19 },
        { title: 'Daria, M', photo: Photo20 },
        { title: 'Kirill, P', photo: Photo21 },
        { title: 'Maksym, S', photo: Photo22 },
        { title: 'Arvid, S', photo: Photo24 },
        { title: 'Andrew, S', photo: Photo23 }

    ];
    return <GalleryGrid data={ photolist } />;
};

export default Photos;
