import React                            from 'react';
import { GalleryGrid, GalleryGridType } from '../../../../../lib/components/gallery-grid/gallery-grid';
import { Wrapper }                      from '../../../../../lib/components/wrapper/wrapper.component';
import P01_alex_munteanu                from  './updated/01_alex_munteanu.jpg';
import P02_juliana_ballard              from  './updated/02_juliana ballard.jpg';
import P03_andrius_nemickas             from  './updated/03_andrius_nemickas.jpg';
import P04_ashley_mulroney              from  './updated/04_ashley_mulroney.jpg';
import P05_rachel_caldwell              from  './updated/05_rachel_caldwell.jpg';
import P06_peter_erben                  from  './updated/06_peter_erben.jpg';
import P07_nataliya_fiala               from  './updated/07_nataliya_fiala.jpg';
import P08_olena_malynska               from  './updated/08_olena_malynska.jpg';
import P09_tatiana_ohara                from  './updated/09_tatiana_ohara.jpg';
import P10_annahita_sarty               from  './updated/10_annahita_sarty.jpg';
import P11_oksana_strashna              from  './updated/11_oksana_strashna.jpg';
import P12_robert_gordon_mcneil         from  './updated/12_robert_gordon_mcneil.jpg';
import P13_lina_nemchenko               from  './updated/13_lina_nemchenko.jpg';
import P14_tawnia_sanford               from  './updated/14_tawnia_sanford.png';
import P15_nora_soliman                 from  './updated/15_nora_soliman.jpg';

const photolist: GalleryGridType['data'] = [
    {
        title:    'Alex Munteanu',
        subtitle: 'USA, \nBoard Chair,\n'
            + 'Finance & Risk Management Committee, '
            + 'Governance and Strategy Committee, '
            + 'Facilities and Building Committee\n',
        photo: P01_alex_munteanu
    },
    {
        title:    'Juliana Ballard',
        subtitle: 'USA, \nBoard Vice-Chair,\nGovernance & Strategy Committee',
        photo:    P02_juliana_ballard
    },
    {
        title:    'Andrius Nemickas',
        subtitle: 'USA,\nSecretary,\n'
            + 'Governance and Strategy Committee / Facilities and Building Committee',
        photo: P03_andrius_nemickas
    },
    {
        title:    'Ashley Mulroney',
        subtitle: 'Canada,\nTreasurer\n'
            + 'Chair of Finance & Risk Management Committee',
        photo: P04_ashley_mulroney
    },
    {
        title:    'Rachel Caldwell',
        subtitle: 'UK,\nDirector',
        photo:    P05_rachel_caldwell
    },
    {
        title:    'Peter Erben',
        subtitle: 'Denmark,\nGovernance & Strategy Committee\n',
        photo:    P06_peter_erben
    },
    {
        title:    'Nataliya Fiala',
        subtitle: 'Ukraine,\nChair of Governance and Strategy Committee \n',
        photo:    P07_nataliya_fiala
    },
    {
        title:    'Olena Malynska',
        subtitle: 'Ukraine,\nFinance and Risk Management Committee',
        photo:    P08_olena_malynska
    },
    {
        title:    'Tatiana O’Hara',
        subtitle: 'USA,\nFacilities and Building Committee',
        photo:    P09_tatiana_ohara
    },
    {
        title:    'Annahita Sarty',
        subtitle: 'Canada,\nNon Voting Member, PSI Staff Representative\n',
        photo:    P10_annahita_sarty
    },
    {
        title:    'Oksana Strashna',
        subtitle: 'Ukraine,\nFinance & Risk Management Committee\n',
        photo:    P11_oksana_strashna
    },
    {
        title:    'Robert Gordon McNeil',
        subtitle: 'UK,\nFinance & Risk Management Committee\n',
        photo:    P12_robert_gordon_mcneil
    },
    {
        title:    'Lina Nemchenko',
        subtitle: 'Ukraine,\nChair of Facilities and Building Committee \n',
        photo:    P13_lina_nemchenko
    },
    {
        title:    'Tawnia Sanford Ammar',
        subtitle: 'Canada,\nPTA President, Non-Voting Member',
        photo:    P14_tawnia_sanford
    },
    {
        title:    'Nora Soliman',
        subtitle: 'USA,\nGovernance & Strategy Committee\n',
        photo:    P15_nora_soliman
    }

];

const Photos = () => <GalleryGrid data={ photolist } />;

export default Photos;
