/* eslint-disable max-len */
import React           from 'react';
import { ColorLegend } from '../../../../../lib/annual-report/survey/colorLegend';
import { SurveyV2 }    from '../../../../../lib/annual-report/survey/survey-v2';
import { Title }       from '../../../../../lib/components/title/title';
import { Wrapper }     from '../../../../../lib/components/wrapper/wrapper.component';

type DataChartItemType = {
    agree: number;
    disagree: number;
    // color?: 'green1' | 'green2' | 'red1' | 'red2' | 'red3';
    agreeColor?: 'green1' | 'green2' | 'red1' | 'red2' | 'red3';
    disagreeColor?: 'green1' | 'green2' | 'red1' | 'red2' | 'red3';
    message?: string;
}
type DataCommunityType = {
    title: string;
    question: string;
    data: {
        subject: 'parent'|'primary'|'secondary'|'faculty'|'staff';
        question?: string;
        current?: DataChartItemType;
        previous?: DataChartItemType;
    }[];
    after: Record<string, string>;

}[]

const colorLegend = {
    green1: 'More than 90% \nAgree/Strongly Agree',
    green2: '2020 Agrees increased \nby least 10% vs. 2019',
    red1:   'More than 15% Disagree',
    red2:   '2020 agrees dropped \nby least 10% vs. 2019',
    red3:   '2020 disagrees increased \nby least 10% vs. 19'
};

const ClimateSurveyChart = () => {
    const data: DataCommunityType = [
        {
            title:    'Distance Learning Programme.',
            question: 'I am satisfied with PSI\'s Distance Learning Programme.',
            data:     [
                {
                    subject: 'parent',
                    current: { agree: 78, disagree: 21, disagreeColor: 'red1' }
                },
                {
                    subject: 'primary',
                    current: { agree: 71, disagree: 17, disagreeColor: 'red1' }
                },
                {
                    subject: 'secondary',
                    current: { agree: 65, disagree: 31, disagreeColor: 'red1' }
                },
                {
                    subject: 'faculty',
                    current: { agree: 93, disagree: 3, agreeColor: 'green1' }
                },
                { subject: 'staff' }
            ],
            after: {
                actions: 'PSI plans to convene focus groups with parents and students to ascertain their satisfaction with the new Remote Learning and Blended Learning Models',
                example: '"Distance learning IS NOT easy for all students. I prefer classroom instruction whenever possible. Good work from PSI anyway in the difficult situation." \n— _Secondary Parent_ \n"I feel that my child is missing out on a lot of learning which would have happened in real school and that there is hardly enough interaction with the teacher and other children." \n— _Primary Parent_'

            }
        },
        {
            title:    'Communication during the pandemic.',
            question: 'I am satisfied with PSI\'s communications during the pandemic.\t',
            data:     [
                {
                    subject: 'parent',
                    current: { agree: 91, disagree: 9, agreeColor: 'green1' }
                },
                {
                    subject: 'primary',
                    current: { agree: 84, disagree: 9 }
                },
                {
                    subject: 'secondary',
                    current: { agree: 82, disagree: 12 }
                },
                {
                    subject: 'faculty',
                    current: { agree: 97, disagree: 2, agreeColor: 'green1' }
                },
                {
                    subject: 'staff',
                    current: { agree: 97, disagree: 3, agreeColor: 'green1' }
                }
            ],
            after: {
                actions: 'PSI plans to Implement a new learning management system, Toddle, to improve communication about learning with students.',
                example: '"I think Its a good program but what\'s challenging is that, once that you go to this program there\'s a link to another program and that continues, tough I like how it \'s organized." \n— _Primary Student_. \n"Sometimes it is a little confusing, but I managed to handle my situation well." \n— _Secondary Student_'
            }
        },
        {
            title:    'Wellbeing during the pandemic.',
            question: 'PSI was supportive of my / my child/ren\'s wellbeing during the pandemic.',
            data:     [
                {
                    subject: 'parent',
                    current: { agree: 77, disagree: 12 }
                },
                {
                    subject: 'primary',
                    current: { agree: 74, disagree: 5 }
                },
                {
                    subject: 'secondary',
                    current: { agree: 63, disagree: 28, disagreeColor: 'red1' }
                },
                {
                    subject: 'faculty',
                    current: { agree: 91, disagree: 5, agreeColor: 'green1' }
                },
                {
                    subject: 'staff',
                    current: { agree: 97, disagree: 0, agreeColor: 'green1' }
                }
            ],
            after: {
                notes:   '',
                actions: 'PSI is adding a new Advisory Programme in Secondary School to provide additional supports to secondary students.',
                example: '"It is actually very hard, I\'m always getting things left behind and accidentally missed, I cannot focus properly as I did before and it is much harder to get help."  \n— _Secondary Student_ \n"I can barely keep up with my classes and when I do, my free time suffers." \n— _Secondary Student_'
            }
        }
    ];
    return (
        <Wrapper margin="xxl">
            <Title margin="lg" size="h2">Distance Learning Climate Survey Questions</Title>
            <SurveyV2 data={ data } colorLegend={ colorLegend } />
            <ColorLegend />
        </Wrapper>
    );
};

export default ClimateSurveyChart;
