import React        from 'react';
import { Text }     from '../../components/text/text';
import { Wrapper }  from '../../components/wrapper/wrapper.component';
import { getTheme } from './getTheme';

type PropsType = {
  colorLegend?: Record<'green1'|'green2'|'red1'|'red2'|'red3', string>;
}
const DefaultProps = {
    colorLegend: {
        green2: '2020 Agrees increased \nby least 10% vs. 2019',
        green1: 'More than 90% \nAgree/Strongly Agree',
        red1:   'More than 15% Disagree',
        red2:   '2020 agrees dropped \nby least 10% vs. 2019',
        red3:   '2020 disagrees increased \nby least 10% vs. 19'
    }
};

export const ColorLegend = (props: PropsType) => {
    const renderColorLegend = () => Object.keys(props.colorLegend).map((color, index) => (
        <Wrapper
            key={ index }
            margin="md"
            flex={ 1 }
            row
            style={ { width: 'auto', flex: '1 1 20%', minWidth: '15rem' } }
            xsm={ { direction: 'column' } }
        >
            <Wrapper
                margin="sm"
                style={ {
                    minWidth:     16,
                    maxWidth:     16,
                    minHeight:    16,
                    maxHeight:    16,
                    borderRadius: 16,
                    ...getTheme(color)
                } }
            />
            <Text margin="md" size="xsm" style={ { marginTop: '-1px' } } preWrap>{ props.colorLegend[ color ] }</Text>
        </Wrapper>
    ));
    return (
        <Wrapper
            frame="solid-white"
            style={ {
                position:    'sticky',
                bottom:      0,
                left:        '-4.8rem',
                marginLeft:  '-4.8rem',
                paddingLeft: '3.2rem',
                width:       '100vw',
                maxWidth:    'calc(100vw + 4.8rem)',
                minWidth:    'calc(100vw + 4.8rem)',
                overflowX:   'scroll',
                boxShadow:   '0px 0px 20px rgba(0, 0, 0, 0.1)'
            } }
            // hide={ [ '', 'sm' ] }
        >
            { /* <Divider color="grey-2" /> */ }
            <Wrapper row padding="md" style={ { overflowY: 'scroll' } }>
                { renderColorLegend() }
            </Wrapper>
        </Wrapper>
    );
};

ColorLegend.defaultProps = DefaultProps;
