import React           from 'react';
import { GalleryGrid } from '../../../../../lib/components/gallery-grid/gallery-grid';
import Photo01         from './photo/01.jpg';
import Photo02         from './photo/02.jpg';
import Photo03         from './photo/03.jpg';
import Photo04         from './photo/04.jpg';
import Photo05         from './photo/05.jpg';
import Photo06         from './photo/06.jpg';
import Photo07         from './photo/07.jpg';
import Photo08         from './photo/08.jpg';
import Photo09         from './photo/09.jpg';
import Photo10         from './photo/10.jpg';
import Photo11         from './photo/11.jpg';
import Photo12         from './photo/12.jpg';
import Photo13         from './photo/13.jpg';
import Photo14         from './photo/14.jpg';
import Photo15         from './photo/15.jpg';
import Photo16         from './photo/16.jpg';
import Photo17         from './photo/17.jpg';
import Photo18         from './photo/18.jpg';
import Photo19         from './photo/19.jpg';
import Photo20         from './photo/20.jpg';
import Photo21         from './photo/21.jpg';
import Photo22         from './photo/22.jpg';
import Photo23         from './photo/23.jpg';
import Photo24         from './photo/24.jpg';
import Photo25         from './photo/25.jpg';

const Photos = () => {
    const photolist = [
        { title: 'Ana C.', photo: Photo01 },
        { title: 'Anatolii K.', photo: Photo02 },
        { title: 'Andreas S.', photo: Photo03 },
        { title: 'Aram A.', photo: Photo04 },
        { title: 'Bogdan I.', photo: Photo05 },
        { title: 'Elyse D.', photo: Photo06 },
        { title: 'Emil M.', photo: Photo07 },
        { title: 'Emilija S.', photo: Photo08 },
        { title: 'Ferdinand R.', photo: Photo09 },
        { title: 'Haesung O.', photo: Photo10 },
        { title: 'Hannah H.', photo: Photo11 },
        { title: 'Ivan D.', photo: Photo12 },
        { title: 'Ivan P.', photo: Photo13 },
        { title: 'Justine B.', photo: Photo14 },
        { title: 'Kateryna U.', photo: Photo15 },
        { title: 'Lev L.', photo: Photo16 },
        { title: 'Louis S.', photo: Photo17 },
        { title: 'Markian V.', photo: Photo18 },
        { title: 'Medeine V.', photo: Photo19 },
        { title: 'Melisa S.', photo: Photo20 },
        { title: 'Nadia O.', photo: Photo21 },
        { title: 'Oliver H.', photo: Photo22 },
        { title: 'Selin E.', photo: Photo23 },
        { title: 'Sial H.', photo: Photo24 },
        { title: 'Siddharth G.', photo: Photo25 }

    ];
    return <GalleryGrid data={ photolist } />;
};

export default Photos;
