/* eslint-disable max-len */
import React        from 'react';
import { Divider }  from '../../components/divider/divider';
import { Text }     from '../../components/text/text';
import { Title }    from '../../components/title/title';
import { Tooltip }  from '../../components/tooltip/tooltip.component';
import { Wrapper }  from '../../components/wrapper/wrapper.component';
import { colors }   from '../../styles/color';
import { md }       from '../../util/md';
import { getTheme } from './getTheme';

export type ClimateSurveyColorsType = 'green1' | 'green2' | 'red1' | 'red2' | 'red3';

export type ClimateSurveyItemType = {
    agree: number;
    disagree: number;
    agreeColor?: ClimateSurveyColorsType;
    disagreeColor?: ClimateSurveyColorsType;
    message?: string;
    dropped?: boolean;
    raised?: boolean;
}
export type ClimateSurveyType = {
    title?: string;
    question?: string;
    description?: string;
    data: {
        subject: 'parent'|'primary'|'secondary'|'faculty'|'staff';
        question?: string;
        numbers?: ClimateSurveyItemType[];
    }[];
    after: Record<string, string>;

}[]

const text = {
    parent:    'Parent',
    primary:   'G3-5 \nStudents',
    secondary: 'Secondary \nStudents',
    faculty:   'Faculty',
    staff:     'Staff',
    years:     [ '2021', '2020', '2019' ],
    goal:      'Goal results',
    notes:     'Notes',
    actions:   'Planned Actions',
    example:   'Example Survey Comments '
};

type PropsType = {
  data: ClimateSurveyType;
  t?: typeof text;
  colorLegend?: Record<ClimateSurveyColorsType, string>;
}

export const SurveyV3 = ({ data, t }: PropsType) => {
    const getColor = (type: 'agree'|'disagree', item: ClimateSurveyItemType) => {
        if (type === 'agree') {
            if (item.dropped) {
                return getTheme('red3');
            }
            if (item.raised) {
                return getTheme('green2');
            }
            if (item.agree > 90) {
                return getTheme('green1');
            }
        }
        if (type === 'disagree') {
            if (item.disagree > 15) {
                return getTheme('red2');
            }
        }
    };
    const renderChartItem = (item, index) => (
        <Wrapper
            margin="xs"
            valign="bottom"
            align="center"
            style={ {
                minHeight: '20rem',
                // opacity:   index !== 0 ? 0.61 : 1,
                maxWidth:  '3.5rem'
            } }
        >
            <Tooltip message="" menuWidth="20rem" fullWidth>
                <Wrapper
                    frame="solid-grey"
                    valign="center"
                    radius="xs"
                    margin="xs"
                    style={ { minHeight: '15rem' } }
                >

                    { (100 - item.disagree - item.agree > 0) && (
                        <Wrapper style={ { minHeight: `${ (100 - item.disagree - item.agree) * 0.15 }rem`, maxHeight: `${ (100 - item[ 1 ] - item[ 0 ]) * 0.2 }rem` } } />
                    ) }
                    { item.disagree > 0 && (
                        <Wrapper
                            valign="bottom"
                            align="center"
                            // frame="solid-key-translucent"
                            // color="key"
                            padding="xxs"
                            style={ {
                                minHeight:       `${ item.disagree * 0.15 }rem`,
                                maxHeight:       `${ item.disagree * 0.2 }rem`,
                                color:           colors.dark.hex(),
                                backgroundColor: colors.gray.darken(0.1).hex(),
                                ...getColor('disagree', item)
                            } }
                        >
                            <Title size="h5" style={ { fontSize: '1.3rem' } }>
                                { item.disagree }
                                %
                            </Title>
                        </Wrapper>
                    ) }

                    <Wrapper
                        valign="top"
                        align="center"
                        padding="xxs"
                        style={ {
                            minHeight:       `${ item.agree * 0.15 }rem`,
                            maxHeight:       `${ item.agree * 0.2 }rem`,
                            backgroundColor: colors.gray.darken(0.3).hex(),
                            color:           colors.dark.hex(),
                            ...getColor('agree', item)
                        } }
                    >
                        <Title size="h5" style={ { fontSize: '1.3rem' } }>
                            { item.agree }
                            %
                        </Title>
                    </Wrapper>

                </Wrapper>
            </Tooltip>
            <Text size="xsm" bold align="left">{ text.years[ index ] }</Text>
        </Wrapper>
    );
    const renderChartBlock = (item) => item.data.map((dataItem, dataIndex) => {
        console.log(dataItem);
        if (!dataItem) {
            return null;
        }
        return (
            <Wrapper key={ dataIndex } margin="md" style={ { flex: '1 1 20%', maxWidth: '20%' } }>
                <Title
                    size="h5"
                    margin="sm"
                    color={ dataItem.numbers ? 'dark' : 'grey-6' }
                    style={ { minHeight: '6rem' } }
                >
                    { t[ dataItem.subject ] }
                </Title>
                <Wrapper row>
                    { dataItem.numbers && dataItem.numbers.map((num, index) => renderChartItem(num, index)) }
                </Wrapper>
            </Wrapper>
        );
    });
    const renderLegend = () => (
        <Wrapper>
            <Divider margin="lg" transparent />
            <Divider margin="sm" color="grey-3" />
            <Text size="sm">
                { md('Agree, Strongly Agree _(bottom)_ / Disagree, Strongly Disagree _(top)_') }
            </Text>

        </Wrapper>
    );
    const renderAfter = (item) => (
        // <Wrapper style={ { position: 'absolute', right: '-34rem', maxWidth: '30rem', marginTop: '-3.2rem' } }>
        <Wrapper
            direction="column"
            lg={ { direction: 'row' } }
            sm={ { direction: 'column' } }
            flex="0"
            style={ { width: 'auto' } }
        >
            { Object.keys(item.after).map((afterKey, afterIndex) => {
                if (item.after[ afterKey ]) {
                    return (
                        <Wrapper key={ afterIndex } style={ { maxWidth: '35rem' } } margin="md">
                            <Title size="h5" margin="xs">{ t[ afterKey ] }</Title>
                            <Text size="sm" preWrap style={ { width: '100%' } }>{ md(item.after[ afterKey ]) }</Text>
                        </Wrapper>
                    );
                }
                return null;
            }) }
        </Wrapper>
    );

    return (
        <>
            { data.map((item, index) => (
                <Wrapper key={ index } margin="xxl" style={ { maxWidth: '100vw' } }>
                    { item.title && <Text uppercase margin="xs" size="xsm" color="grey-6">{ item.title }</Text> }
                    { item.question && <Title margin="md">{ item.question }</Title> }
                    { item.description && <Text margin="md">{ item.description }</Text> }
                    <Wrapper row fullWidth md={ { direction: 'column' } } overflow="hidden" style={ { maxWidth: '100vw' } }>
                        <Wrapper frame="solid-grey" padding="lg" margin="lg" style={ { maxWidth: '80rem', overflowY: 'scroll' } } overflow="scroll">

                            <Wrapper row style={ { minWidth: '60rem' } }>
                                { renderChartBlock(item) }
                            </Wrapper>

                            { renderLegend() }
                        </Wrapper>
                        { renderAfter(item) }
                    </Wrapper>
                </Wrapper>

            )) }

        </>
    );
};
SurveyV3.defaultProps = {
    t: {
        parent:    'Parent',
        primary:   'G3-5 \nStudents',
        secondary: 'Secondary \nStudents',
        faculty:   'Faculty',
        staff:     'Staff',
        years:     [ '2021', '2020', '2019' ],
        goal:      'Goal results',
        notes:     'Notes',
        actions:   'Planned Actions',
        example:   'Example Survey Comments '
    }
};
