
import { useEffect, useState } from 'react';

type UseDropdownType = [ string ];

export function useAutoPosition(p: {
  dropdownEl: HTMLElement | null;
  triggerEl: HTMLElement | null;
  openState: boolean;
  position?: string;
}): UseDropdownType {
    const [ autoPosition, setAutoPosition ] = useState('');

    useEffect(() => {
        if (p.openState && p.position && p.position.split('-')[ 0 ] === 'auto') {
            if (p.dropdownEl && p.triggerEl) {
                const triggerHeight = p.triggerEl.getBoundingClientRect().height;
                const triggerTop = p.triggerEl.getBoundingClientRect().top;
                const dropHeight = p.dropdownEl.getBoundingClientRect().height;
                const windowHeight = window.innerHeight;

                if ((windowHeight - triggerTop - triggerHeight - dropHeight) > 8) {
                    setAutoPosition(`bottom-${ p.position.split('-')[ 2 ] }`);
                } else {
                    setAutoPosition(`top-${ p.position.split('-')[ 2 ] }`);
                }
            }
        }
    }, [ p.openState ]);

    return [ autoPosition ];
}
