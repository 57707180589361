import React     from 'react';
import { md }    from '../../util/md';
import { Title } from '../title/title';

type PropsType = {
    children: React.ReactNode;
};

export const ChartTitle = (props: PropsType) => (
    <Title
        margin="xxl"
        xsm={ { size: 'h4', align: 'center' } }
        // style={ { fontSize: '28px' } }
        fullWidth
    >
        { md(props.children) }
    </Title>
);
