import React                         from 'react';
import { LabelProps }                from 'recharts';
import { ChartBar }                  from '../../components/charts/bar-chart.component';
import { ChartLegend }               from '../../components/charts/legend';
import { ChartTitle }                from '../../components/charts/title';
import { TitlePropsType }            from '../../components/title/title';
import { Wrapper, WrapperPropsType } from '../../components/wrapper/wrapper.component';
import { colors }                    from '../../styles/color';
import { cn }                        from '../../util/bem';
import { ItemDataType }              from './radial-chart-block';

type BarChartPropsType ={
    data: ItemDataType[];
    title: React.ReactNode;
    titleProps: Partial<TitlePropsType>;
    wrapper?: WrapperPropsType;
    colors?: string[];
    legend: ItemDataType[];
    labelComponent?: (v: LabelProps) => void;
}

const className = cn('bar-chart-block');

export const BarChartBlock = (props: BarChartPropsType) => {
    const getColor = (index) => colors.key.lighten(0.03 * index).rotate(-5 * index).hex();

    return (
        <Wrapper
            className={ className() }
            frame="solid-grey"
            radius="md"
            margin="md"
            row
            columns={ 2 }
            padding="xxl"
            valign="center"
            xsm={ { direction: 'column', columns: 1, align: 'center', valign: 'center' } }
            { ...props.wrapper }
        >
            <Wrapper style={ { height: '100%' } }>
                <ChartTitle>{ props.title }</ChartTitle>
                <Wrapper flex={ 1 } xsm={ { display: 'none' } } />
                <Wrapper xsm={ { display: 'none' } }>
                    <ChartLegend
                        data={ props.legend || props.data }
                        colorFunction={ (index) => getColor(index) }
                    />
                </Wrapper>
            </Wrapper>
            <Wrapper xsm={ { margin: 'xl' } } align="center" valign="center">
                <ChartBar
                    data={ props.data }
                    colorFunction={ (index) => getColor(index) }
                    colors={ props.colors }
                    labelComponent={ props.labelComponent }
                />
            </Wrapper>
            <Wrapper display="none" xsm={ { display: 'flex' } } style={ { flex: '1 1 auto', width: 'auto' } }>
                <ChartLegend data={ props.data } colorFunction={ (index) => getColor(index) } />
            </Wrapper>
        </Wrapper>
    );
};
