import React, { useState }  from 'react';
import { Divider }          from '../../../../../../lib/components/divider/divider';
import { Flag }             from '../../../../../../lib/components/flag/flag';
import { Table }            from '../../../../../../lib/components/table/table';
import { Text }             from '../../../../../../lib/components/text/text';
import { Title }            from '../../../../../../lib/components/title/title';
import { Wrapper }          from '../../../../../../lib/components/wrapper/wrapper.component';
import { colors }           from '../../../../../../lib/styles/color';
import { cn }               from '../../../../../../lib/util/bem';
import { useCommunityData } from '../../../../data/community/community-data';
import PsiCommunityMap      from './map';
import './style.scss';

const PsiCommunityChart = () => {
    const year = '_2021';
    const data = useCommunityData().filter((item) => item.node[ year ] > 0);
    const [ current, setCurrent ] = useState('');
    const [ currentID, setCurrentID ] = useState('');
    const [ tooltipX, setTooltipX ] = useState(0);
    const [ tooltipY, setTooltipY ] = useState(0);
    const total = data.length;

    const getSum = () => {
        let sum = 0;
        for (const el in data) {
            // eslint-disable-next-line no-prototype-builtins
            if (data.hasOwnProperty(el)) {
                sum += parseFloat(data[ el ].node[ year ]);
            }
        }
        return sum;
    };
    const getNonUkrainan = () => {
        const ukrainian = data.filter((item) => item.node.id === 'UA')[ 0 ].node[ year ];
        return getSum() - ukrainian;
    };

    const move = (e) => {
        if (e.target.id) {
            const c = data.filter((item) => item.node.id === e.target.id);
            if (c && c[ 0 ] && c[ 0 ].node) {
                setTimeout(() => {
                    setCurrent(`${ c[ 0 ].node.country }: ${ c[ 0 ].node[ year ] }`);
                    setCurrentID(e.target.id);
                }, 1);
            }
        }
        setTooltipX(e.x);
        setTooltipY(e.y);
    };
    const handlerOnMouseEnter = () => {
        document.addEventListener('mousemove', move);
    };
    const handlerOnMouseLeave = () => {
        setTimeout(() => document.removeEventListener('mousemove', move), 400);
        setCurrent('');
    };

    const generateColors = () => {
        let out = '#custom:{fill:gray;}';
        data.forEach(({ node }) => {
            out += `#${ node.id }{fill:${ colors.key.darken(node[ year ] / 180).hex() }}`;
        });
        return out;
    };
    const renderTooltip = () => (
        <Wrapper
            frame="solid-dark"
            radius="md"
            padding="xs"
            align="center"
            valign="center"
            row
            style={ {
                position:   'fixed',
                top:        tooltipY + -44,
                left:       tooltipX + -16,
                width:      'auto',
                // opacity:    current ? 1 : 0,
                transition: 'all .2s ease'
            } }
        >
            { ' ' }
            <Flag size={ 16 } country={ currentID } />
            { ' ' }
            <Divider transparent margin="sm" />
            { ' ' }
            <Text size="sm">{ current }</Text>
        </Wrapper>
    );

    return (
        <>
            <style>
                { `.land{fill:${ colors.gray.darken(0.1).hex() }}` }
                { ' ' }
                { generateColors() }
            </style>
            <Divider transparent margin="sm" />
            <Wrapper className={ cn('community')() } fullWidth frame="solid-grey" padding="xxl" overflow="hidden">
                <Wrapper row xsm={ { direction: 'column' } }>
                    <Wrapper>
                        <Title size="h1">{ getSum() }</Title>
                        <Text size="md">Students</Text>
                    </Wrapper>
                    <Wrapper>
                        <Title size="h1">{ getNonUkrainan() }</Title>
                        <Text size="md"> International Students</Text>
                    </Wrapper>
                    <Wrapper>
                        <Title size="h1">367</Title>
                        <Text size="md">Families</Text>
                    </Wrapper>
                    <Wrapper>
                        <Title size="h1">{ total }</Title>
                        <Text size="md">Nationalities</Text>
                    </Wrapper>
                </Wrapper>
                <Divider margin="xxl" transparent />
                { ' ' }
                <Divider margin="xxl" />
                <Wrapper columns={ 4 } lg={ { columns: 3 } } sm={ { columns: 2 } } xsm={ { columns: 1 } } row margin="xxl">
                    { data
                        .sort((a, b) => (parseInt(b.node[ year ], 10) - parseInt(a.node[ year ], 10)))
                        // .sort((a, b) => {
                        //     if (a.node[ year ] > b.node[ year ]) {
                        //         return -1;
                        //     }
                        //     return 1;
                        // })
                        .map((item, index) => {
                            console.log(item);
                            return (
                                <Wrapper row key={ index } padding={ { right: 'md', bottom: 'md' } } valign="center">
                                    <Flag size={ 18 } country={ item.node.id } />
                                    <Text
                                        bold
                                        margin="sm"
                                        style={ { minWidth: 40 } }
                                        align="right"
                                    >
                                        { item.node[ year ] }
                                    </Text>
                                    <Text>{ item.node.country }</Text>

                                </Wrapper>
                            );
                        }) }
                </Wrapper>

                <Table innerWidth={ 600 }>
                    <PsiCommunityMap onMouseEnter={ () => handlerOnMouseEnter() } onMouseLeave={ () => handlerOnMouseLeave() } />
                </Table>
                { ' ' }
                { current && renderTooltip() }
            </Wrapper>
        </>
    );
};

export default PsiCommunityChart;
