import React                from 'react';
import { RadialChartBlock } from '../../../../../lib/annual-report/radial-chart-block/radial-chart-block';
import { colors }           from '../../../../../lib/styles/color';

const data1 = [
    { name: 'Tuition fees', value: 96 },
    { name: 'Strategic development Fund Income', value: 4, color: colors.red.hex() }
];
const data2 = [
    { name: 'Staff expense', value: 79 },
    { name: 'Educational Programme', value: 11, color: colors.red.hex() },
    { name: 'Operational cost', value: 7, color: colors.yellow.darken(0.4).hex() },
    { name: 'Equipment & software', value: 2, color: colors.yellow.hex() },
    { name: 'Other', value: 2, color: colors.gray.darken(0.2).hex() }
];

const Chart = () => (
    <>
        <RadialChartBlock data={ data1 } title="Share of total income Per Forecast 3, %" />
        <RadialChartBlock data={ data2 } title="Share of total income Per Forecast 3, %" />
    </>
);

export default Chart;
