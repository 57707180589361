import React from 'react';
// import { colors } from '../../styles/color';

// export const getTheme = (theme?): React.CSSProperties => {
//     switch (theme) {
//         case 'green1': return { backgroundColor: colors.green.darken(0.5).hex(), color: 'white' };
//         case 'green2': return { backgroundColor: colors.green.hex(), color: 'white' };
//         case 'red1': return { backgroundColor: colors.red.lighten(0.4).hex(), color: 'red' };
//         case 'red2': return { backgroundColor: colors.red.hex(), color: 'white' };
//         case 'red3': return { backgroundColor: colors.red.darken(0.4).hex(), color: 'white' };
//         default: return {};
//     }
// };
export const getTheme = (theme?): React.CSSProperties => {
    switch (theme) {
        case 'green2': return { backgroundColor: '#257453', color: 'white' };
        case 'green1': return { backgroundColor: '#32C15A', color: 'white' };
        case 'yellow': return { backgroundColor: '#F7C454', color: 'dark' };
        case 'red1': return { backgroundColor: '#E38752', color: 'white' };
        case 'red2': return { backgroundColor: '#EA5532', color: 'white' };
        case 'red3': return { backgroundColor: '#81210B', color: 'white' };
        default: return {};
    }
};
