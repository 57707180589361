import React                         from 'react';
import { ChartLegend }               from '../../components/charts/legend';
import { ChartPie }                  from '../../components/charts/radial-chart.component';
import { ChartTitle }                from '../../components/charts/title';
import { Wrapper, WrapperPropsType } from '../../components/wrapper/wrapper.component';
import { colors }                    from '../../styles/color';
import { cn }                        from '../../util/bem';

export type ItemDataType = {
  name: string;
  value: number;
  value1?: number;
  value2?: number;
  color: any;
}

type PropsType ={
  data: ItemDataType[];
  title: React.ReactNode;
  wrapper?: WrapperPropsType;
  unit?: string;
  tooltip?: boolean;
  disableLabel?: boolean;
}

const className = cn('radial-chart-block');

export const RadialChartBlock = (props: PropsType) => {
    const getColor = (index) => colors.key.lighten(0.03 * index).rotate(-5 * index).hex();

    return (
        <Wrapper
            className={ className() }
            frame="solid-grey"
            radius="md"
            margin="md"
            columns={ 2 }
            row
            padding="xxl"
            valign="center"
            xsm={ { direction: 'column', columns: 1, align: 'center', valign: 'center' } }
            { ...props.wrapper }
        >
            <Wrapper style={ { height: '100%' } }>
                <ChartTitle>{ props.title }</ChartTitle>
                <Wrapper flex={ 1 } xsm={ { display: 'none' } } />
                <Wrapper xsm={ { display: 'none' } }>
                    <ChartLegend data={ props.data } colorFunction={ (index) => getColor(index) } unit={ props.unit } />
                </Wrapper>
            </Wrapper>
            <Wrapper xsm={ { margin: 'xl' } } align="center" valign="center">
                <ChartPie
                    data={ props.data }
                    colorFunction={ (index) => getColor(index) }
                    unit={ props.unit }
                    tooltip={ props.tooltip }
                    disableLabel={ props.disableLabel }
                />
            </Wrapper>
            <Wrapper display="none" xsm={ { display: 'flex' } } style={ { flex: '1 1 auto', width: 'auto' } }>
                <ChartLegend data={ props.data } colorFunction={ (index) => getColor(index) } unit={ props.unit } />
            </Wrapper>
        </Wrapper>
    );
};
