import React                       from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Divider }                 from '../../../../../../lib/components/divider/divider';
import { Span, Text }              from '../../../../../../lib/components/text/text';
import { Title }                   from '../../../../../../lib/components/title/title';
import { Wrapper }                 from '../../../../../../lib/components/wrapper/wrapper.component';

const DefaultProps = {};

export const Chart = () => {
    const data1 = useStaticQuery(graphql`
      query ArAssesments20211Csv {
          allArAssesments20211Csv {
              nodes {
                  assessments
                  description
                  howWeAssess
                  uses
                  when
                  who
              }
          }
          allArAssesments20212Csv {
              nodes {
                  assessments
                  description
                  howWeAssess
                  uses
                  when
                  who
              }
          }
      }
  `);
    const templete = (data) => data.map((item, index) => (
        <Wrapper key={ index } margin="xxl">
            <Divider margin="sm" color="grey-2" />
            { console.log(item) }
            <Title size="h4" margin="sm">{ item.assessments }</Title>
            <Wrapper row margin="md" />
            <Wrapper row columns={ 4 } sm={ { direction: 'column', columns: 1 } }>
                <Wrapper row>
                    <Text size="md" padding={ { right: 'md' } } style={ { flex: '1 1 50%' } } preWrap>
                        <Span color="grey-6">Who:</Span>
                        <br />
                        { ' ' }
                        { item.who }
                    </Text>
                    <Text size="md" padding={ { right: 'md' } } style={ { flex: '1 1 50%' } } preWrap>
                        <Span color="grey-6">When:</Span>
                        <br />
                        { ' ' }
                        { item.when }
                    </Text>
                </Wrapper>
                <Text size="md" padding={ { right: 'md' } } sm={ { margin: 'md' } } preWrap>
                    <Span color="grey-6">Description:</Span>
                    <br />
                    { ' ' }
                    { item.description }
                </Text>
                <Text size="md" padding={ { right: 'md' } } sm={ { margin: 'md' } } preWrap>
                    <Span color="grey-6">How we assess:</Span>
                    <br />
                    { ' ' }
                    { item.howWeAssess }
                </Text>
                <Text size="md" padding={ { right: 'md' } } sm={ { margin: 'md' } } preWrap>
                    <Span color="grey-6">Uses:</Span>
                    <br />
                    { ' ' }
                    { item.uses }
                </Text>
            </Wrapper>
        </Wrapper>
    ));
    return (
        <Wrapper fullWidth>
            <Title margin="md">Internal & External Exams</Title>
            { templete(data1.allArAssesments20211Csv.nodes) }
            <Title margin="md">Non-exam based Assessments</Title>
            { templete(data1.allArAssesments20212Csv.nodes) }
        </Wrapper>
    );
};

Chart.defaultProps = DefaultProps;
