import React, { useEffect, useState } from 'react';
import { Divider }                    from '../../components/divider/divider';
import { Text }                       from '../../components/text/text';
import { Title }                      from '../../components/title/title';
import { Wrapper }                    from '../../components/wrapper/wrapper.component';
import { colors }                     from '../../styles/color';

type DataType = {
  year: string;
  sum?: number;
  values: {
    August: number;
    November: number;
    January: number;
    April: number;
    June: number;
  };
  }

export type EnrolmentChartPropsType = {
  data: DataType[];
}
const DefaultProps = {};

export const EnrolmentChart = (props: EnrolmentChartPropsType) => {
    const [ active, setActive ] = useState('');
    const newData = props.data;
    const opacity = 0.3;
    const staticData = [
        { title: 'August', color: colors.key.darken(0.4).rotate(0).hex() },
        { title: 'November', color: colors.key.hex() },
        { title: 'January', color: colors.blue.darken(0.3).hex() },
        { title: 'April', color: colors.green.darken(0.2).hex() },
        { title: 'June', color: colors.green.hex() }
    ];
    let maximum = 0;
    const calculateYearsValue = () => {
        newData.map((item, index) => {
            newData[ index ].sum = 0;
            Object.keys(item.values).map((key) => {
                newData[ index ].sum += item.values[ key ];
            });
            console.log(newData[ index ].sum);
        });
    };
    const calculateMaximum = () => {
        newData.map((item) => {
            if (item.sum > maximum) {
                maximum = item.sum;
            }
        });
    };
    // const maximum = props.data[ 0 ].August + props.data[ 0 ].November;
    // const chartKeys = [ 'August', 'November', 'January', 'April', 'June' ];
    // const chartColors = [ colors.red.hex(), colors.blue.hex(), colors.green.hex(), colors.mint.hex(), colors.violet.hex() ];
    calculateYearsValue();
    calculateMaximum();

    const renderChartItem = (item) => (
        <Wrapper row margin="md" valign="center" onMouseLeave={ () => setActive('') }>
            <Title size="h5" style={ { minWidth: '100px' } }>{ item.year }</Title>
            <Wrapper row frame="solid-grey" valign="center" radius="xs" margin="sm">
                { staticData.map((s, index) => (
                    <Wrapper
                        key={ index }
                        valign="center"
                        frame="solid-green"
                        padding="xs"
                        // onMouseEnter={ () => setActive(s.title) }
                        style={ {
                            maxWidth:        `${ (item.values[ s.title ] * 100) / maximum }%`,
                            backgroundColor: s.color,
                            opacity:         active && (active === s.title ? 1 : opacity),
                            display:         item.values[ s.title ] === 0 ? 'none' : 'flex'
                        } }
                    >
                        <Title size="h5" color="white">
                            { item.values[ s.title ] }
                        </Title>
                    </Wrapper>
                )) }
            </Wrapper>
            { /* <Title size="h5" style={ { minWidth: '100px' } }>{ item.sum }</Title> */ }
        </Wrapper>
    );

    const renderLegend = () => staticData.map((item, index) => (
        <Wrapper key={ index } margin="sm" flex="0" row style={ { width: 'auto', opacity: active && (active === item.title ? 1 : opacity), cursor: 'pointer' } } onMouseEnter={ () => setActive(item.title) }>
            <Wrapper margin="sm" style={ { width: 16, height: 16, borderRadius: 16, backgroundColor: item.color } } />
            <Text margin="md" size="sm" style={ { marginTop: '-1px' } }>{ item.title }</Text>
        </Wrapper>
    ));

    return (
        <Wrapper frame="solid-grey" padding="lg" margin="lg" fullWidth>
            <Wrapper row margin="md" onMouseLeave={ () => setActive('') }>
                { renderLegend() }
            </Wrapper>
            <Divider margin="xl" color="grey-3" />
            { newData.map((year) => renderChartItem(year)) }
        </Wrapper>
    );
};

EnrolmentChart.defaultProps = DefaultProps;
