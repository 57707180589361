import { graphql, useStaticQuery } from 'gatsby';

export const useCommunityData = () => {
    const psiCommunityQuery = useStaticQuery(graphql`
        query PsiCommunityQuery {
            allCommunityDataCsv {
                edges {
                    node {
                        country
                        id
                        _2020
                        _2021
                    }
                }
            }
        }
    `);
    return psiCommunityQuery.allCommunityDataCsv.edges;
};
