import React         from 'react';
import ReactMarkdown from 'react-markdown';

export const md = (markdown: string | React.ReactNode) => {
    if (typeof markdown !== 'string') { return markdown; }
    const renderers = { paragraph: (props: any) => <>{ props.children }</> };
    return (
        <ReactMarkdown source={ markdown } renderers={ renderers } />
    );
};
