import React                from 'react';
import { RadialChartBlock } from '../../../../../lib/annual-report/radial-chart-block/radial-chart-block';
import { colors }           from '../../../../../lib/styles/color';

const data1 = [
    { name: 'Tuition fees', value: 94 },
    { name: 'Strategic development Fund Income', value: 6, color: colors.red.hex() }
];
const data2 = [
    { name: 'Staff expense', value: 76 },
    { name: 'Educational Programme', value: 10, color: colors.violet.hex() },
    { name: 'Operational cost', value: 4, color: colors.mint.hex() },
    { name: 'COVID Mitigation', value: 4, color: colors.red.hex() },
    { name: 'Other', value: 4, color: colors.gray.darken(0.2).hex() },
    { name: 'Equipment & software', value: 2, color: colors.yellow.hex() }
];

const Chart = () => (
    <>
        <RadialChartBlock data={ data1 } tooltip disableLabel unit="%" title="Share of total Income Per Forecast 2 (Feb.2021), %" />
        <RadialChartBlock data={ data2 } tooltip disableLabel unit="%" title="Share of total Expenses Per Forecast 2 (Feb.2021), %" />
    </>
);

export default Chart;
