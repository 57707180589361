import React              from 'react';
import { colors }         from '../../styles/color';
import { Text }           from '../text/text';
import { Wrapper }        from '../wrapper/wrapper.component';
import { ChartPropsType } from './types';

type LegendPropsType = {} & ChartPropsType;
const DefaultProps = {};

export const ChartLegend = (props: LegendPropsType) => {
    const getColor = (index) => {
        if (props.data[ index ].color) {
            return props.data[ index ].color;
        }
        if (props.colorFunction) {
            return props.colorFunction(index);
        }
        if (props.colorArray) {
            return props.colorArray[ index ];
        }
        return colors.key.hex();
    };
    const renderItem = (entry, index) => (
        <Wrapper row key={ index } valign="center" style={ { paddingBottom: 16 } }>
            <Wrapper
                margin="xs"
                flex="0"
                style={ {
                    borderRadius: 16, width: 16, minWidth: 16, height: 16, background: getColor(index)
                } }
            />
            { entry.value && <Text size="sm" bold margin={ props.unit ? undefined : 'xxs' }>{ entry.value }</Text> }
            { entry.name && <Text size="sm" italic flex="0">{ `${ props.unit ? `${ props.unit }, ` : '' }${ entry.name }` }</Text> }
        </Wrapper>
    );
    return <>{ props.data.map((entry, index) => renderItem(entry, index)) }</>;
};

ChartLegend.defaultProps = DefaultProps;
