/* eslint-disable max-len */
import React           from 'react';
import { ColorLegend } from '../../../../../../lib/annual-report/survey/colorLegend';
import { getTheme }    from '../../../../../../lib/annual-report/survey/getTheme';
import { SurveyV2 }    from '../../../../../../lib/annual-report/survey/survey-v2';
import { Paragraph }   from '../../../../../../lib/components/paragraph/paragraph';
import { Text }        from '../../../../../../lib/components/text/text';
import { Title }       from '../../../../../../lib/components/title/title';
import { Wrapper }     from '../../../../../../lib/components/wrapper/wrapper.component';
import { md }          from '../../../../../../lib/util/md';

type DataChartItemType = {
    agree: number;
    disagree: number;
    agreeColor?: 'green1' | 'green2' | 'red1' | 'red2' | 'red3';
    disagreeColor?: 'green1' | 'green2' | 'red1' | 'red2' | 'red3';
    message?: string;
}
type DataCommunityType = {
    title: string;
    question: string;
    data: {
        subject: 'parent'|'primary'|'secondary'|'faculty'|'staff';
        question?: string;
        current?: DataChartItemType;
        previous?: DataChartItemType;
    }[];
    after: Record<string, string | React.ReactNode>;

}[]
const resultsType = {
    good:  { color: 'green1', text: 'Met target' },
    close: { color: 'yellow', text: 'Close to target / Good result' },
    bed:   { color: 'red2', text: 'Growth needed for target' }
};
const goalResults = (type: 'good' | 'close' | 'bed', body: string) => (
    <Wrapper>
        <Wrapper
            padding="xxs"
            radius="sm"
            margin="xs"
            style={ {
                ...getTheme(resultsType[ type ].color),
                minHeight:    '1rem',
                width:        'auto',
                paddingLeft:  '1rem',
                paddingRight: '1rem'
            } }
        >
            <Text size="xsm" uppercase>{ resultsType[ type ].text }</Text>
        </Wrapper>
        <Text preWrap margin="off">
            { md(body) }
        </Text>
    </Wrapper>
);

const StrategicPlanGoalsChart = () => {
    const personalised: DataCommunityType = [
        {
            title:    '',
            question: 'I know where I am in my learning and what I have to do to progress.',
            data:     [
                {
                    subject:  'parent',
                    current:  { agree: 73, disagree: 12, disagreeColor: 'red2' },
                    previous: { agree: 83, disagree: 5 }
                },
                {
                    subject: 'primary',
                    current: { agree: 84, disagree: 4 }
                },
                {
                    subject: 'secondary',
                    current: { agree: 77, disagree: 15 }
                },
                {
                    subject: 'faculty',
                    current: { agree: 87, disagree: 7 }
                },
                { subject: 'staff' }
            ],
            after: {
                goal: goalResults('close', ''
                    + '**Target:** 85% by 2020\n'
                    + '**Parents:** rating decreased\n'
                    + '**G3-5 Students:** close to target\n'
                    + '**Sec. Students:** close to target\n'
                    + '**Faculty:** met target'),
                actions: 'We plan to increase the transparency of our student evaluation process, so our parents are better informed about student involvement in their own evaluation.',
                example: ''

            }
        },
        {
            title:    '',
            question: 'Students are involved in the design of their own learning.',
            data:     [
                { subject: 'parent' },
                {
                    subject: 'primary',
                    current: { agree: 75, disagree: 12 }
                },
                {
                    subject: 'secondary',
                    current: { agree: 61, disagree: 34, disagreeColor: 'red1' }
                },
                {
                    subject: 'faculty',
                    current: { agree: 75, disagree: 18, disagreeColor: 'red1' }
                },
                { subject: 'staff' }
            ],
            after: {
                goal: goalResults('close', '**Target:** 75% by 2022\n'
                  + '**G3-5 Students:** met target\n'
                  + '**Sec. Students:** growth needed\n'
                  + '**Faculty:** met target\n'),
                actions: 'We plan to provide more professional development and coaching for faculty on methods to enhance students experience with personalised learning',
                example: ''

            }
        },
        {
            title:    '',
            question: 'Setting and reflecting on goals motivate me to improve.',
            data:     [
                { subject: 'parent' },
                {
                    subject: 'primary',
                    current: { agree: 77, disagree: 18, disagreeColor: 'red1' }
                },
                {
                    subject: 'secondary',
                    current: { agree: 61, disagree: 28, disagreeColor: 'red1' }
                },
                { subject: 'faculty' },
                { subject: 'staff' }
            ],
            after: {
                goal: goalResults('bed', '**Target:** 80% by 2020\n'
                  + '**G3-5 Students:** close to target\n'
                  + '**Sec. Students:** growth needed\n'),
                actions: 'We plan to update students\' goal setting process as part of our integration of the new learning management system.',
                example: ''

            }
        },
        {
            title:    '',
            question: 'Students have opportunities for flexible pacing',
            data:     [
                { subject: 'parent' },
                {
                    subject: 'primary',
                    current: { agree: 65, disagree: 25, disagreeColor: 'red1' }
                },
                {
                    subject: 'secondary',
                    current: { agree: 63, disagree: 23, disagreeColor: 'red1' }
                },
                {
                    subject: 'faculty',
                    current: { agree: 85, disagree: 9 }
                },
                { subject: 'staff' }
            ],
            after: {
                goal: goalResults('bed', '**Target:** 80% by 2021\n'
                  + '**G3-5 Students:** growth needed\n'
                  + '**Sec. Students:** growth needed\n'
                  + '**Faculty:** growth needed'),
                actions: 'We plan to provide more faculty training and coaching so that faculty better understand pedagogical approachs to incorporating flexible pacing strategies into unit design.',
                example: '"Especially for the DP program, PSI needs to provide students with more flexibility. Right now with the distance learning, DP students have a lot of flexibility to work at their own pace - not the same when we were on campus. Flexibility will decrease a lot of stress that DP students have, and therefore I think is a very important addition." \n—_Secondary Student_'

            }
        },
        {
            title:    '',
            question: 'Most of what I learn in my classes is relevant to me.',
            data:     [
                { subject: 'parent' },
                {
                    subject: 'primary',
                    current: { agree: 83, disagree: 8 }
                },
                {
                    subject: 'secondary',
                    current: { agree: 67, disagree: 26, disagreeColor: 'red1' }
                },
                { subject: 'faculty' },
                { subject: 'staff' }
            ],
            after: {
                goal: goalResults('bed', '**Target:** 80% by 2020\n'
                  + '**G3-5 Students:** met target\n'
                  + '**Sec. Students:** growth needed\n'),
                actions: 'We plan to create empathy building experiences between faculty and secondary students so that faculty might better understand what learning is relevant to secondary students.',
                example: ''

            }
        }
    ];
    const thinking: DataCommunityType = [
        {
            title:    '',
            question: 'Most teachers help me make connections across the different subject areas.',
            data:     [
                { subject: 'parent' },
                {
                    subject:  'primary',
                    current:  { agree: 96, disagree: 1, agreeColor: 'green2' },
                    previous: { agree: 94, disagree: 4 }
                },
                {
                    subject:  'secondary',
                    current:  { agree: 79, disagree: 17, disagreeColor: 'red1' },
                    previous: { agree: 82, disagree: 15 }
                },
                { subject: 'faculty' },
                { subject: 'staff' }
            ],
            after: {
                goal: goalResults('close', ''
                  + '**Target:** 90% by 2020\n'
                  + '**G3-5 Students:** close to target\n'
                  + '**Sec. Students:** close to target\n'),
                actions: 'We plan to convene a focus group of secondary students in order to better understand in what ways teachers could better help make connections between subjects.\n',
                example: ''

            }
        },
        {
            // title:    'Learners will explore ideas and solve problems with innovative approaches.',
            title:    '',
            question: 'I am provided with opportunities to generate creative ideas and solutions.',
            data:     [
                { subject: 'parent' },
                {
                    subject:  'primary',
                    current:  { agree: 88, disagree: 4 },
                    previous: { agree: 88, disagree: 2 }
                },
                {
                    subject:  'secondary',
                    current:  { agree: 87, disagree: 9 },
                    previous: { agree: 88, disagree: 6 }
                },
                { subject: 'faculty' },
                { subject: 'staff' }
            ],
            after: {
                goal: goalResults('close', ''
                  + '**Target:** 90% by 2020\n'
                  + '**G3-5 Students:** close to target\n'
                  + '**Sec. Students:** close to target\n'),
                actions: 'We plan to encourage faculty to provide opportunities for creativitiy  and provide additional professional development in this area.',
                example: ''

            }
        }
    ];
    const balanced: DataCommunityType = [
        {
            // title:    'To develop socially and emotionally balanced learners',
            title:    '',
            question: 'PSI is welcoming and friendly.',
            data:     [
                {
                    subject:  'parent',
                    current:  { agree: 100, disagree: 0, agreeColor: 'green1' },
                    previous: { agree: 90, disagree: 6 }
                },
                {
                    subject:  'primary',
                    current:  { agree: 96, disagree: 1, agreeColor: 'green1' },
                    previous: { agree: 92, disagree: 7 }
                },
                {
                    subject:  'secondary',
                    current:  { agree: 91, disagree: 4, agreeColor: 'green1' },
                    previous: { agree: 91, disagree: 3 }
                },
                {
                    subject:  'faculty',
                    current:  { agree: 90, disagree: 9 },
                    previous: { agree: 83, disagree: 17 }
                },
                {
                    subject:  'staff',
                    current:  { agree: 100, disagree: 0, agreeColor: 'green1' },
                    previous: { agree: 100, disagree: 0 }
                }
            ],
            after: {
                goal: goalResults('good', ''
                  + '**Target:** 90% by 2020\n'
                  + '**Parents:** met target\n'
                  + '**G3-5 Students:** met target\n'
                  + '**Sec. Students:** met target\n'
                  + '**Faculty:** met target\n'
                  + '**Staff:** met target\n'),
                actions: 'We plan to share these results and celebrate with the community :)',
                example: ''

            }
        },
        {
            // title:    'To develop socially and emotionally balanced learners',
            title:    '',
            question: 'PSI creates an environment where I can usually be myself around others.',
            data:     [
                { subject: 'parent' },
                { subject: 'primary' },
                {
                    subject:  'secondary',
                    current:  { agree: 80, disagree: 17, disagreeColor: 'red1' },
                    previous: { agree: 70, disagree: 19 }
                },
                { subject: 'faculty' },
                { subject: 'staff' }
            ],
            after: {
                goal: goalResults('good', ''
                  + '**Target:** 80% by 2020\n'
                  + '**Sec. Students:** met target\n'),
                actions: '',
                example: ''

            }
        },
        {
            // title:    'Learning will enable students to be well-rounded and balanced individuals through active engagement in planned curricular and co-curricular opportunities.',
            title:    '',
            question: 'PSI cares about my well-being.',
            data:     [
                { subject: 'parent' },
                {
                    subject:  'primary',
                    current:  { agree: 88, disagree: 3, agreeColor: 'green2' },
                    previous: { agree: 78, disagree: 9 }
                },
                {
                    subject:  'secondary',
                    current:  { agree: 69, disagree: 22, disagreeColor: 'red1' },
                    previous: { agree: 70, disagree: 24 }
                },
                { subject: 'faculty' },
                { subject: 'staff' }
            ],
            after: {
                goal: goalResults('close', ''
                  + '**Target:** 80% by 2020\n'
                  + '**G3-5 Students:** met target\n'
                  + '**Sec. Students:** growth needed\n'),
                actions: 'We plan to convene student focus groups in order to better understand students\' needs for well-being',
                example: '"I believe that PSI should care more about the students wellbeing because I know a lot of people including  myself that  feel a lot of pressure and stress because of the overloading amount of work we are expected to complete and in time. I also believe that students should have more freedom in terms of creativity and doing things that interests them not outside of school but in different classes." \n—_Secondary Student_'

            }
        },
        {
            // title:    'Learning will enable students to be well-rounded and balanced individuals through active engagement in planned curricular and co-curricular opportunities.',
            title:    '',
            question: 'PSI provides me with opportunities to explore various personal interests.',
            data:     [
                { subject: 'parent' },
                { subject: 'primary' },
                {
                    subject:  'secondary',
                    current:  { agree: 77, disagree: 18, disagreeColor: 'red1', agreeColor: 'red2' },
                    previous: { agree: 90, disagree: 9 }
                },
                { subject: 'faculty' },
                { subject: 'staff' }
            ],
            after: {
                goal: goalResults('close', ''
                  + '**Target:** 90% by 2021\n'
                  + '**Sec. Students:** close to target\n'),
                actions: 'We plan to meet with secondary students in order to design Exploratories which reflect personal interests\n',
                example: '"I believe that PSI should care more about the students wellbeing because I know a lot of people including  myself that  feel a lot of pressure and stress because of the overloading amount of work we are expected to complete and in time. I also believe that students should have more freedom in terms of creativity and doing things that interests them not outside of school but in different classes." \n—_Secondary Student_'

            }
        },
        {
            // title:    'Learning will require students to positively contribute to and responsibly participate in the digital world.',
            title:    '',
            question: 'I learn how to be responsible online.',
            data:     [
                { subject: 'parent' },
                {
                    subject:  'primary',
                    current:  { agree: 89, disagree: 7 },
                    previous: { agree: 90, disagree: 4 }
                },
                {
                    subject:  'secondary',
                    current:  { agree: 61, disagree: 25, disagreeColor: 'red1' },
                    previous: { agree: 69, disagree: 24 }
                },
                { subject: 'faculty' },
                { subject: 'staff' }
            ],
            after: {
                goal: goalResults('close', ''
                  + 'Target: 80% by 2021\n'
                  + '**G3-5 Students:** met target\n'
                  + '**Sec. Students:** growth needed\n'),
                actions: 'We plan to review and revise our digital citizenship lessons.',
                example: '"In trying to teach us how to be safe online or how to organise, you just kind of cram the ideas" \n-_Secondary Student_\n'

            }
        },
        {
            // title:    'Learning will require students to positively contribute to and responsibly participate in the digital world.',
            title:    '',
            question: 'PSI teaches me how to make positive, socially responsible online contributions.',
            data:     [
                { subject: 'parent' },
                {
                    subject:  'primary',
                    current:  { agree: 69, disagree: 9, disagreeColor: 'red2' },
                    previous: { agree: 88, disagree: 7 }
                },
                {
                    subject:  'secondary',
                    current:  { agree: 74, disagree: 12 },
                    previous: { agree: 79, disagree: 10 }
                },
                { subject: 'faculty' },
                { subject: 'staff' }
            ],
            after: {
                goal: goalResults('close', ''
                  + 'Target: 80% by 2021\n'
                  + '**G3-5 Students:** growth area\n'
                  + '**Sec. Students:** close to target\n'),
                actions: 'We plan to review and revise our digital citizenship lessons.',
                example: ''

            }
        },
        {
            // title:    'Learning will require students to positively contribute to and responsibly participate in the digital world.',
            title:    '',
            question: 'PSI has helped develop the skills needed to effectively utilise global information networks.',
            data:     [
                {
                    subject: 'parent',
                    current: { agree: 83, disagree: 5 }
                },
                { subject: 'primary' },
                {
                    subject: 'secondary',
                    current: { agree: 82, disagree: 10 }
                },
                { subject: 'faculty' },
                { subject: 'staff' }
            ],
            after: {
                goal: goalResults('good', ''
                  + '**Target:** 80% by 202\n'
                  + '**Parents:** met target\n'
                  + '**Sec. Students:** met target\n'),
                actions: '',
                example: ''

            }
        }

    ];
    return (
        <Wrapper margin="xxl">

            <Title margin="lg" size="h2">Personalised:</Title>
            <Text margin="md">To develop engaged and motivated learners by providing personalised learning opportunities relevant to their lives, interests and ambitions.</Text>
            <SurveyV2 data={ personalised } />

            <Title margin="lg" size="h2">Thinking:</Title>
            <Text margin="md">To develop students thinking dispositions and habits by creating a culture of thinking in our classrooms and across our school.</Text>
            <SurveyV2 data={ thinking } />

            <Title margin="lg" size="h2">Balanced:</Title>
            <Text margin="md">To develop cognitively, emotionally, socially, and physically balanced learners.</Text>
            <SurveyV2 data={ balanced } />

            <ColorLegend />
        </Wrapper>
    );
};

export default StrategicPlanGoalsChart;
