import React                       from 'react';
import { cn }                      from '../../util/bem';
import { dataFunction }            from '../../util/data-function';
import { SizeType, TextColorType } from '../../util/global-props';
import './paragraph.scss';

type PropsType = {
  children: React.ReactNode;
  className?: string;
  size?: 'sm' | 'md' | 'lg';
  align?: 'left' | 'center' | 'right';
  weight?: 'regular' | 'bold';
  color?: TextColorType;
  padding?: SizeType;
  margin?: SizeType;
  fullWidth?: boolean;
  flex?: number | string;
  style?: React.CSSProperties;
}

const className = cn('paragraph');

export const Paragraph  = (props: PropsType) => (
    <p
        className={ className({
            align:     props.align,
            color:     props.color,
            padding:   props.padding,
            flex:      props.flex,
            fullWidth: props.fullWidth
        }, null, props.className) }
        { ...dataFunction({ size: props.size, margin: props.margin, flex: props.flex }) }
    >
        { props.children }
    </p>
);
export const Caption    = (props: PropsType) => (
    <caption className={ className({
        align:     props.align,
        color:     props.color,
        padding:   props.padding,
        margin:    props.margin,
        flex:      props.flex,
        fullWidth: props.fullWidth
    }, null, props.className) }
    >
        { props.children }
    </caption>
);
export const Blockquote = (props: PropsType) => (
    <blockquote className={ className({
        align:     props.align,
        color:     props.color,
        padding:   props.padding,
        margin:    props.margin,
        flex:      props.flex,
        fullWidth: props.fullWidth
    }, null, props.className) }
    >
        { props.children }
    </blockquote>
);
