import React                         from 'react';
import { Text }                      from '../../components/text/text';
import { Wrapper, WrapperPropsType } from '../../components/wrapper/wrapper.component';

export type ColorLegendItemType = {
    color?: string;
    text: string;
    wrapper?: WrapperPropsType;
}

type PropsType = {
  colorLegend: ColorLegendItemType[];
}

export const ColorLegend = (props: PropsType) => {
    const renderColorLegend = () => props.colorLegend.map((item, index) => (
        <Wrapper
            key={ index }
            margin="md"
            flex={ 1 }
            row
            style={ { width: 'auto', flex: '1 1 20%', minWidth: '15rem' } }
            xsm={ { direction: 'column' } }
        >
            <Wrapper
                margin="sm"
                { ...item.wrapper }
                style={ {
                    minWidth:        16,
                    maxWidth:        16,
                    minHeight:       16,
                    maxHeight:       16,
                    borderRadius:    16,
                    backgroundColor: item.color,
                    ...item.wrapper ? item.wrapper.style : undefined
                } }

            />
            <Text margin="md" size="xsm" style={ { marginTop: '-1px' } } preWrap>{ item.text }</Text>
        </Wrapper>
    ));
    return (
        <Wrapper
            frame="solid-white"
            style={ {
                position:    'sticky',
                bottom:      0,
                left:        '-4.8rem',
                marginLeft:  '-4.8rem',
                paddingLeft: '3.2rem',
                width:       '100vw',
                maxWidth:    'calc(100vw + 4.8rem)',
                minWidth:    'calc(100vw + 4.8rem)',
                overflowX:   'scroll',
                boxShadow:   '0px 0px 20px rgba(0, 0, 0, 0.1)'
            } }
            // hide={ [ '', 'sm' ] }
        >
            { /* <Divider color="grey-2" /> */ }
            <Wrapper row padding="md" style={ { overflowY: 'scroll' } }>
                { renderColorLegend() }
            </Wrapper>
        </Wrapper>
    );
};
