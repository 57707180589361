import React                from 'react';
import { RadialChartBlock } from '../../../../../../lib/annual-report/radial-chart-block/radial-chart-block';
import { ChartLegend }      from '../../../../../../lib/components/charts/legend';
import { ChartPie }         from '../../../../../../lib/components/charts/radial-chart.component';
import { Title }            from '../../../../../../lib/components/title/title';
import { Wrapper }          from '../../../../../../lib/components/wrapper/wrapper.component';
import { colors }           from '../../../../../../lib/styles/color';
import { md }               from '../../../../../../lib/util/md';

const content = [
    {
        title: 'Teaching Staff Nationalities _(13)_',
        data:  [
            { name: 'US', value: 33, color: colors.red.hex() },
            { name: 'Ukraine', value: 13, color: colors.yellow.hex() },
            { name: 'UK', value: 10, color: colors.key.hex() },
            { name: 'New Zealand', value: 4, color: colors.blue.hex() },
            { name: 'Canada', value: 3, color: colors.mint.hex() },
            { name: 'Other', value: 8 }
        ]
    },
    {
        title: 'Teaching Staff Qualifications',
        data:  [
            { name: 'PHD', value: 3, color: colors.red.hex() },
            { name: 'MA', value: 45 },
            { name: 'Supplementary degree', value: 5 },
            { name: 'BA', value: 15 }
        ]
    },
    {
        title: 'Staffing Area Break Down',
        data:  [
            { name: 'Teachers', value: 68, color: colors.key.hex() },
            { name: 'TAs', value: 34, color: colors.blue.hex() },
            { name: 'Administration', value: 44, color: colors.violet.hex() },
            { name: 'Castodian/Maintenance/Security/Drivers ', value: 20, color: colors.green.hex() }
        ]
    },
    {
        title: 'Gender',
        data:  [
            { name: 'Female. Faculty ', value: 44, color: colors.red.hex() },
            { name: 'Female. Other ', value: 66, color: colors.red.darken(0.3).hex() },
            { name: 'Male. Faculty', value: 24, color: colors.key.hex() },
            { name: 'Male. Other', value: 32, color: colors.key.darken(0.3).hex() }
        ]
    }
];

const Chart1 = () => {
    const getColor = (index) => colors.key.lighten(0.03 * index).rotate(-5 * index).hex();

    return content.map((chart: typeof content[0], index) => (
        <RadialChartBlock
            key={ index }
            data={ chart.data }
            title={ md(chart.title) }
        />
    ));
};

export default Chart1;
