// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-articles-annual-report-2020-01-intro-01-school-directors-report-index-mdx": () => import("./../../../src/articles/annual-report/2020/01-intro/01-school-directors-report/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2020-01-intro-01-school-directors-report-index-mdx" */),
  "component---src-articles-annual-report-2020-01-intro-02-a-place-where-we-belong-index-mdx": () => import("./../../../src/articles/annual-report/2020/01-intro/02-a-place-where-we-belong/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2020-01-intro-02-a-place-where-we-belong-index-mdx" */),
  "component---src-articles-annual-report-2020-01-intro-03-psi-guiding-statements-index-mdx": () => import("./../../../src/articles/annual-report/2020/01-intro/03-psi-guiding-statements/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2020-01-intro-03-psi-guiding-statements-index-mdx" */),
  "component---src-articles-annual-report-2020-01-intro-04-an-in-world-school-index-mdx": () => import("./../../../src/articles/annual-report/2020/01-intro/04-an-in-world-school/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2020-01-intro-04-an-in-world-school-index-mdx" */),
  "component---src-articles-annual-report-2020-01-intro-05-psi-community-index-mdx": () => import("./../../../src/articles/annual-report/2020/01-intro/05-psi-community/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2020-01-intro-05-psi-community-index-mdx" */),
  "component---src-articles-annual-report-2020-01-intro-06-faculty-academic-qualifications-index-mdx": () => import("./../../../src/articles/annual-report/2020/01-intro/06-faculty-academic-qualifications/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2020-01-intro-06-faculty-academic-qualifications-index-mdx" */),
  "component---src-articles-annual-report-2020-02-review-01-board-chairpersons-annual-story-index-mdx": () => import("./../../../src/articles/annual-report/2020/02-review/01-board-chairpersons-annual-story/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2020-02-review-01-board-chairpersons-annual-story-index-mdx" */),
  "component---src-articles-annual-report-2020-02-review-02-board-of-ddirectors-index-mdx": () => import("./../../../src/articles/annual-report/2020/02-review/02-board-of-ddirectors/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2020-02-review-02-board-of-ddirectors-index-mdx" */),
  "component---src-articles-annual-report-2020-02-review-02-board-of-ddirectors-photos-tsx": () => import("./../../../src/articles/annual-report/2020/02-review/02-board-of-ddirectors/photos.tsx" /* webpackChunkName: "component---src-articles-annual-report-2020-02-review-02-board-of-ddirectors-photos-tsx" */),
  "component---src-articles-annual-report-2020-02-review-03-primary-school-principals-report-index-mdx": () => import("./../../../src/articles/annual-report/2020/02-review/03-primary-school-principals-report/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2020-02-review-03-primary-school-principals-report-index-mdx" */),
  "component---src-articles-annual-report-2020-02-review-04-secondary-school-principals-report-index-mdx": () => import("./../../../src/articles/annual-report/2020/02-review/04-secondary-school-principals-report/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2020-02-review-04-secondary-school-principals-report-index-mdx" */),
  "component---src-articles-annual-report-2020-02-review-05-director-of-learnings-report-index-mdx": () => import("./../../../src/articles/annual-report/2020/02-review/05-director-of-learnings-report/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2020-02-review-05-director-of-learnings-report-index-mdx" */),
  "component---src-articles-annual-report-2020-02-review-06-distance-learning-chart-tsx": () => import("./../../../src/articles/annual-report/2020/02-review/06-distance-learning/chart.tsx" /* webpackChunkName: "component---src-articles-annual-report-2020-02-review-06-distance-learning-chart-tsx" */),
  "component---src-articles-annual-report-2020-02-review-06-distance-learning-index-mdx": () => import("./../../../src/articles/annual-report/2020/02-review/06-distance-learning/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2020-02-review-06-distance-learning-index-mdx" */),
  "component---src-articles-annual-report-2020-02-review-07-human-resources-annual-report-index-mdx": () => import("./../../../src/articles/annual-report/2020/02-review/07-human-resources-annual-report/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2020-02-review-07-human-resources-annual-report-index-mdx" */),
  "component---src-articles-annual-report-2020-02-review-08-pta-annual-report-index-mdx": () => import("./../../../src/articles/annual-report/2020/02-review/08-pta-annual-report/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2020-02-review-08-pta-annual-report-index-mdx" */),
  "component---src-articles-annual-report-2020-02-review-09-enrolment-annual-story-chart-tsx": () => import("./../../../src/articles/annual-report/2020/02-review/09-enrolment-annual-story/chart.tsx" /* webpackChunkName: "component---src-articles-annual-report-2020-02-review-09-enrolment-annual-story-chart-tsx" */),
  "component---src-articles-annual-report-2020-02-review-09-enrolment-annual-story-index-mdx": () => import("./../../../src/articles/annual-report/2020/02-review/09-enrolment-annual-story/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2020-02-review-09-enrolment-annual-story-index-mdx" */),
  "component---src-articles-annual-report-2020-02-review-10-financial-year-annual-story-chart-1-tsx": () => import("./../../../src/articles/annual-report/2020/02-review/10-financial-year-annual-story/chart-1.tsx" /* webpackChunkName: "component---src-articles-annual-report-2020-02-review-10-financial-year-annual-story-chart-1-tsx" */),
  "component---src-articles-annual-report-2020-02-review-10-financial-year-annual-story-chart-2-tsx": () => import("./../../../src/articles/annual-report/2020/02-review/10-financial-year-annual-story/chart-2.tsx" /* webpackChunkName: "component---src-articles-annual-report-2020-02-review-10-financial-year-annual-story-chart-2-tsx" */),
  "component---src-articles-annual-report-2020-02-review-10-financial-year-annual-story-index-mdx": () => import("./../../../src/articles/annual-report/2020/02-review/10-financial-year-annual-story/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2020-02-review-10-financial-year-annual-story-index-mdx" */),
  "component---src-articles-annual-report-2020-02-review-11-library-index-mdx": () => import("./../../../src/articles/annual-report/2020/02-review/11-library/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2020-02-review-11-library-index-mdx" */),
  "component---src-articles-annual-report-2020-02-review-12-psi-alumni-association-index-mdx": () => import("./../../../src/articles/annual-report/2020/02-review/12-psi-alumni-Association/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2020-02-review-12-psi-alumni-association-index-mdx" */),
  "component---src-articles-annual-report-2020-03-planing-01-strategic-plan-goals-index-mdx": () => import("./../../../src/articles/annual-report/2020/03-planing/01-strategic-plan-goals/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2020-03-planing-01-strategic-plan-goals-index-mdx" */),
  "component---src-articles-annual-report-2020-03-planing-02-leading-school-benchmarks-index-mdx": () => import("./../../../src/articles/annual-report/2020/03-planing/02-leading-school-benchmarks/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2020-03-planing-02-leading-school-benchmarks-index-mdx" */),
  "component---src-articles-annual-report-2020-03-planing-03-climate-survey-chart-tsx": () => import("./../../../src/articles/annual-report/2020/03-planing/03-climate-survey/chart.tsx" /* webpackChunkName: "component---src-articles-annual-report-2020-03-planing-03-climate-survey-chart-tsx" */),
  "component---src-articles-annual-report-2020-03-planing-03-climate-survey-index-mdx": () => import("./../../../src/articles/annual-report/2020/03-planing/03-climate-survey/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2020-03-planing-03-climate-survey-index-mdx" */),
  "component---src-articles-annual-report-2020-04-achievements-01-assessment-index-mdx": () => import("./../../../src/articles/annual-report/2020/04-achievements/01-assessment/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2020-04-achievements-01-assessment-index-mdx" */),
  "component---src-articles-annual-report-2020-04-achievements-02-cas-report-index-mdx": () => import("./../../../src/articles/annual-report/2020/04-achievements/02-cas-report/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2020-04-achievements-02-cas-report-index-mdx" */),
  "component---src-articles-annual-report-2020-04-achievements-03-opportunities-and-experiences-index-mdx": () => import("./../../../src/articles/annual-report/2020/04-achievements/03-opportunities-and-experiences/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2020-04-achievements-03-opportunities-and-experiences-index-mdx" */),
  "component---src-articles-annual-report-2020-04-achievements-04-psi-taking-action-index-mdx": () => import("./../../../src/articles/annual-report/2020/04-achievements/04-psi-taking-action/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2020-04-achievements-04-psi-taking-action-index-mdx" */),
  "component---src-articles-annual-report-2020-04-achievements-05-ukrainian-externum-programme-story-index-mdx": () => import("./../../../src/articles/annual-report/2020/04-achievements/05-ukrainian-externum-programme-story/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2020-04-achievements-05-ukrainian-externum-programme-story-index-mdx" */),
  "component---src-articles-annual-report-2020-04-achievements-06-the-graduating-class-2020-index-mdx": () => import("./../../../src/articles/annual-report/2020/04-achievements/06-the-graduating-class-2020/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2020-04-achievements-06-the-graduating-class-2020-index-mdx" */),
  "component---src-articles-annual-report-2020-04-achievements-07-our-graduates-index-mdx": () => import("./../../../src/articles/annual-report/2020/04-achievements/07-our-graduates/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2020-04-achievements-07-our-graduates-index-mdx" */),
  "component---src-articles-annual-report-2020-04-achievements-07-our-graduates-photos-tsx": () => import("./../../../src/articles/annual-report/2020/04-achievements/07-our-graduates/photos.tsx" /* webpackChunkName: "component---src-articles-annual-report-2020-04-achievements-07-our-graduates-photos-tsx" */),
  "component---src-articles-annual-report-2020-04-achievements-08-university-offers-to-pso-students-index-mdx": () => import("./../../../src/articles/annual-report/2020/04-achievements/08-university-offers-to-pso-students/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2020-04-achievements-08-university-offers-to-pso-students-index-mdx" */),
  "component---src-articles-annual-report-2021-01-intro-01-school-directors-report-index-mdx": () => import("./../../../src/articles/annual-report/2021/01-intro/01-school-directors-report/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2021-01-intro-01-school-directors-report-index-mdx" */),
  "component---src-articles-annual-report-2021-01-intro-02-a-place-where-we-belong-index-mdx": () => import("./../../../src/articles/annual-report/2021/01-intro/02-a-place-where-we-belong/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2021-01-intro-02-a-place-where-we-belong-index-mdx" */),
  "component---src-articles-annual-report-2021-01-intro-03-psi-guiding-statements-index-mdx": () => import("./../../../src/articles/annual-report/2021/01-intro/03-psi-guiding-statements/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2021-01-intro-03-psi-guiding-statements-index-mdx" */),
  "component---src-articles-annual-report-2021-01-intro-04-an-in-world-school-index-mdx": () => import("./../../../src/articles/annual-report/2021/01-intro/04-an-in-world-school/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2021-01-intro-04-an-in-world-school-index-mdx" */),
  "component---src-articles-annual-report-2021-01-intro-05-psi-community-index-mdx": () => import("./../../../src/articles/annual-report/2021/01-intro/05-psi-community/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2021-01-intro-05-psi-community-index-mdx" */),
  "component---src-articles-annual-report-2021-01-intro-06-faculty-academic-qualifications-index-mdx": () => import("./../../../src/articles/annual-report/2021/01-intro/06-faculty-academic-qualifications/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2021-01-intro-06-faculty-academic-qualifications-index-mdx" */),
  "component---src-articles-annual-report-2021-02-review-01-board-chairpersons-annual-story-index-mdx": () => import("./../../../src/articles/annual-report/2021/02-review/01-board-chairpersons-annual-story/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2021-02-review-01-board-chairpersons-annual-story-index-mdx" */),
  "component---src-articles-annual-report-2021-02-review-02-board-of-governors-index-mdx": () => import("./../../../src/articles/annual-report/2021/02-review/02-board-of-governors/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2021-02-review-02-board-of-governors-index-mdx" */),
  "component---src-articles-annual-report-2021-02-review-02-board-of-governors-photos-tsx": () => import("./../../../src/articles/annual-report/2021/02-review/02-board-of-governors/photos.tsx" /* webpackChunkName: "component---src-articles-annual-report-2021-02-review-02-board-of-governors-photos-tsx" */),
  "component---src-articles-annual-report-2021-02-review-03-primary-school-principals-report-index-mdx": () => import("./../../../src/articles/annual-report/2021/02-review/03-primary-school-principals-report/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2021-02-review-03-primary-school-principals-report-index-mdx" */),
  "component---src-articles-annual-report-2021-02-review-04-secondary-school-principals-report-index-mdx": () => import("./../../../src/articles/annual-report/2021/02-review/04-secondary-school-principals-report/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2021-02-review-04-secondary-school-principals-report-index-mdx" */),
  "component---src-articles-annual-report-2021-02-review-05-director-of-learnings-report-index-mdx": () => import("./../../../src/articles/annual-report/2021/02-review/05-director-of-learnings-report/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2021-02-review-05-director-of-learnings-report-index-mdx" */),
  "component---src-articles-annual-report-2021-02-review-06-distance-learning-chart-tsx": () => import("./../../../src/articles/annual-report/2021/02-review/06-distance-learning/chart.tsx" /* webpackChunkName: "component---src-articles-annual-report-2021-02-review-06-distance-learning-chart-tsx" */),
  "component---src-articles-annual-report-2021-02-review-06-distance-learning-index-mdx": () => import("./../../../src/articles/annual-report/2021/02-review/06-distance-learning/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2021-02-review-06-distance-learning-index-mdx" */),
  "component---src-articles-annual-report-2021-02-review-07-human-resources-annual-report-index-mdx": () => import("./../../../src/articles/annual-report/2021/02-review/07-human-resources-annual-report/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2021-02-review-07-human-resources-annual-report-index-mdx" */),
  "component---src-articles-annual-report-2021-02-review-08-pta-annual-report-index-mdx": () => import("./../../../src/articles/annual-report/2021/02-review/08-pta-annual-report/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2021-02-review-08-pta-annual-report-index-mdx" */),
  "component---src-articles-annual-report-2021-02-review-09-enrolment-annual-story-chart-tsx": () => import("./../../../src/articles/annual-report/2021/02-review/09-enrolment-annual-story/chart.tsx" /* webpackChunkName: "component---src-articles-annual-report-2021-02-review-09-enrolment-annual-story-chart-tsx" */),
  "component---src-articles-annual-report-2021-02-review-09-enrolment-annual-story-index-mdx": () => import("./../../../src/articles/annual-report/2021/02-review/09-enrolment-annual-story/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2021-02-review-09-enrolment-annual-story-index-mdx" */),
  "component---src-articles-annual-report-2021-02-review-10-financial-year-annual-story-chart-1-tsx": () => import("./../../../src/articles/annual-report/2021/02-review/10-financial-year-annual-story/chart-1.tsx" /* webpackChunkName: "component---src-articles-annual-report-2021-02-review-10-financial-year-annual-story-chart-1-tsx" */),
  "component---src-articles-annual-report-2021-02-review-10-financial-year-annual-story-chart-2-tsx": () => import("./../../../src/articles/annual-report/2021/02-review/10-financial-year-annual-story/chart-2.tsx" /* webpackChunkName: "component---src-articles-annual-report-2021-02-review-10-financial-year-annual-story-chart-2-tsx" */),
  "component---src-articles-annual-report-2021-02-review-10-financial-year-annual-story-index-mdx": () => import("./../../../src/articles/annual-report/2021/02-review/10-financial-year-annual-story/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2021-02-review-10-financial-year-annual-story-index-mdx" */),
  "component---src-articles-annual-report-2021-02-review-11-library-index-mdx": () => import("./../../../src/articles/annual-report/2021/02-review/11-library/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2021-02-review-11-library-index-mdx" */),
  "component---src-articles-annual-report-2021-02-review-12-green-committee-index-mdx": () => import("./../../../src/articles/annual-report/2021/02-review/12-green-committee/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2021-02-review-12-green-committee-index-mdx" */),
  "component---src-articles-annual-report-2021-02-review-13-it-report-index-mdx": () => import("./../../../src/articles/annual-report/2021/02-review/13-it-report/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2021-02-review-13-it-report-index-mdx" */),
  "component---src-articles-annual-report-2021-02-review-14-psi-alumni-association-index-mdx": () => import("./../../../src/articles/annual-report/2021/02-review/14-psi-alumni-Association/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2021-02-review-14-psi-alumni-association-index-mdx" */),
  "component---src-articles-annual-report-2021-03-planing-01-strategic-plan-goals-index-mdx": () => import("./../../../src/articles/annual-report/2021/03-planing/01-strategic-plan-goals/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2021-03-planing-01-strategic-plan-goals-index-mdx" */),
  "component---src-articles-annual-report-2021-03-planing-02-leading-school-benchmarks-index-mdx": () => import("./../../../src/articles/annual-report/2021/03-planing/02-leading-school-benchmarks/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2021-03-planing-02-leading-school-benchmarks-index-mdx" */),
  "component---src-articles-annual-report-2021-03-planing-03-climate-survey-chart-tsx": () => import("./../../../src/articles/annual-report/2021/03-planing/03-climate-survey/chart.tsx" /* webpackChunkName: "component---src-articles-annual-report-2021-03-planing-03-climate-survey-chart-tsx" */),
  "component---src-articles-annual-report-2021-03-planing-03-climate-survey-index-mdx": () => import("./../../../src/articles/annual-report/2021/03-planing/03-climate-survey/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2021-03-planing-03-climate-survey-index-mdx" */),
  "component---src-articles-annual-report-2021-04-achievements-01-assessment-index-mdx": () => import("./../../../src/articles/annual-report/2021/04-achievements/01-assessment/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2021-04-achievements-01-assessment-index-mdx" */),
  "component---src-articles-annual-report-2021-04-achievements-02-cas-report-index-mdx": () => import("./../../../src/articles/annual-report/2021/04-achievements/02-cas-report/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2021-04-achievements-02-cas-report-index-mdx" */),
  "component---src-articles-annual-report-2021-04-achievements-03-opportunities-and-experiences-index-mdx": () => import("./../../../src/articles/annual-report/2021/04-achievements/03-opportunities-and-experiences/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2021-04-achievements-03-opportunities-and-experiences-index-mdx" */),
  "component---src-articles-annual-report-2021-04-achievements-04-psi-taking-action-index-mdx": () => import("./../../../src/articles/annual-report/2021/04-achievements/04-psi-taking-action/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2021-04-achievements-04-psi-taking-action-index-mdx" */),
  "component---src-articles-annual-report-2021-04-achievements-05-ukrainian-externum-programme-story-index-mdx": () => import("./../../../src/articles/annual-report/2021/04-achievements/05-ukrainian-externum-programme-story/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2021-04-achievements-05-ukrainian-externum-programme-story-index-mdx" */),
  "component---src-articles-annual-report-2021-04-achievements-06-the-graduating-class-index-mdx": () => import("./../../../src/articles/annual-report/2021/04-achievements/06-the-graduating-class/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2021-04-achievements-06-the-graduating-class-index-mdx" */),
  "component---src-articles-annual-report-2021-04-achievements-07-our-graduates-index-mdx": () => import("./../../../src/articles/annual-report/2021/04-achievements/07-our-graduates/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2021-04-achievements-07-our-graduates-index-mdx" */),
  "component---src-articles-annual-report-2021-04-achievements-07-our-graduates-photos-tsx": () => import("./../../../src/articles/annual-report/2021/04-achievements/07-our-graduates/photos.tsx" /* webpackChunkName: "component---src-articles-annual-report-2021-04-achievements-07-our-graduates-photos-tsx" */),
  "component---src-articles-annual-report-2021-04-achievements-08-university-offers-index-mdx": () => import("./../../../src/articles/annual-report/2021/04-achievements/08-university-offers/index.mdx" /* webpackChunkName: "component---src-articles-annual-report-2021-04-achievements-08-university-offers-index-mdx" */),
  "component---src-articles-handbook-parent-student-2021-general-section-1-index-mdx": () => import("./../../../src/articles/handbook/parent-student/2021/general/section-1/index.mdx" /* webpackChunkName: "component---src-articles-handbook-parent-student-2021-general-section-1-index-mdx" */),
  "component---src-articles-handbook-parent-student-2021-general-section-2-index-mdx": () => import("./../../../src/articles/handbook/parent-student/2021/general/section-2/index.mdx" /* webpackChunkName: "component---src-articles-handbook-parent-student-2021-general-section-2-index-mdx" */),
  "component---src-articles-handbook-parent-student-2021-general-section-3-index-mdx": () => import("./../../../src/articles/handbook/parent-student/2021/general/section-3/index.mdx" /* webpackChunkName: "component---src-articles-handbook-parent-student-2021-general-section-3-index-mdx" */),
  "component---src-articles-handbook-parent-student-2021-general-section-4-index-mdx": () => import("./../../../src/articles/handbook/parent-student/2021/general/section-4/index.mdx" /* webpackChunkName: "component---src-articles-handbook-parent-student-2021-general-section-4-index-mdx" */),
  "component---src-articles-handbook-parent-student-2021-general-section-5-index-mdx": () => import("./../../../src/articles/handbook/parent-student/2021/general/section-5/index.mdx" /* webpackChunkName: "component---src-articles-handbook-parent-student-2021-general-section-5-index-mdx" */),
  "component---src-articles-handbook-parent-student-2021-general-section-6-index-mdx": () => import("./../../../src/articles/handbook/parent-student/2021/general/section-6/index.mdx" /* webpackChunkName: "component---src-articles-handbook-parent-student-2021-general-section-6-index-mdx" */),
  "component---src-articles-handbook-parent-student-2021-general-section-7-index-mdx": () => import("./../../../src/articles/handbook/parent-student/2021/general/section-7/index.mdx" /* webpackChunkName: "component---src-articles-handbook-parent-student-2021-general-section-7-index-mdx" */),
  "component---src-articles-handbook-parent-student-2021-general-section-8-index-mdx": () => import("./../../../src/articles/handbook/parent-student/2021/general/section-8/index.mdx" /* webpackChunkName: "component---src-articles-handbook-parent-student-2021-general-section-8-index-mdx" */),
  "component---src-articles-handbook-parent-student-2021-primary-section-1-index-mdx": () => import("./../../../src/articles/handbook/parent-student/2021/primary/section-1/index.mdx" /* webpackChunkName: "component---src-articles-handbook-parent-student-2021-primary-section-1-index-mdx" */),
  "component---src-articles-handbook-parent-student-2021-primary-section-2-index-mdx": () => import("./../../../src/articles/handbook/parent-student/2021/primary/section-2/index.mdx" /* webpackChunkName: "component---src-articles-handbook-parent-student-2021-primary-section-2-index-mdx" */),
  "component---src-articles-handbook-parent-student-2021-primary-section-3-index-mdx": () => import("./../../../src/articles/handbook/parent-student/2021/primary/section-3/index.mdx" /* webpackChunkName: "component---src-articles-handbook-parent-student-2021-primary-section-3-index-mdx" */),
  "component---src-articles-handbook-parent-student-2021-secondary-section-1-index-mdx": () => import("./../../../src/articles/handbook/parent-student/2021/secondary/section-1/index.mdx" /* webpackChunkName: "component---src-articles-handbook-parent-student-2021-secondary-section-1-index-mdx" */),
  "component---src-articles-handbook-parent-student-2021-secondary-section-2-index-mdx": () => import("./../../../src/articles/handbook/parent-student/2021/secondary/section-2/index.mdx" /* webpackChunkName: "component---src-articles-handbook-parent-student-2021-secondary-section-2-index-mdx" */),
  "component---src-articles-handbook-primary-2021-section-1-index-mdx": () => import("./../../../src/articles/handbook/primary/2021/section1/index.mdx" /* webpackChunkName: "component---src-articles-handbook-primary-2021-section-1-index-mdx" */),
  "component---src-articles-handbook-primary-2021-section-10-index-mdx": () => import("./../../../src/articles/handbook/primary/2021/section10/index.mdx" /* webpackChunkName: "component---src-articles-handbook-primary-2021-section-10-index-mdx" */),
  "component---src-articles-handbook-primary-2021-section-2-index-mdx": () => import("./../../../src/articles/handbook/primary/2021/section2/index.mdx" /* webpackChunkName: "component---src-articles-handbook-primary-2021-section-2-index-mdx" */),
  "component---src-articles-handbook-primary-2021-section-3-index-mdx": () => import("./../../../src/articles/handbook/primary/2021/section3/index.mdx" /* webpackChunkName: "component---src-articles-handbook-primary-2021-section-3-index-mdx" */),
  "component---src-articles-handbook-primary-2021-section-4-index-mdx": () => import("./../../../src/articles/handbook/primary/2021/section4/index.mdx" /* webpackChunkName: "component---src-articles-handbook-primary-2021-section-4-index-mdx" */),
  "component---src-articles-handbook-primary-2021-section-5-index-mdx": () => import("./../../../src/articles/handbook/primary/2021/section5/index.mdx" /* webpackChunkName: "component---src-articles-handbook-primary-2021-section-5-index-mdx" */),
  "component---src-articles-handbook-primary-2021-section-6-index-mdx": () => import("./../../../src/articles/handbook/primary/2021/section6/index.mdx" /* webpackChunkName: "component---src-articles-handbook-primary-2021-section-6-index-mdx" */),
  "component---src-articles-handbook-primary-2021-section-7-index-mdx": () => import("./../../../src/articles/handbook/primary/2021/section7/index.mdx" /* webpackChunkName: "component---src-articles-handbook-primary-2021-section-7-index-mdx" */),
  "component---src-articles-handbook-primary-2021-section-8-index-mdx": () => import("./../../../src/articles/handbook/primary/2021/section8/index.mdx" /* webpackChunkName: "component---src-articles-handbook-primary-2021-section-8-index-mdx" */),
  "component---src-articles-handbook-primary-2021-section-9-index-mdx": () => import("./../../../src/articles/handbook/primary/2021/section9/index.mdx" /* webpackChunkName: "component---src-articles-handbook-primary-2021-section-9-index-mdx" */),
  "component---src-articles-handbook-secondary-2021-section-1-index-mdx": () => import("./../../../src/articles/handbook/secondary/2021/section-1/index.mdx" /* webpackChunkName: "component---src-articles-handbook-secondary-2021-section-1-index-mdx" */),
  "component---src-articles-handbook-secondary-2021-section-2-index-mdx": () => import("./../../../src/articles/handbook/secondary/2021/section-2/index.mdx" /* webpackChunkName: "component---src-articles-handbook-secondary-2021-section-2-index-mdx" */),
  "component---src-articles-handbook-secondary-2021-section-3-index-mdx": () => import("./../../../src/articles/handbook/secondary/2021/section-3/index.mdx" /* webpackChunkName: "component---src-articles-handbook-secondary-2021-section-3-index-mdx" */),
  "component---src-articles-handbook-secondary-2021-section-4-index-mdx": () => import("./../../../src/articles/handbook/secondary/2021/section-4/index.mdx" /* webpackChunkName: "component---src-articles-handbook-secondary-2021-section-4-index-mdx" */),
  "component---src-articles-handbook-secondary-2021-section-5-index-mdx": () => import("./../../../src/articles/handbook/secondary/2021/section-5/index.mdx" /* webpackChunkName: "component---src-articles-handbook-secondary-2021-section-5-index-mdx" */),
  "component---src-articles-handbook-secondary-2021-section-6-index-mdx": () => import("./../../../src/articles/handbook/secondary/2021/section-6/index.mdx" /* webpackChunkName: "component---src-articles-handbook-secondary-2021-section-6-index-mdx" */),
  "component---src-articles-handbook-secondary-2021-section-7-index-mdx": () => import("./../../../src/articles/handbook/secondary/2021/section-7/index.mdx" /* webpackChunkName: "component---src-articles-handbook-secondary-2021-section-7-index-mdx" */),
  "component---src-articles-ink-stains-2020-primary-01-my-unicorn-index-mdx": () => import("./../../../src/articles/ink-stains/2020/primary/01-my-unicorn/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2020-primary-01-my-unicorn-index-mdx" */),
  "component---src-articles-ink-stains-2020-primary-02-sports-index-mdx": () => import("./../../../src/articles/ink-stains/2020/primary/02-sports/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2020-primary-02-sports-index-mdx" */),
  "component---src-articles-ink-stains-2020-primary-03-every-week-my-passion-changes-index-mdx": () => import("./../../../src/articles/ink-stains/2020/primary/03-every-week-my-passion-changes/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2020-primary-03-every-week-my-passion-changes-index-mdx" */),
  "component---src-articles-ink-stains-2020-primary-04-brilliant-books-index-mdx": () => import("./../../../src/articles/ink-stains/2020/primary/04-brilliant-books/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2020-primary-04-brilliant-books-index-mdx" */),
  "component---src-articles-ink-stains-2020-primary-05-the-3-passions-index-mdx": () => import("./../../../src/articles/ink-stains/2020/primary/05-the-3-passions/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2020-primary-05-the-3-passions-index-mdx" */),
  "component---src-articles-ink-stains-2020-primary-06-the-day-coolboy-1234-went-to-the-moon-index-mdx": () => import("./../../../src/articles/ink-stains/2020/primary/06-the-day-coolboy-1234-went-to-the-moon/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2020-primary-06-the-day-coolboy-1234-went-to-the-moon-index-mdx" */),
  "component---src-articles-ink-stains-2020-primary-07-aurora-index-mdx": () => import("./../../../src/articles/ink-stains/2020/primary/07-aurora/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2020-primary-07-aurora-index-mdx" */),
  "component---src-articles-ink-stains-2020-primary-08-my-swimming-story-index-mdx": () => import("./../../../src/articles/ink-stains/2020/primary/08-my-swimming-story/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2020-primary-08-my-swimming-story-index-mdx" */),
  "component---src-articles-ink-stains-2020-primary-09-art-index-mdx": () => import("./../../../src/articles/ink-stains/2020/primary/09-art/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2020-primary-09-art-index-mdx" */),
  "component---src-articles-ink-stains-2020-primary-10-ballroom-dancing-index-mdx": () => import("./../../../src/articles/ink-stains/2020/primary/10-ballroom-dancing/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2020-primary-10-ballroom-dancing-index-mdx" */),
  "component---src-articles-ink-stains-2020-secondary-submissions-01-life-s-gust-index-mdx": () => import("./../../../src/articles/ink-stains/2020/secondary/submissions/01-life-s-gust/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2020-secondary-submissions-01-life-s-gust-index-mdx" */),
  "component---src-articles-ink-stains-2020-secondary-submissions-02-animals-are-different-index-mdx": () => import("./../../../src/articles/ink-stains/2020/secondary/submissions/02-animals-are-different/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2020-secondary-submissions-02-animals-are-different-index-mdx" */),
  "component---src-articles-ink-stains-2020-secondary-submissions-03-remember-index-mdx": () => import("./../../../src/articles/ink-stains/2020/secondary/submissions/03-remember/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2020-secondary-submissions-03-remember-index-mdx" */),
  "component---src-articles-ink-stains-2020-secondary-submissions-04-last-day-of-school-index-mdx": () => import("./../../../src/articles/ink-stains/2020/secondary/submissions/04-last-day-of-school/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2020-secondary-submissions-04-last-day-of-school-index-mdx" */),
  "component---src-articles-ink-stains-2020-secondary-submissions-05-on-the-mountain-index-mdx": () => import("./../../../src/articles/ink-stains/2020/secondary/submissions/05-on-the-mountain/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2020-secondary-submissions-05-on-the-mountain-index-mdx" */),
  "component---src-articles-ink-stains-2020-secondary-submissions-06-an-unexpected-mindset-index-mdx": () => import("./../../../src/articles/ink-stains/2020/secondary/submissions/06-an-unexpected-mindset/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2020-secondary-submissions-06-an-unexpected-mindset-index-mdx" */),
  "component---src-articles-ink-stains-2020-secondary-submissions-07-the-samurai-index-mdx": () => import("./../../../src/articles/ink-stains/2020/secondary/submissions/07-the-samurai/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2020-secondary-submissions-07-the-samurai-index-mdx" */),
  "component---src-articles-ink-stains-2020-secondary-submissions-08-there-will-be-blood-index-mdx": () => import("./../../../src/articles/ink-stains/2020/secondary/submissions/08-there-will-be-Blood/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2020-secondary-submissions-08-there-will-be-blood-index-mdx" */),
  "component---src-articles-ink-stains-2020-secondary-submissions-09-passion-vs-oppression-index-mdx": () => import("./../../../src/articles/ink-stains/2020/secondary/submissions/09-passion-vs-oppression/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2020-secondary-submissions-09-passion-vs-oppression-index-mdx" */),
  "component---src-articles-ink-stains-2020-secondary-submissions-10-ode-to-passion-index-mdx": () => import("./../../../src/articles/ink-stains/2020/secondary/submissions/10-ode-to-passion/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2020-secondary-submissions-10-ode-to-passion-index-mdx" */),
  "component---src-articles-ink-stains-2020-secondary-submissions-11-the-citadel-index-mdx": () => import("./../../../src/articles/ink-stains/2020/secondary/submissions/11-the-citadel/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2020-secondary-submissions-11-the-citadel-index-mdx" */),
  "component---src-articles-ink-stains-2020-secondary-submissions-12-red-balloon-index-mdx": () => import("./../../../src/articles/ink-stains/2020/secondary/submissions/12-red-balloon/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2020-secondary-submissions-12-red-balloon-index-mdx" */),
  "component---src-articles-ink-stains-2020-secondary-submissions-13-never-run-again-index-mdx": () => import("./../../../src/articles/ink-stains/2020/secondary/submissions/13-never-run-again/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2020-secondary-submissions-13-never-run-again-index-mdx" */),
  "component---src-articles-ink-stains-2020-secondary-submissions-14-the-virus-index-mdx": () => import("./../../../src/articles/ink-stains/2020/secondary/submissions/14-the-virus/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2020-secondary-submissions-14-the-virus-index-mdx" */),
  "component---src-articles-ink-stains-2020-secondary-submissions-15-the-perfect-change-index-mdx": () => import("./../../../src/articles/ink-stains/2020/secondary/submissions/15-the-perfect-change/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2020-secondary-submissions-15-the-perfect-change-index-mdx" */),
  "component---src-articles-ink-stains-2020-secondary-submissions-16-a-passionate-trance-index-mdx": () => import("./../../../src/articles/ink-stains/2020/secondary/submissions/16-a-passionate-trance/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2020-secondary-submissions-16-a-passionate-trance-index-mdx" */),
  "component---src-articles-ink-stains-2020-secondary-submissions-17-sci-fi-on-an-intergalactic-index-mdx": () => import("./../../../src/articles/ink-stains/2020/secondary/submissions/17-sci-fi-on-an-intergalactic/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2020-secondary-submissions-17-sci-fi-on-an-intergalactic-index-mdx" */),
  "component---src-articles-ink-stains-2020-secondary-submissions-18-la-murder-mystery-index-mdx": () => import("./../../../src/articles/ink-stains/2020/secondary/submissions/18-la-murder-mystery/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2020-secondary-submissions-18-la-murder-mystery-index-mdx" */),
  "component---src-articles-ink-stains-2020-secondary-submissions-19-let-me-be-index-mdx": () => import("./../../../src/articles/ink-stains/2020/secondary/submissions/19-let-me-be/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2020-secondary-submissions-19-let-me-be-index-mdx" */),
  "component---src-articles-ink-stains-2020-secondary-submissions-20-the-night-of-no-regret-index-mdx": () => import("./../../../src/articles/ink-stains/2020/secondary/submissions/20-the-night-of-no-regret/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2020-secondary-submissions-20-the-night-of-no-regret-index-mdx" */),
  "component---src-articles-ink-stains-2020-secondary-submissions-21-passionate-lies-index-mdx": () => import("./../../../src/articles/ink-stains/2020/secondary/submissions/21-passionate-lies/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2020-secondary-submissions-21-passionate-lies-index-mdx" */),
  "component---src-articles-ink-stains-2020-secondary-submissions-22-jealousy-is-passionate-index-mdx": () => import("./../../../src/articles/ink-stains/2020/secondary/submissions/22-jealousy-is-passionate/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2020-secondary-submissions-22-jealousy-is-passionate-index-mdx" */),
  "component---src-articles-ink-stains-2020-secondary-winers-01-my-bookworm-index-mdx": () => import("./../../../src/articles/ink-stains/2020/secondary/winers/01-my-bookworm/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2020-secondary-winers-01-my-bookworm-index-mdx" */),
  "component---src-articles-ink-stains-2020-secondary-winers-02-a-very-furry-poem-index-mdx": () => import("./../../../src/articles/ink-stains/2020/secondary/winers/02-a-very-furry-poem/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2020-secondary-winers-02-a-very-furry-poem-index-mdx" */),
  "component---src-articles-ink-stains-2020-secondary-winers-03-dreamland-index-mdx": () => import("./../../../src/articles/ink-stains/2020/secondary/winers/03-dreamland/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2020-secondary-winers-03-dreamland-index-mdx" */),
  "component---src-articles-ink-stains-2020-secondary-winers-04-broken-vase-index-mdx": () => import("./../../../src/articles/ink-stains/2020/secondary/winers/04-broken-vase/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2020-secondary-winers-04-broken-vase-index-mdx" */),
  "component---src-articles-ink-stains-2020-secondary-winers-05-my-turn-index-mdx": () => import("./../../../src/articles/ink-stains/2020/secondary/winers/05-my-turn/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2020-secondary-winers-05-my-turn-index-mdx" */),
  "component---src-articles-ink-stains-2020-secondary-winers-06-a-stand-up-from-failure-index-mdx": () => import("./../../../src/articles/ink-stains/2020/secondary/winers/06-a-stand-up-from-failure/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2020-secondary-winers-06-a-stand-up-from-failure-index-mdx" */),
  "component---src-articles-ink-stains-2020-secondary-winers-07-what-does-a-person-have-to-do-with-wind-index-mdx": () => import("./../../../src/articles/ink-stains/2020/secondary/winers/07-what-Does-a-person-have-to-do-with-wind/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2020-secondary-winers-07-what-does-a-person-have-to-do-with-wind-index-mdx" */),
  "component---src-articles-ink-stains-2020-secondary-winers-08-stones-index-mdx": () => import("./../../../src/articles/ink-stains/2020/secondary/winers/08-stones/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2020-secondary-winers-08-stones-index-mdx" */),
  "component---src-articles-ink-stains-2020-secondary-winers-09-why-must-i-go-on-index-mdx": () => import("./../../../src/articles/ink-stains/2020/secondary/winers/09-why-must-i-go-on/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2020-secondary-winers-09-why-must-i-go-on-index-mdx" */),
  "component---src-articles-ink-stains-2020-secondary-winers-10-black-ladder-index-mdx": () => import("./../../../src/articles/ink-stains/2020/secondary/winers/10-black-ladder/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2020-secondary-winers-10-black-ladder-index-mdx" */),
  "component---src-articles-ink-stains-2020-secondary-winers-11-on-the-current-state-of-genealogical-dna-testing-index-mdx": () => import("./../../../src/articles/ink-stains/2020/secondary/winers/11-on-the-current-state-of-genealogical-dna-testing/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2020-secondary-winers-11-on-the-current-state-of-genealogical-dna-testing-index-mdx" */),
  "component---src-articles-ink-stains-2020-secondary-winers-12-representation-and-oppression-index-mdx": () => import("./../../../src/articles/ink-stains/2020/secondary/winers/12-representation-and-oppression/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2020-secondary-winers-12-representation-and-oppression-index-mdx" */),
  "component---src-articles-ink-stains-2020-secondary-winers-13-untitled-passion-index-mdx": () => import("./../../../src/articles/ink-stains/2020/secondary/winers/13-untitled-passion/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2020-secondary-winers-13-untitled-passion-index-mdx" */),
  "component---src-articles-ink-stains-2020-secondary-winers-14-nothingness-index-mdx": () => import("./../../../src/articles/ink-stains/2020/secondary/winers/14-nothingness/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2020-secondary-winers-14-nothingness-index-mdx" */),
  "component---src-articles-ink-stains-2021-primary-01-long-life-to-psi-index-mdx": () => import("./../../../src/articles/ink-stains/2021/primary/01-long-life-to-psi/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2021-primary-01-long-life-to-psi-index-mdx" */),
  "component---src-articles-ink-stains-2021-primary-02-happy-birthday-psi-index-mdx": () => import("./../../../src/articles/ink-stains/2021/primary/02-happy-birthday-psi/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2021-primary-02-happy-birthday-psi-index-mdx" */),
  "component---src-articles-ink-stains-2021-primary-03-congratulations-pechersk-school-international-kyiv-index-mdx": () => import("./../../../src/articles/ink-stains/2021/primary/03-congratulations-pechersk-school-international-kyiv/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2021-primary-03-congratulations-pechersk-school-international-kyiv-index-mdx" */),
  "component---src-articles-ink-stains-2021-primary-04-slashy-birthday-psi-index-mdx": () => import("./../../../src/articles/ink-stains/2021/primary/04-slashy-birthday-psi/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2021-primary-04-slashy-birthday-psi-index-mdx" */),
  "component---src-articles-ink-stains-2021-primary-05-celebrate-index-mdx": () => import("./../../../src/articles/ink-stains/2021/primary/05-celebrate/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2021-primary-05-celebrate-index-mdx" */),
  "component---src-articles-ink-stains-2021-primary-06-silver-lining-index-mdx": () => import("./../../../src/articles/ink-stains/2021/primary/06-silver-lining/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2021-primary-06-silver-lining-index-mdx" */),
  "component---src-articles-ink-stains-2021-primary-07-bday-bday-index-mdx": () => import("./../../../src/articles/ink-stains/2021/primary/07-bday-bday/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2021-primary-07-bday-bday-index-mdx" */),
  "component---src-articles-ink-stains-2021-primary-08-i-will-think-of-psi-index-mdx": () => import("./../../../src/articles/ink-stains/2021/primary/08-i-will-think-of-psi/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2021-primary-08-i-will-think-of-psi-index-mdx" */),
  "component---src-articles-ink-stains-2021-primary-09-a-year-of-birthdays-index-mdx": () => import("./../../../src/articles/ink-stains/2021/primary/09-a-year-of-birthdays/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2021-primary-09-a-year-of-birthdays-index-mdx" */),
  "component---src-articles-ink-stains-2021-primary-10-the-secrets-of-nowhere-index-mdx": () => import("./../../../src/articles/ink-stains/2021/primary/10-the-secrets-of-nowhere/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2021-primary-10-the-secrets-of-nowhere-index-mdx" */),
  "component---src-articles-ink-stains-2021-primary-11-the-best-b-day-index-mdx": () => import("./../../../src/articles/ink-stains/2021/primary/11-the-best-b-day/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2021-primary-11-the-best-b-day-index-mdx" */),
  "component---src-articles-ink-stains-2021-primary-12-happy-birthday-index-mdx": () => import("./../../../src/articles/ink-stains/2021/primary/12-happy-birthday/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2021-primary-12-happy-birthday-index-mdx" */),
  "component---src-articles-ink-stains-2021-primary-13-my-journey-through-the-ages-in-psi-index-mdx": () => import("./../../../src/articles/ink-stains/2021/primary/13-my-journey-through-the-Ages-in-psi/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2021-primary-13-my-journey-through-the-ages-in-psi-index-mdx" */),
  "component---src-articles-ink-stains-2021-secondary-dp-01-wedding-dress-index-mdx": () => import("./../../../src/articles/ink-stains/2021/secondary/dp/01-wedding-dress/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2021-secondary-dp-01-wedding-dress-index-mdx" */),
  "component---src-articles-ink-stains-2021-secondary-lower-01-2020-the-poem-index-mdx": () => import("./../../../src/articles/ink-stains/2021/secondary/lower/01-2020-the-poem/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2021-secondary-lower-01-2020-the-poem-index-mdx" */),
  "component---src-articles-ink-stains-2021-secondary-lower-01-lies-index-mdx": () => import("./../../../src/articles/ink-stains/2021/secondary/lower/01-lies/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2021-secondary-lower-01-lies-index-mdx" */),
  "component---src-articles-ink-stains-2021-secondary-lower-03-diversity-index-mdx": () => import("./../../../src/articles/ink-stains/2021/secondary/lower/03-diversity/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2021-secondary-lower-03-diversity-index-mdx" */),
  "component---src-articles-ink-stains-2021-secondary-lower-04-ahmeds-life-index-mdx": () => import("./../../../src/articles/ink-stains/2021/secondary/lower/04-ahmeds-life/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2021-secondary-lower-04-ahmeds-life-index-mdx" */),
  "component---src-articles-ink-stains-2021-secondary-lower-05-under-the-ice-index-mdx": () => import("./../../../src/articles/ink-stains/2021/secondary/lower/05-under-the-ice/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2021-secondary-lower-05-under-the-ice-index-mdx" */),
  "component---src-articles-ink-stains-2021-secondary-lower-06-curious-sophia-index-mdx": () => import("./../../../src/articles/ink-stains/2021/secondary/lower/06-curious-sophia/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2021-secondary-lower-06-curious-sophia-index-mdx" */),
  "component---src-articles-ink-stains-2021-secondary-lower-07-good-night-moscow-index-mdx": () => import("./../../../src/articles/ink-stains/2021/secondary/lower/07-good-night-moscow/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2021-secondary-lower-07-good-night-moscow-index-mdx" */),
  "component---src-articles-ink-stains-2021-secondary-lower-09-bully-steve-index-mdx": () => import("./../../../src/articles/ink-stains/2021/secondary/lower/09-bully-steve/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2021-secondary-lower-09-bully-steve-index-mdx" */),
  "component---src-articles-ink-stains-2021-secondary-lower-10-sports-have-no-gender-index-mdx": () => import("./../../../src/articles/ink-stains/2021/secondary/lower/10-sports-have-no-gender/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2021-secondary-lower-10-sports-have-no-gender-index-mdx" */),
  "component---src-articles-ink-stains-2021-secondary-lower-11-the-race-of-equality-index-mdx": () => import("./../../../src/articles/ink-stains/2021/secondary/lower/11-the-race-of-equality/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2021-secondary-lower-11-the-race-of-equality-index-mdx" */),
  "component---src-articles-ink-stains-2021-secondary-lower-12-diversity-equity-inclusion-index-mdx": () => import("./../../../src/articles/ink-stains/2021/secondary/lower/12-diversity-equity-inclusion/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2021-secondary-lower-12-diversity-equity-inclusion-index-mdx" */),
  "component---src-articles-ink-stains-2021-secondary-lower-13-chronicles-of-bastionna-index-mdx": () => import("./../../../src/articles/ink-stains/2021/secondary/lower/13-chronicles-of-bastionna/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2021-secondary-lower-13-chronicles-of-bastionna-index-mdx" */),
  "component---src-articles-ink-stains-2021-secondary-lower-14-spiraling-thoughts-index-mdx": () => import("./../../../src/articles/ink-stains/2021/secondary/lower/14-spiraling-thoughts/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2021-secondary-lower-14-spiraling-thoughts-index-mdx" */),
  "component---src-articles-ink-stains-2021-secondary-lower-15-dual-realities-index-mdx": () => import("./../../../src/articles/ink-stains/2021/secondary/lower/15-dual-realities/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2021-secondary-lower-15-dual-realities-index-mdx" */),
  "component---src-articles-ink-stains-2021-secondary-lower-16-formula-1-index-mdx": () => import("./../../../src/articles/ink-stains/2021/secondary/lower/16-formula-1/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2021-secondary-lower-16-formula-1-index-mdx" */),
  "component---src-articles-ink-stains-2021-secondary-theme-01-last-time-index-mdx": () => import("./../../../src/articles/ink-stains/2021/secondary/theme/01-last-time/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2021-secondary-theme-01-last-time-index-mdx" */),
  "component---src-articles-ink-stains-2021-secondary-theme-02-truly-inclusive-index-mdx": () => import("./../../../src/articles/ink-stains/2021/secondary/theme/02-truly-inclusive/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2021-secondary-theme-02-truly-inclusive-index-mdx" */),
  "component---src-articles-ink-stains-2021-secondary-theme-03-c-306-index-mdx": () => import("./../../../src/articles/ink-stains/2021/secondary/theme/03-C306/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2021-secondary-theme-03-c-306-index-mdx" */),
  "component---src-articles-ink-stains-2021-secondary-theme-03-domestic-violence-shelter-index-mdx": () => import("./../../../src/articles/ink-stains/2021/secondary/theme/03-domestic-violence-shelter/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2021-secondary-theme-03-domestic-violence-shelter-index-mdx" */),
  "component---src-articles-ink-stains-2021-secondary-theme-04-three-winter-celebrations-index-mdx": () => import("./../../../src/articles/ink-stains/2021/secondary/theme/04-three-winter-celebrations/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2021-secondary-theme-04-three-winter-celebrations-index-mdx" */),
  "component---src-articles-ink-stains-2021-secondary-theme-05-use-of-language-in-we-should-all-be-feminists-index-mdx": () => import("./../../../src/articles/ink-stains/2021/secondary/theme/05-use-of-Language-in-we-should-all-be-feminists/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2021-secondary-theme-05-use-of-language-in-we-should-all-be-feminists-index-mdx" */),
  "component---src-articles-ink-stains-2021-secondary-theme-06-two-kings-index-mdx": () => import("./../../../src/articles/ink-stains/2021/secondary/theme/06-two-kings/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2021-secondary-theme-06-two-kings-index-mdx" */),
  "component---src-articles-ink-stains-2021-secondary-theme-07-black-racism-index-mdx": () => import("./../../../src/articles/ink-stains/2021/secondary/theme/07-black-racism/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2021-secondary-theme-07-black-racism-index-mdx" */),
  "component---src-articles-ink-stains-2021-secondary-theme-08-a-woman-of-success-index-mdx": () => import("./../../../src/articles/ink-stains/2021/secondary/theme/08-a-woman-of-success/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2021-secondary-theme-08-a-woman-of-success-index-mdx" */),
  "component---src-articles-ink-stains-2021-secondary-theme-09-lorelei-and-the-witch-index-mdx": () => import("./../../../src/articles/ink-stains/2021/secondary/theme/09-lorelei-and-the-witch/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2021-secondary-theme-09-lorelei-and-the-witch-index-mdx" */),
  "component---src-articles-ink-stains-2021-secondary-upper-01-to-be-a-feminist-index-mdx": () => import("./../../../src/articles/ink-stains/2021/secondary/upper/01-to-be-a-feminist/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2021-secondary-upper-01-to-be-a-feminist-index-mdx" */),
  "component---src-articles-ink-stains-2021-secondary-upper-02-should-vaccination-be-compulsory-index-mdx": () => import("./../../../src/articles/ink-stains/2021/secondary/upper/02-should-vaccination-be-compulsory/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2021-secondary-upper-02-should-vaccination-be-compulsory-index-mdx" */),
  "component---src-articles-ink-stains-2021-secondary-upper-03-the-ethics-of-mandatory-vaccination-index-mdx": () => import("./../../../src/articles/ink-stains/2021/secondary/upper/03-the-ethics-of-mandatory-vaccination/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2021-secondary-upper-03-the-ethics-of-mandatory-vaccination-index-mdx" */),
  "component---src-articles-ink-stains-2021-secondary-upper-04-adapting-mitigating-the-impacts-index-mdx": () => import("./../../../src/articles/ink-stains/2021/secondary/upper/04-adapting-mitigating-the-impacts/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2021-secondary-upper-04-adapting-mitigating-the-impacts-index-mdx" */),
  "component---src-articles-ink-stains-2021-secondary-upper-05-feminism-an-equitable-society-index-mdx": () => import("./../../../src/articles/ink-stains/2021/secondary/upper/05-feminism-an-equitable-society/index.mdx" /* webpackChunkName: "component---src-articles-ink-stains-2021-secondary-upper-05-feminism-an-equitable-society-index-mdx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-annual-report-2020-index-tsx": () => import("./../../../src/pages/annual-report/2020/index.tsx" /* webpackChunkName: "component---src-pages-annual-report-2020-index-tsx" */),
  "component---src-pages-annual-report-2021-index-tsx": () => import("./../../../src/pages/annual-report/2021/index.tsx" /* webpackChunkName: "component---src-pages-annual-report-2021-index-tsx" */),
  "component---src-pages-annual-report-index-tsx": () => import("./../../../src/pages/annual-report/index.tsx" /* webpackChunkName: "component---src-pages-annual-report-index-tsx" */),
  "component---src-pages-handbook-index-tsx": () => import("./../../../src/pages/handbook/index.tsx" /* webpackChunkName: "component---src-pages-handbook-index-tsx" */),
  "component---src-pages-handbook-parent-student-index-tsx": () => import("./../../../src/pages/handbook/parent-student/index.tsx" /* webpackChunkName: "component---src-pages-handbook-parent-student-index-tsx" */),
  "component---src-pages-handbook-primary-index-tsx": () => import("./../../../src/pages/handbook/primary/index.tsx" /* webpackChunkName: "component---src-pages-handbook-primary-index-tsx" */),
  "component---src-pages-handbook-secondary-index-tsx": () => import("./../../../src/pages/handbook/secondary/index.tsx" /* webpackChunkName: "component---src-pages-handbook-secondary-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ink-stains-2020-index-tsx": () => import("./../../../src/pages/ink-stains/2020/index.tsx" /* webpackChunkName: "component---src-pages-ink-stains-2020-index-tsx" */),
  "component---src-pages-ink-stains-2021-index-tsx": () => import("./../../../src/pages/ink-stains/2021/index.tsx" /* webpackChunkName: "component---src-pages-ink-stains-2021-index-tsx" */),
  "component---src-pages-ink-stains-index-tsx": () => import("./../../../src/pages/ink-stains/index.tsx" /* webpackChunkName: "component---src-pages-ink-stains-index-tsx" */),
  "component---src-pages-own-email-signature-tsx": () => import("./../../../src/pages/own/email-signature.tsx" /* webpackChunkName: "component---src-pages-own-email-signature-tsx" */),
  "component---src-pages-psi-life-index-tsx": () => import("./../../../src/pages/psi-life/index.tsx" /* webpackChunkName: "component---src-pages-psi-life-index-tsx" */)
}

