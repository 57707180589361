import React             from 'react';
import { LabelProps }    from 'recharts';
import { BarChartBlock } from '../../../../../../lib/annual-report/radial-chart-block/bar-chart-block';
import {
    ItemDataType,
    RadialChartBlock
} from '../../../../../../lib/annual-report/radial-chart-block/radial-chart-block';
import { colors } from '../../../../../../lib/styles/color';

const legend: Partial<ItemDataType>[] = [
    { name: 'Agree, strongly Agree', color: colors.green.hex() },
    { name: 'Disagree, strongly Disagree', color: colors.red.hex() }
];

// Personalised
const dataPersonalised1 = [
    { name: 'Designers of their learning', value: 4.1, color: colors.key.darken(0.5).hex() },
    { name: 'Co-Designers of their learning', value: 47.4, color: colors.key.hex() },
    { name: 'Participants of their learning', value: 48.5, color: colors.mint.hex() }
];
const dataPersonalised2 = [
    { name: '2021', value: 72.4, value1: 18.4 },
    { name: '2020', value: 66.9, value1: 26.4 }
];

// Thinking
const dataThinking1 = [
    { name: 'Strongly Agree', value: 30.2, color: colors.green.hex() },
    { name: 'Agree', value: 57.1, color: colors.key.hex() },
    { name: 'I dont know', value: 12.7, color: colors.gray.darken(0.2).hex() }
];

// Balanced
const dataBalanced1 = [
    { name: '2021', value: 73, value1: 11.1 },
    { name: '2020', value: 68.8, value1: 9.1 }
];
const dataBalanced2 = [
    { name: '2021', value: 81.5, value1: 10.5 },
    { name: '2020', value: 73.6, value1: 12.3 }
];

const customLabel = (data) => (v: LabelProps) => {
    const { x, y, value, name } = v;
    // console.log(v.value, v);
    const _data = data.find((item) => item.name === name);
    const val = _data.value === value ? value : _data.value1;
    return (
        <text
            x={ x + 5 }
            y={ y + 10 }
            fill="white"
            fontSize="14px"
            // textAnchor={ x > cx ? 'start' : 'end' }
            dominantBaseline="central"
        >
            { `${ (val).toFixed(2) }%` }
        </text>
    );
};

export const ChartPersonalised = () => (
    <>
        <RadialChartBlock
            data={ dataPersonalised1 }
            title="Secondary school curricular unit personalisation"
            unit="%"
        />
        <BarChartBlock
            data={ dataPersonalised2 }
            legend={ legend }
            colors={ [ colors.green.hex(), colors.red.hex() ] }
            title="Secondary Students: _Most of That I Learn in my Class is Relevant to Me_"
            titleProps={ { size: 'h3' } }
            labelComponent={ customLabel(dataPersonalised2) }
        />
    </>
);

export const ChartThinking = () => (
    <>
        <RadialChartBlock
            data={ dataThinking1 }
            title="Primary Students: _I am provided with opportunities to generate creative ideas and solutions_"
            unit="%"
        />
    </>
);

export const ChartBalanced = () => (
    <>
        { /* <Title></Title> */ }
        <BarChartBlock
            data={ dataBalanced1 }
            legend={ legend }
            colors={ [ colors.green.hex(), colors.red.hex() ] }
            title="Grade 3-5 Students: _PSI Teach me how to make positive, socially responsible online contributions_"
            titleProps={ { size: 'h3' } }
            labelComponent={ customLabel(dataBalanced1) }
        />
        <BarChartBlock
            data={ dataBalanced2 }
            legend={ legend }
            colors={ [ colors.green.hex(), colors.red.hex() ] }
            title="Secondary students: _PSI Teach me how to make positive, socially responsible online contributions_"
            titleProps={ { size: 'h3' } }
            labelComponent={ customLabel(dataBalanced2) }
        />
    </>
);
