import  Color from 'color';

export const colors = {
    key:    Color.hsl(221, 100, 39) as typeof Color,
    dark:   Color.hsl(240, 3, 10) as typeof Color,
    gray:   Color.hsl(240, 10, 95) as typeof Color,
    red:    Color.hsl(14, 96, 57) as typeof Color,
    yellow: Color.hsl(45, 91, 68) as typeof Color,
    green:  Color.hsl(130, 53, 57) as typeof Color,
    mint:   Color.hsl(174, 55, 65) as typeof Color,
    blue:   Color.hsl(191, 60, 69) as typeof Color,
    violet: Color.hsl(229, 84, 78),

    secondary: Color.hsl(14, 96, 57) as typeof Color,
    primary:   Color.hsl(191, 60, 69)
};

export type ColorsType = keyof typeof colors;

export const generateColorsVars = () => {
    let out = {};
    Object.keys(colors).map((colorKey, index) => {
        out = {
            ...out,
            ...{ [ `--ui-color-${ colorKey }` ]: colors[ colorKey ].hex() }
        };
        for (let i = 1; i < 5; i += 1) {
            out = {
                ...out,
                ...{ [ `--ui-color-${ colorKey }-d${ i }` ]: colors[ colorKey ].darken(i * 0.05).hex() },
                ...{ [ `--ui-color-${ colorKey }-l${ i }` ]: colors[ colorKey ].lighten(i * 0.05).hex() }
            };
        }
    });
    return out;
};
