import React from 'react';
import {
    EnrolmentChart,
    EnrolmentChartPropsType
} from '../../../../../lib/annual-report/enrolment-chart/enrolment-chart';

const Chart = () => {
    const data: EnrolmentChartPropsType['data'] = [
        {
            year:   '2019-2020',
            values: {
                August:   498,
                November: 500,
                January:  504,
                April:    506,
                June:     508
            }

        },
        {
            year:   '2018-2019',
            values: {
                August:   446,
                November: 449,
                January:  444,
                April:    452,
                June:     455
            }
        },
        {
            year:   '2017-2018',
            values: {
                August:   436,
                November: 442,
                January:  445,
                April:    441,
                June:     443
            }
        },
        {
            year:   '2016-2017',
            values: {
                August:   396,
                November: 397,
                January:  401,
                April:    409,
                June:     410
            }
        },
        {
            year:   '2015-2016',
            values: {
                August:   384,
                November: 387,
                January:  384,
                April:    390,
                June:     391
            }
        },
        {
            year:   '2014-2015',
            values: {
                August:   386,
                November: 394,
                January:  393,
                April:    385,
                June:     385
            }
        },
        {
            year:   '2013-2014',
            values: {
                August:   436,
                November: 435,
                January:  429,
                April:    429,
                June:     429
            }
        },
        {
            year:   '2012-2013',
            values: {
                August:   460,
                November: 461,
                January:  459,
                April:    470,
                June:     470
            }
        }
    ];
    return <EnrolmentChart data={ data } />;
};

export default Chart;
