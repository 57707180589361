import React                                                         from 'react';
import { cn }                                                        from '../../util/bem';
import { dataFunction, dataMedia }                                   from '../../util/data-function';
import { HidePropsType, SizeType, SizeTypeDirection, TextColorType } from '../../util/global-props';
import './text.scss';

export type TextPropsType = {
  children: React.ReactNode;
  className?: string;

  type?: 'caption'|'bloquote';
  size?: 'xsm' | 'sm' | 'md' | 'lg';
  uppercase?: boolean;
  underline?: boolean;
  weight?: 'bold' | 'regular';
  align?: 'left'|'center' | 'right';
  color?: TextColorType;
  bold?: boolean;
  italic?: boolean;
  noWrap?: boolean;
  preWrap?: boolean;

  margin?: SizeType;
  padding?: SizeType | SizeTypeDirection;
  fullWidth?: boolean;
  flex?: number | string ;
  style?: React.CSSProperties;
  hide?: HidePropsType;
  xsm?: Partial<TextPropsType>;
  sm?: Partial<TextPropsType>;
  md?: Partial<TextPropsType>;
  lg?: Partial<TextPropsType>;
}

export const Text = (props: TextPropsType) => {
    const className = `text${ props.type ? ` ${ props.type }` : '' }`;
    const properties = {
        className: cn(className)(null, null, props.className),
        children:  props.children,
        ...dataFunction({
            size:      props.size,
            uppercase: props.uppercase,
            underline: props.underline,
            weight:    props.bold ? 'bold' : props.weight,
            align:     props.align,
            color:     props.color,
            bold:      props.bold,
            italic:    props.italic,
            noWrap:    props.noWrap,
            preWrap:   props.preWrap,

            margin:    props.margin,
            padding:   props.padding,
            fullWidth: props.fullWidth,
            flex:      props.flex,

            ...dataMedia(props)
        }),
        style: { ...props.style }
    };
    return <p { ...properties } />;
};
export const Span = (props: TextPropsType) => {
    const properties = {
        children: props.children,
        ...dataFunction({
            weight: props.bold ? 'bold' : props.weight,
            color:  props.color,
            xsm:    props.xsm,
            sm:     props.sm,
            md:     props.md,
            lg:     props.lg
        }),
        style: { ...props.style }
    };
    return <span { ...properties } />;
};

export const I = (props: TextPropsType) => {
    const properties = {
        children: props.children,
        ...dataFunction({
            color: props.color,
            xsm:   props.xsm,
            sm:    props.sm,
            md:    props.md,
            lg:    props.lg
        }),
        style: { ...props.style }
    };
    return <em { ...properties } />;
};
export const B = (props: TextPropsType) => {
    const properties = {
        children: props.children,
        ...dataFunction({
            color: props.color,
            xsm:   props.xsm,
            sm:    props.sm,
            md:    props.md,
            lg:    props.lg
        }),
        style: { ...props.style }
    };
    return <b { ...properties } />;
};
