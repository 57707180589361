import React, { FC, memo, useRef, useState } from 'react';
import { cn }                                from '../../util/bem';
import { dataFunction }                      from '../../util/data-function';
import { SizeType }                          from '../../util/global-props';
import { Wrapper }                           from '../wrapper/wrapper.component';
import {  useAutoPosition }                  from './autoposition.hook';
import './tooltip.compontnet.scss';

export type TooltipPropsType = {
  className?: string;
  children: React.ReactNode;
  message?: React.ReactNode;
  disabled?: boolean;
  timeout?: number;
  fullWidth?: boolean;

  manual?: boolean;

  margin?: SizeType;
  menuWidth?: string;
  position?:
    | 'auto-vertical-left'
    | 'auto-vertical-center'
    | 'auto-vertical-right'
    | 'bottom-left'
    | 'bottom-center'
    | 'bottom-right'
    | 'top-left'
    | 'top-center'
    | 'top-right';
  style?: React.CSSProperties;

}

const className = cn('tooltip');

export const Tooltip: FC<TooltipPropsType> = memo((props) => {
    const triggerEl  = useRef(null);
    const dropdownEl = useRef(null);
    const [ open, setOpen ] = useState(false);

    const [ autoPosition ] = useAutoPosition({
        dropdownEl: dropdownEl.current,
        triggerEl:  triggerEl.current,
        openState:  props.manual || open,
        position:   props.position
    });

    return (
        <div
            className={ className() }
            onMouseEnter={ () => setOpen(true) }
            onMouseLeave={ () => setOpen(false) }
            style={ { width: props.fullWidth ? '100%' : undefined, ...props.style } }
            { ...dataFunction({
                disabled: props.disabled,
                margin:   props.margin
            }) }
        >
            <div
                className={ className('trigger') }
                ref={ triggerEl }
                style={ { width: props.fullWidth ? '100%' : undefined } }
            >
                { props.children }
            </div>

            <div
                className={ className('message', { open }) }
                { ...dataFunction({ position: autoPosition || props.position || 'auto-vertical-center' }) }
                ref={ dropdownEl }
                style={ props.menuWidth ? { width: props.menuWidth } : { width: 'auto' } }
                onClick={ (e) => e.stopPropagation() }
            >

                { props.message
        && (
            <Wrapper
                className={ className('message-wrapper') }
                frame="solid-dark"
                color="white"
                padding="xs"
                radius="sm"
                fullWidth
                styles={ { padding: typeof props.message === 'string' ? '8px 16px' : '0' } }
            >
                { props.message }
            </Wrapper>
        ) }

            </div>

        </div>
    );
});

Tooltip.defaultProps = {
    disabled: false,
    position: 'auto-vertical-center',
    timeout:  200
};
