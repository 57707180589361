import React                from 'react';
import { RadialChartBlock } from '../../../../../../lib/annual-report/radial-chart-block/radial-chart-block';
import { colors }           from '../../../../../../lib/styles/color';
import { md }               from '../../../../../../lib/util/md';

// US - 35
// UK - 8
// UA - 12
// Canada - 5
// Australia - 6
// New Zealand - 4
// France - 2
// Ireland - 1
// Spain - 1
// Hungary - 1
// Portugal - 1
// Columbia - 1

const content = [
    {
        title: 'Teaching Staff Nationalities _(12)_',
        data:  [
            { name: 'US', value: 35, color: colors.red.hex() },
            { name: 'Ukraine', value: 12, color: colors.yellow.hex() },
            { name: 'UK', value: 8, color: colors.key.hex() },
            { name: 'Australia', value: 6, color: colors.mint.darken(0.2).hex() },
            { name: 'Canada', value: 5, color: colors.mint.hex() },
            { name: 'New Zealand', value: 4, color: colors.blue.hex() },
            { name: 'France', value: 2 },
            { name: 'Ireland', value: 1 },
            { name: 'Hungary', value: 1 },
            { name: 'Portugal', value: 1 },
            { name: 'Columbia', value: 1 },

        ]
    },
    {
        title: 'Teaching Staff Qualifications',
        data:  [
            { name: 'PHD', value: 4, color: colors.red.hex() },
            { name: 'MA/MS', value: 54 },
            { name: 'Supplementary degree', value: 5 },
            { name: 'BA', value: 17 }
        ]
    },
    {
        title: 'Staffing Area Break Down',
        data:  [
            { name: 'Teachers', value: 66, color: colors.key.hex() },
            { name: 'TAs', value: 35, color: colors.blue.hex() },
            { name: 'Administration', value: 43, color: colors.violet.hex() },
            { name: 'Custodian/Maintenance/Security/Drivers ', value: 15, color: colors.green.hex() }
        ]
    }
    // {
    //     title: 'Gender',
    //     data:  [
    //         { name: 'Female. Faculty ', value: 74, color: colors.red.hex() },
    //         { name: 'Female. Other ', value: 27, color: colors.red.darken(0.3).hex() },
    //         { name: 'Male. Faculty', value: 33, color: colors.key.hex() },
    //         { name: 'Male. Other', value: 25, color: colors.key.darken(0.3).hex() }
    //     ]
    // }
];

const Chart1 = () => {
    const getColor = (index) => colors.key.lighten(0.03 * index).rotate(-5 * index).hex();

    return content.map((chart: typeof content[0], index) => (
        <RadialChartBlock
            key={ index }
            data={ chart.data }
            title={ md(chart.title) }
            tooltip
            disableLabel
        />
    ));
};

export default Chart1;
