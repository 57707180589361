/* eslint-disable jsx-a11y/heading-has-data */
import React                       from 'react';
import { cn }                      from '../../util/bem';
import { dataFunction }            from '../../util/data-function';
import { SizeType, TextColorType } from '../../util/global-props';
import './title.scss';

export type TitlePropsType = {
  children: React.ReactNode;
  className?: string;
  id?: string;

  size?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' |'chapter';
  type?: 'chapter';
  align?: 'left'|'center' | 'right';
  weight?: 'bold' | 'regular';
  color?: TextColorType;
  uppercase?: boolean;
  underline?: boolean;

  padding?: SizeType;
  margin?: SizeType;
  maxWidth?: string | number;
  fullWidth?: boolean;
  flex?: number | string ;
  style?: React.CSSProperties;

  xsm?: Partial<TitlePropsType>;
  sm?: Partial<TitlePropsType>;
  md?: Partial<TitlePropsType>;
  lg?: Partial<TitlePropsType>;
  hover?: Partial<TitlePropsType>;

}

const className = cn('title');

export const Title = (props: TitlePropsType) => {
    const properties = {
        className: className(null, null, props.className),
        children:  props.children,
        id:        props.id,
        ...dataFunction({
            size:      props.size,
            type:      props.type,
            uppercase: props.uppercase,
            underline: props.underline,
            weight:    props.weight,
            align:     props.align,
            color:     props.color,

            margin:    props.margin,
            fullWidth: props.fullWidth,
            flex:      props.flex,

            padding: props.padding,
            xsm:     props.xsm,
            sm:      props.sm,
            md:      props.md,
            lg:      props.lg,
            hover:   props.hover
        }),
        style: {
            maxWidth: props.maxWidth,
            ...props.style
        }
    };
    switch (props.size) {
        case 'h1': return <h1 { ...properties } />;
        case 'h2': return <h2 { ...properties } />;
        case 'h3': return <h3 { ...properties } />;
        case 'h4': return <h4 { ...properties } />;
        case 'h5': return <h5 { ...properties } />;
        default: return <h3 { ...properties } />;
    }
};
Title.defaultProps = { size: 'h3' };
