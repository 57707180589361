import React, { FC, memo } from 'react';
import { cn }              from '../../util/bem';
import { dataFunction }    from '../../util/data-function';
import { SizeType }        from '../../util/global-props';
import './divider.scss';

export type DividerPropsType = {
  transparent?: boolean;
  margin?: SizeType;
  color?: 'grey'|'grey-2'|'grey-3'|'dark'|'primary'|'secondary'|'key';
  width?: string;
  dashed?: boolean;
  xsm?: DividerPropsType;
  sm?: DividerPropsType;
  md?: DividerPropsType;
  lg?: DividerPropsType;

}

const className = cn('divider');

export const Divider: FC<DividerPropsType> = memo((props) => (
    <div
        className={ className() }
        { ...dataFunction({
            'color-border': props.color,
            margin:         props.margin,
            transparent:    props.transparent,
            dashed:         props.dashed,
            xsm:            props.xsm,
            sm:             props.sm,
            md:             props.md,
            lg:             props.lg
        }) }
        style={ { width: props.width } }
    />
));
