import React  from 'react';
import { cn } from '../../util/bem';
import './flag.scss';

type PropsType = {
  country: string;
  size: number;
}

const className = cn('flag-icon');

// @ts-ignore
export const Flag = (props: PropsType) => (
    <div
        className={ className({ country: props.country.toLowerCase() }) }
        style={ { '--flag-size': props.size } }
    />
);

Flag.defaultProps = {
    size:    12,
    country: 'UA'
};
