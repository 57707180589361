import React                             from 'react';
import { GalleryGrid,  GalleryGridType } from '../../../../../lib/components/gallery-grid/gallery-grid';
// @ts-ignore
import Photo01                           from './01.jpg';
// @ts-ignore
import Photo02                           from './02.jpg';
// @ts-ignore
import Photo03                           from './03.jpg';
// @ts-ignore
import Photo04                           from './04.jpg';
// @ts-ignore
import Photo05                           from './05.jpg';
// @ts-ignore
import Photo06                           from './06.jpg';
// @ts-ignore
import Photo07                           from './07.jpg';
// @ts-ignore
import Photo08                           from './08.jpg';
// @ts-ignore
import Photo09                           from './09.jpg';
// @ts-ignore
import Photo10                           from './10.jpg';
// @ts-ignore
import Photo11                           from './11.jpg';
// @ts-ignore
import Photo12                           from './12.jpg';
// @ts-ignore
import Photo13                           from './13.jpg';
// @ts-ignore
import Photo14                           from './14.jpg';
// @ts-ignore
import Photo15                           from './15.jpg';
// @ts-ignore
import Photo16                           from './16.jpg';

const Photos = () => {
    const photolist: GalleryGridType['data'] = [
        { title: 'Alex Munteanu', subtitle: 'USA,\nBoard Chair', photo: Photo03 },
        { title: 'Juliana Ballard', subtitle: 'USA,\nVice-Chair', photo: Photo06 },
        { title: 'Andrius Nemickas', subtitle: 'USA,\nBoard Secretary, \nGovernance & Strategy, Facilities & Building', photo: Photo02 },
        { title: 'Marina Petrov', subtitle: 'Moldova,\nTreasurer, \nChair of Finance & Risk Management Committee', photo: Photo09 },

        { title: 'Rachel Caldwell', subtitle: 'UK,\nSchool Director', photo: Photo04 },
        { title: 'Jan Cairns', subtitle: 'UK,\nPTA Representative / Non Voting Member', photo: Photo16 },
        { title: 'Peter Erben', subtitle: 'Denmark,\nGovernance & Strategy Committee', photo: Photo14 },
        { title: 'Natalia Fiala', subtitle: 'Ukraine,\nChair of Governance & Strategy Committee', photo: Photo01 },
        { title: 'Vitaliy Gorduz', subtitle: 'Ukraine,\nFinance & Risk Management Committee', photo: Photo12 },
        { title: 'Eric Luhman', subtitle: 'USA,\nFinance & Risk Management Committee', photo: Photo07 },
        { title: 'Robert Gordon McNeil', subtitle: 'UK,\nFinance & Risk Management Committee', photo: Photo13 },
        { title: 'Lina Nemchenko', subtitle: 'Ukraine,\nChair of Facilities & Building Committee', photo: Photo11 },
        { title: 'Tatiana O’Hara', subtitle: 'USA,\nFacilities & Building Committee', photo: Photo08 },
        { title: 'Nora Soliman', subtitle: 'USA,\nGovernance & Strategy Committee', photo: Photo05 },
        { title: 'Nigel Woods', subtitle: 'UK,\nFaculty Representative / Non Voting Member', photo: Photo15 }

    ];
    return <GalleryGrid data={ photolist } />;
};

export default Photos;
