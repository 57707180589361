export const dataFunction = (data: Record<string, any>) => {
    const getPrefix = (prefix?: string) => {
        if (prefix) return `-${ prefix }`;
        return '';
    };
    let dataOutput: Record<string, string> = {};

    const generator = (d: Record<string, any>, prefix?: string, suffix?: string) => {
        let t = false;
        // eslint-disable-next-line array-callback-return
        Object.keys(d).map((key) => {
            if (d && d[ key ] !== undefined) {
                if ([ 'xsm', 'sm', 'md', 'lg', 'hover' ].indexOf(key) > -1) {
                    t = true;
                    generator(d[ key ], key, suffix);
                } else if (typeof d[ key ] === 'object') {
                    generator(d[ key ], prefix, key);
                } else if (typeof d[ key ] === 'boolean' && d[ key ]) {
                    const optionData = `data${ getPrefix(suffix) }-options${ getPrefix(prefix) }`;
                    if (!dataOutput[ optionData ]) {
                        dataOutput[ optionData ] = key;
                    } else {
                        dataOutput[ optionData ] += ` ${ key }`;
                    }
                } else {
                    // const a = { [ `data-${ key }` ]: data[ key ] };
                    dataOutput = { ...dataOutput, ...{ [ `data${ getPrefix(suffix) }-${ key }${ getPrefix(prefix) }` ]: d[ key ] } };
                }
            }
        });
    };
    generator(data);

    return dataOutput;
};

export const dataMedia = ({
    xsm, sm, md, lg, hover, hide
}: any) => {
    const out = {
        xsm,
        sm,
        md,
        lg,
        hide: hide && `${ hide[ 0 ] ? hide[ 0 ] : 'zero' }-${ hide[ 1 ] ? hide[ 1 ] : 'infinite' }`,
        hover
    };
    return out;
};
