/* eslint-disable max-len */
import React                           from 'react';
import { ColorLegend }                 from '../../../../../lib/annual-report/survey/colorLegend-v3';
import { ClimateSurveyType, SurveyV3 } from '../../../../../lib/annual-report/survey/survey-v3';
import { Wrapper }                     from '../../../../../lib/components/wrapper/wrapper.component';

const colorLegend = {
    green1: 'More than 90% \nAgree/Strongly Agree',
    green2: '2020 Agrees increased \nby least 10% vs. 2019',
    red1:   'More than 15% Disagree',
    red2:   '2020 agrees dropped \nby least 10% vs. 2019',
    red3:   '2020 disagrees increased \nby least 10% vs. 19'
};

const ClimateSurveyChart = () => {
    const community: ClimateSurveyType = [
        {
            question:    'I would recommend PSI \nto parents seeking a school for their child.',
            description: 'Community members\' willingness to recommend PSI was impacted by the pandemic, as can be seen in the example comments.  We saw strong positive results by all constituents in 2019 prior to the pandemic, followed in 2020 for the initial pandemic transition to remote learning for all except primary students who had a significant number of “I don’t know responses.”  Now, in 2021, we see increased agreement by our primary students and recognise that more of our parents either disagree or indicate that they “did not know.”  We plan to discuss this further with  parents  through focus groups, and we expect to find that once we return to a 5-day a week full time on-campus learning schedule that this response will return to pre-pandemic levels.',
            data:        [
                {
                    subject:  'secondary',
                    question: 'PSI has a strong sense of community.',
                    numbers:  [
                        { agree: 81, disagree: 13 },
                        { agree: 85, disagree: 4.5 },
                        { agree: 84, disagree: 4.9 }
                    ]
                },
                {
                    subject: 'primary',
                    numbers: [
                        { agree: 89, disagree: 1.6 },
                        { agree: 82, disagree: 6.5 },
                        { agree: 84, disagree: 4.5 }
                    ]
                },
                {
                    subject:  'faculty',
                    question: 'PSI has a strong sense of community.',
                    numbers:  [
                        { agree: 93, disagree: 0 },
                        { agree: 94, disagree: 4.6 },
                        { agree: 95, disagree: 2.6 }
                    ]

                },
                {
                    subject:  'parent',
                    question: 'PSI fosters a strong sense of community.',
                    numbers:  [
                        { agree: 71, disagree: 18, dropped: true },
                        { agree: 71, disagree: 26 },
                        { agree: 71, disagree: 26 }
                    ]
                },
                {
                    subject: 'staff',
                    numbers: [
                        { agree: 94, disagree: 0 },
                        { agree: 97, disagree: 0 },
                        { agree: 95, disagree: 0 }
                    ]
                }
            ],
            after: { example: '"I would recommend PSI any day before we went on remote learning." — _Parent_, \r"I feel PSI has done the best they can in the circumstances. Much more than many other schools." \n— _Parent_, ' }
        },
        {
            question:    'PSI fosters a strong sense of community. / PSI has a strong sense of community',
            description: 'Our motto, “A place where we belong” announces our commitment to cultivating a strong community. Whilst the pandemic has made it challenging to be together on-campus, we have remained committed to finding ways to improve connections between us. We are pleased to see a decrease, in our student and faculty groups, in the number of respondents who disagree with this statement.  We have found, though, maintaining a strong sense of community to be particularly challenging with regards to our parents, as governmental restrictions have prevented them from coming on campus.  Even though we have offered virtual events such as regular town halls, parent education Tuesdays, assemblies and conferences, we find only 71.1% of parents agree with this statement.  We plan to discuss this with the Parent Teacher Association and parent focus groups for guidance on other ways to help build the community feeling.',
            data:        [
                {
                    subject:  'secondary',
                    question: 'PSI has a strong sense of community.',
                    numbers:  [
                        { agree: 80, disagree: 11 },
                        { agree: 83, disagree: 12, raised: true },
                        { agree: 64, disagree: 31 }
                    ]
                },
                { subject: 'primary' },
                {
                    subject:  'faculty',
                    question: 'PSI has a strong sense of community.',
                    numbers:  [
                        { agree: 88, disagree: 8 },
                        { agree: 86, disagree: 6.6, raised: true },
                        { agree: 71, disagree: 26 }
                    ]

                },
                {
                    subject:  'parent',
                    question: 'PSI fosters a strong sense of community.',
                    numbers:  [
                        { agree: 71, disagree: 15, dropped: true },
                        { agree: 92, disagree: 7, raised: true },
                        { agree: 77, disagree: 16 }
                    ]
                },
                {
                    subject: 'staff',
                    numbers: [
                        { agree: 94, disagree: 5 }
                    ]
                }
            ],
            after: {
                example: 'It is difficult to create an enduring sense of community when the school is in blended mode. — _Parent_\n'
                    + 'School’s culture has significantly deteriorated during this school year — _Parent_\n'
                    + 'PSI does well at building a high sense of community — _Secondary student_\n'
                    + 'I think that the sense of community in PSI is sometimes limited and could be improved. — _Secondary student_\n'
                    + 'I feel that PSI has a great climate that helps a lot of students to develop and grow. — _Secondary student_\n'
                    + 'The sense of community remains strong even with the many challenges. I am proud to be a member of a School that has maintained this. — _Faculty member_'
            }
        },
        {
            question:    'PSI respects people from different backgrounds, e.g., race, ethnicities and gender.',
            description: 'At PSI, we believe respect for everyone is essential, so we are disappointed to see that among our primary students as many as 6.3% disagree and 16.1% indicated that they “do not know” if our faculty and staff are respectful of people with different backgrounds. We have already begun an intensified  focus on diversity, equity and inclusion.  In the 2021 - 2022 school year, we plan to have additional professional development for faculty and staff as well as increased student and parent engagement opportunities with this initiative to increase both awareness and understanding of the importance of, and ways to,  improve as individuals and as an institution in this regard.',
            data:        [
                {
                    subject: 'secondary',
                    numbers: [
                        { agree: 88, disagree: 2.6 },
                        { agree: 96, disagree: 2.5 },
                        { agree: 98, disagree: 1.5 }
                    ]
                },
                {
                    subject: 'primary',
                    numbers: [
                        { agree: 78, disagree: 6.3, dropped: true },
                        { agree: 88, disagree: 2.6 },
                        { agree: 90, disagree: 7.3 }
                    ]
                },
                {
                    subject: 'faculty',
                    numbers: [
                        { agree: 98, disagree: 2 },
                        { agree: 92, disagree: 5.7 },
                        { agree: 92, disagree: 8 }
                    ]

                },
                {
                    subject:  'parent',
                    question: 'PSI fosters a strong sense of community.',
                    numbers:  [
                        { agree: 89, disagree: 3.8 },
                        { agree: 96, disagree: 2.8, raised: true },
                        { agree: 83, disagree: 10 }
                    ]
                },
                {
                    subject: 'staff',
                    numbers: [
                        { agree: 97, disagree: 0 }
                    ]
                }
            ],
            after: {
                example: 'Psi definitely respects people — _G3 - 5 student_\n'
                    + 'I feel very safe at school and also respected when I share something about my culture at school — _Secondary student_\n'
                    + 'I have seen and heard evidence that local staff are being mistreated by parents and supervisors. An important part of having an inclusive school culture and community, is that everyone is treated respectfully and with dignity, regardless of where they sit within the administrative hierarchy of the school. — _Faculty member_ '
            }
        },
        {
            question:    'Teachers ensure that learning is differentiated so that all learning engagements meet students’ needs and potential.',
            description: 'We found that the pandemic, and transitioning between blended and remote learning, affected teachers and disciplines differently, as some were better equipped to transition to new approaches more seamlessly than others.  This resulted in some learning being more uniform and some  more differentiated experiences.  As a result, one area for increased focus for professional learning in the 2021 / 2022 school year is to enhance faculty members’ capacity to both personalise and differentiate learning further.',
            data:        [
                {
                    subject: 'secondary',
                    numbers: [
                        { agree: 76, disagree: 13 },
                        { agree: 80, disagree: 15 },
                        { agree: 90, disagree: 10 }
                    ]
                },
                {
                    subject: 'primary',
                    numbers: [
                        { agree: 97, disagree: 3.2, raised: true },
                        { agree: 50, disagree: 49 }
                    ]
                },
                {
                    subject: 'parent',
                    numbers: [
                        { agree: 58, disagree: 14, dropped: true },
                        { agree: 82, disagree: 14 },
                        { agree: 83, disagree: 10 }
                    ]
                },
                {
                    subject: 'faculty',
                    numbers: [
                        { agree: 77, disagree: 16, dropped: true },
                        { agree: 93, disagree: 3.4 },
                        { agree: 98, disagree: 0 }
                    ]

                },
                { subject: 'staff' }
            ],
            after: {}
        },
        {
            question:    'Students are challenged by the learning opportunities presented to them.',
            description: 'We are pleased that our students are, for the most part, feeling challenged by their learning. Even so, we plan to offer additional faculty training in this area.  With 22.4% of parents responding “I do not know” to this question, we wonder if perhaps the question is less valid for the parent group and will discuss this further.',
            data:        [
                {
                    subject: 'secondary',
                    numbers: [
                        { agree: 90, disagree: 5.3 },
                        { agree: 83, disagree: 11 },
                        { agree: 79, disagree: 12 }
                    ]
                },
                {
                    subject: 'primary',
                    numbers: [
                        { agree: 89, disagree: 6.3 },
                        { agree: 94, disagree: 3.9 },
                        { agree: 94, disagree: 2.1 }
                    ]
                },
                {
                    subject: 'parent',
                    numbers: [
                        { agree: 67, disagree: 11, dropped: true },
                        { agree: 83, disagree: 15 },
                        { agree: 83, disagree: 11 }
                    ]
                },
                {
                    subject: 'faculty',
                    numbers: [
                        { agree: 96, disagree: 0 },
                        { agree: 99, disagree: 0 },
                        { agree: 100, disagree: 0 }
                    ]

                },
                { subject: 'staff' }
            ],
            after: {
                example: 'I feel like we haven\'t been encouraged to challenge our students this year — _Faculty_\n'
                    + 'I do not think my kids are challenged based on their abilities or that their learning is personalized or paced based on their needs. — _Parent_\n'
                    + 'I feel that my child is not being challenged in some classes. — _Parent_'
            }
        },
        {
            question:    'Students receive timely and meaningful feedback to improve their learning.',
            description: 'We have seen steady improvement, across the years, from students in this area; however, we recognise we still have some way  to go with our secondary students.   This year, with the blended learning model, our secondary faculty questioned the effectiveness of some of their assessment tools and strategies, including  feedback.\n'
                + 'Assessment, grading and feedback will be an area of focus for the 2021 / 2022 school year professional development, with an experienced and well-respected consultant working with all our faculty, and in particular  with our secondary school teaching teams.   With almost 20% of parents responding “I don’t know” to this question, again we wonder if this is an area where parents do not have enough experience to provide us with meaningful data.\n',
            data: [
                {
                    subject: 'secondary',
                    numbers: [
                        { agree: 75, disagree: 21 },
                        { agree: 72, disagree: 21 },
                        { agree: 70, disagree: 27 }
                    ]
                },
                {
                    subject: 'primary',
                    numbers: [
                        { agree: 100, disagree: 0 },
                        { agree: 92, disagree: 3.9 },
                        { agree: 87, disagree: 4.2 }
                    ]
                },
                {
                    subject: 'parent',
                    numbers: [
                        { agree: 69, disagree: 11, dropped: true },
                        { agree: 84, disagree: 15 },
                        { agree: 79, disagree: 14 }
                    ]
                },
                {
                    subject: 'faculty',
                    numbers: [
                        { agree: 84, disagree: 5.4 },
                        { agree: 94, disagree: 1.1 },
                        { agree: 95, disagree: 2.4 }
                    ]

                },
                { subject: 'staff' }
            ],
            after: {
                example: 'You have to ask them yourself to give you feedback.   — _Secondary student_\n'
                    + 'I am given useful feedback, but unless report cards are coming out the next day it will take several weeks to get grades.   — _Secondary student_\n'
                    + 'I was expecting to see some feedback on his seesaw activities so that my son knows better on what to concentrate but we haven\'t received much feedback on that platform. — _Parent_'
            }
        },
        {
            question:    'PSI prioritises student well-being.  /  PSI values well-being for its staff.',
            description: 'This year, the importance of well-being was highlighted and we plan to continue with this in the coming years.  In particular, our Diploma Programme students consistently disagree with the premise that we prioritise student well-being.  We plan to hold a focus group among secondary students to learn from them in what ways we can better demonstrate our commitment to their well-being.',
            data:        [
                {
                    subject: 'secondary',
                    numbers: [
                        { agree: 67, disagree: 25 },
                        { agree: 69, disagree: 22 },
                        { agree: 70, disagree: 24 }
                    ]
                },
                {
                    subject: 'primary',
                    numbers: [
                        { agree: 84, disagree: 3.2 },
                        { agree: 88, disagree: 2.6 },
                        { agree: 78, disagree: 9.4 }
                    ]
                },
                { subject: 'parent' },
                {
                    subject: 'faculty',
                    numbers: [
                        { agree: 84, disagree: 10 },
                        { agree: 86, disagree: 9.2, raised: true },
                        { agree: 72, disagree: 25 }
                    ]

                },
                {
                    subject: 'staff',
                    numbers: [
                        { agree: 91, disagree: 3 },
                        { agree: 90, disagree: 3.4 }
                    ]
                }
            ],
            after: {
                example: 'PSI does well in student well-being. — _Secondary student_\n'
                    + 'online school is not good for their social & emotional well-being — _Parent_\n'
                    + 'The transition to new school is difficult and the school should take better care to support social and emotional well-being of students — _Parent_\n'
                    + 'Some from PSI show care for well-being more than others. _Faculty member_\n'
                    + 'The school at least talks about valuing the well-being of the staff, but then the expectations are often contradictory to that tal_Faculty member_k.'
            }
        },
        {
            question:    'If I have a personal problem / question / concern, I feel comfortable talking with a trusted adult at PSI.\n',
            description: 'We are pleased to see a decrease in the percentage of students who disagree with regards to not feeling comfortable with a trusted adult at PSI.  We plan to hold focus groups among both the primary and secondary student bodies  to better understand their answers and ways we can improve. In particular, we have begun revising the new Secondary School Advisory Programme to reflect a belief that advisors are advocates for their students with the hope that more students will see their advisor as a trusted adult.',
            data:        [
                {
                    subject: 'secondary',
                    numbers: [
                        { agree: 66, disagree: 26, raised: true },
                        { agree: 54, disagree: 37 }
                    ]
                },
                {
                    subject: 'primary',
                    numbers: [
                        { agree: 59, disagree: 14 },
                        { agree: 66, disagree: 20 }
                    ]
                },
                { subject: 'parent' },
                { subject: 'faculty' },
                { subject: 'staff' }
            ],
            after: {}
        },
        {
            question:    'I am satisfied with the school’s technology resources. \n',
            description: 'The pandemic has certainly highlighted further the importance  of technology at PSI, and we invested in additional resources during the 2020 / 2021 school year as a result of the impact of the pandemic.  We also transitioned our Grades 4 and 5 to the Bring Your Own Device Programme and Grade 3 to 1:1 iPad use.  We plan to hold focus groups among our primary students and parents to better understand their decreased satisfaction and how to improve further with regards to technology.',
            data:        [
                {
                    subject: 'secondary',
                    numbers: [
                        { agree: 92, disagree: 4 },
                        { agree: 89, disagree: 7.4 },
                        { agree: 82, disagree: 16 }
                    ]
                },
                {
                    subject: 'primary',
                    numbers: [
                        { agree: 78, disagree: 7.9 },
                        { agree: 85, disagree: 4.2 }
                    ]
                },
                {
                    subject: 'parent',
                    numbers: [
                        { agree: 85, disagree: 7.5 },
                        { agree: 86, disagree: 6.6 },
                        { agree: 86, disagree: 5.8 }
                    ]
                },
                {
                    subject: 'faculty',
                    numbers: [
                        { agree: 97, disagree: 1.8, raised: true },
                        { agree: 79, disagree: 16 },
                        { agree: 62, disagree: 36 }
                    ]
                },
                {
                    subject: 'staff',
                    numbers: [
                        { agree: 85, disagree: 15 }
                    ]
                }
            ],
            after: {
                example: 'I don\'t really like the (Exam.Net ), because it\'s not really comfortable to use. — _Secondary student_\n'
                    + 'The distribution of IPads to grade 3 children was most welcome. — _Parent_\n'
                    + 'Need to improve WiFi coverage and speed on campus, missing remote tools for children monitor control. — _Parent_\n'
                    + 'IT is improving all the time. — _Faculty_\n'
                    + ' printing equipment is old and often breaks — _Staff_'
            }
        },
        {
            question:    'Adequate security measures are in place at PSI.',
            description: 'We are pleased that our community continues to find our security measures to be comprehensive .  We believe that the increase in “I do not know” responses is related to those who were not able to  enter the campus.',
            data:        [
                {
                    subject: 'secondary',
                    numbers: [
                        { agree: 95, disagree: 2.7 },
                        { agree: 90, disagree: 5.5 },
                        { agree: 97, disagree: 3 }
                    ]
                },
                {
                    subject: 'primary',
                    numbers: [
                        { agree: 91, disagree: 1.8 },
                        { agree: 96, disagree: 3.9 },
                        { agree: 95, disagree: 5.2 }
                    ]
                },
                {
                    subject: 'parent',
                    numbers: [
                        { agree: 82, disagree: 4.7, dropped: true },
                        { agree: 96, disagree: 2.8 },
                        { agree: 91, disagree: 3.9 }
                    ]
                },
                {
                    subject: 'faculty',
                    numbers: [
                        { agree: 97, disagree: 3.4 },
                        { agree: 98, disagree: 0 },
                        { agree: 97, disagree: 2.6 }
                    ]
                },
                { subject: 'staff' }
            ],
            after: {
                example: 'I feel safe — _Secondary student_\n'
                    + 'Security looks solid. Procedures are followed, including hand washing and disinfectant, which is good — _Parent_\n'
            }
        },
        {
            question:    ' I am satisfied with PSI\'s communication during the pandemic.',
            description: 'With the pandemic came the need to share more information than in a typical year.  As a result, many community members indicated they felt overwhelmed.  We also added additional methods of communication, such as the bi-weekly Town Hall, which allowed for live updates, questions and interactions.  Still, communication remains a point of frustration for some community members.  As such, we are working on developing enhanced ways to curate and present the information in the upcoming years. ',
            data:        [
                {
                    subject: 'secondary',
                    numbers: [
                        { agree: 75, disagree: 21 },
                        { agree: 82, disagree: 14 }
                    ]
                },
                {
                    subject: 'primary',
                    numbers: [
                        { agree: 89, disagree: 11 },
                        { agree: 85, disagree: 9.1 }
                    ]
                },
                {
                    subject: 'parent',
                    numbers: [
                        { agree: 78, disagree: 21, dropped: true },
                        { agree: 91, disagree: 10 }
                    ]
                },
                { subject: 'faculty' },
                { subject: 'staff' }
            ],
            after: {
                example: 'Secondary student - sometimes a lack of proper communication with a lot of confusion not only among the students, but how it seems also among the teachers going on. \n'
                    + 'Parent - Some emails from teachers come at midnight, some early on Sat mornings. it turned out they are automated notifications from learning apps\n'
                    + 'Faculty member - I have felt informed throughout the year.'
            }
        }

    ];

    return (
        <Wrapper className="test">
            <SurveyV3 data={ community } />

            <ColorLegend
                colorLegend={ {
                    // green2: '2020 Agrees increased \nby least 10% vs. 2019',
                    green2: 'This year’s % of respondents who agree or strongly agree increased by at least 10% vs. prior year',
                    green1: 'More than 90% of respondents agree \nor strongly agree',
                    red3:   'This year’s % of respondents who agree or strongly agree dropped by at least 10% vs. prior year',
                    red2:   'More than 15%  of respondents disagree \nor strongly disagree'
                } }
            />
        </Wrapper>
    );
};

export default ClimateSurveyChart;
